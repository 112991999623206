import { NotificationType } from "@blacknut/javascript-sdk/dist";
import Notification from "@blacknut/javascript-sdk/dist/model/Notification";
import clsx from "clsx";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Done } from "../../assets/dist/notification/ic_done.svg";
import { ReactComponent as Error } from "../../assets/dist/notification/ic_error.svg";
import { ReactComponent as Info } from "../../assets/dist/notification/ic_info.svg";

import styles from "./styles.module.scss";
interface NotificationComponentProps {
  notification: Notification;

  style?: React.CSSProperties;
}

const NotificationComponent = ({ notification, style }: NotificationComponentProps) => {
  const { t } = useTranslation();
  let translated = "";
  if (notification.localizedMessage) {
    translated = notification.localizedMessage;
  } else if (typeof notification.message === "string") {
    translated = t(notification.message);
  } else if (notification.message && typeof notification.message === "object") {
    translated = t(notification.message.key, notification.message.opts);
  }
  return (
    <div className={styles.container} style={style}>
      <div
        className={clsx(
          styles.iconContainer,
          notification.type === NotificationType.SUCCESS && styles.success,
          notification.type === NotificationType.ERROR && styles.error,
          notification.type === NotificationType.INFO && styles.info,
        )}
      >
        {notification.type === NotificationType.SUCCESS && <Done />}
        {notification.type === NotificationType.ERROR && <Error />}
        {notification.type === NotificationType.INFO && <Info />}
      </div>
      <span className={styles.text}>{translated}</span>
    </div>
  );
};

export default NotificationComponent;
