import { ApiError, previewPage } from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";
import { Page } from "@blacknut/react-client-core/lib";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { firstValueFrom } from "rxjs";
import Catalog from "../../components/Catalog/Catalog";
import CircularProgress from "../../components/CircularProgress/CircularProgress";
import { usePreview } from "../../components/Modals/PreviewContext";
import { LOGGING_TAG } from "../../utils/Utils";
import styles from "./styles.module.scss";
import React from "react";

import { useErrorHandling } from "../../utils/V2ErrorHandler";
const LIMIT = 10;

export const PreviewPage: FunctionComponent = () => {
  const { pageUuid, restrictions } = usePreview();

  const [page, setPage] = useState<Page>();
  const [error, setError] = useState<ApiError>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const page = await firstValueFrom(
          previewPage(pageUuid as string, 0, LIMIT, restrictions),
        );
        setPage(page);
      } catch (e) {
        logE(LOGGING_TAG, "Failed to fetch preview: %o", e);
        if (e.status === "404") {
          setPage({
            isPaginated: false,
            lists: [],
            total: 0,
            uuid: "uuid",
          });
        } else {
          setError(e);
        }
      }
      setLoading(false);
    };
    init();
  }, [error, pageUuid, restrictions]);

  const [paginating, setPaginating] = useState(false);

  const paginate = useCallback(async () => {
    if (!page) return;
    if (page.total > page.lists.length && !paginating) {
      setPaginating(true);
      try {
        const newPage = await firstValueFrom(
          previewPage(pageUuid as string, page?.lists.length, LIMIT, restrictions),
        );
        console.log("new page", newPage);
        setPage({ ...page, lists: [...page.lists, ...newPage.lists] });
      } catch (e) {
        setError(e);
        logE(LOGGING_TAG, "Failed to fetch preview: %o", e);
      }
      setPaginating(false);
    }
  }, [page, pageUuid, paginating, restrictions]);

  const clearError = useCallback(() => {
    //NOP
  }, []);
  useErrorHandling({ error, clearError });
  return (
    <div className={styles.container}>
      {loading && <CircularProgress className={styles.loading} size={50} />}
      {!loading && page && (
        <Catalog page={page} onPaginate={paginate} paginating={paginating} />
      )}
    </div>
  );
};
