import { logD, logE, logW } from "@blacknut/logging/dist";

const LOGGING_TAG = "CEF";
class CEFService {
  private callbacks: Record<
    string,
    { resolve: (data: any) => void; reject: (e: any) => void }
  > = {};
  private static id = 0;

  private _isAvailable = false;
  public get isAvailable(): boolean {
    return this._isAvailable;
  }

  //{"type":"__native_from_js","id":1,"key":"setPeerId","payload":{"peerId":27}}

  public init() {
    const onMessage = this.onMessage.bind(this);
    window.addEventListener("message", onMessage);
    this.sendSync<boolean>("isAvailable")
      .then((isAvailable) => {
        logD(LOGGING_TAG, "isAvialble :%o", isAvailable);
        this._isAvailable = isAvailable;
      })
      .catch((e) => {
        logE(LOGGING_TAG, "Error checking for CEF :%o", e);
      });

    setTimeout(() => {
      logD(LOGGING_TAG, "Timeout reached");
      window.removeEventListener("message", onMessage);
    }, 10000);
  }

  private sendSync<T>(message: string, payload?: any) {
    CEFService.id++;

    return new Promise<T>((resolve, reject) => {
      const data = JSON.stringify({
        type: "__native_from_js",
        key: `${message}`,
        payload,
        id: CEFService.id,
      });
      logD(LOGGING_TAG, "SENDING DATA:%o ", data);

      window.postMessage(data, "*");
      this.callbacks[`${CEFService.id}`] = { resolve, reject };
    });
  }

  private onMessage(e: MessageEvent<any>) {
    logD(LOGGING_TAG, "got message back: %o", e.data);

    const s = e.data;
    if (typeof s === "string") {
      try {
        const data = JSON.parse(s);
        if (data.type === `__native_from_native`) {
          const id = data.id;
          const status = data.status;
          const payload = data.payload;
          if (id !== undefined) {
            if (this.callbacks[id]) {
              const p = this.callbacks[id];

              if (status === 0) {
                // OK
                p.resolve(JSON.parse(payload));
              } else {
                // KO
                p.reject(data.message);
              }
              delete this.callbacks[id];
            } else {
              logW(LOGGING_TAG, "NO CALLBACK WITH GIVEN ID : %o", data);
            }
          } else {
            // ???
          }
        }
      } catch (e) {
        logE(LOGGING_TAG, "Caught error handling message: %o", e);
      }
    }
  }
}
const service = new CEFService();
export default service;
