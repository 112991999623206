import { useAnalytics, AppLayout, useLayout } from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  NavigationFailedEvent,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect, useRef } from "react";
import { Route, Switch } from "react-router";
import styled from "styled-components";
import SideMenu from "../../components/SideMenu/SideMenu";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import EditProfilesPage from "../EditProfiles/EditProfiles";
import FaqPage from "../Faq/FaqPage";
import MePage from "../Me/MePage";
import MobileAccountPage from "../MobileAccountPage/MobileAccountPage";
import NewsletterPage from "../Newsletter/NewsletterPage";
import PersonalizationPage from "../Personalization/PersonalizationPage";
import AdvancedSettingsPage from "../PowerUserSettings/PowerUserSettings";
import NewProfile from "../ProfileCreate/ProfileCreate";
import EditProfile from "../ProfileEdit/ProfileEdit";
import ReceiptPage from "../Receipt/Receipt";
import RemoteNotificationsPage from "../RemoteNotifications/RemoteNotifications";
import { SpeedtestPage } from "../SpeedtestPage/SpeedtestPage";
import SubscriptionPage from "../Subscription/SubscriptionPage";
import SupportPage from "../Support/SupportPage";
import SwitchUser from "../SwitchUser/SwitchUser";
import AccountManage from "./TV/AccountManage";

const Container = styled(FocusableSection)<{ theme: Theme }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${(props) => props.theme.accountPage?.backgroundColor};
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    overflow-y: hidden;
    height: calc(100vh - ${dimens.desktop.headerHeight}rem);
  }
`;

const Content = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    overflow-y: auto;
    overflow-y: overlay;
    height: calc(100vh - ${dimens.desktop.headerHeight}rem);
  }
`;

const AccountPage = () => {
  const { theme } = useTheme();

  const { trackSettings } = useAnalytics();
  const { resume: resumeSpatialNav } = useSpatialNavigation();
  const mounted = useRef(false);
  const layout = useLayout();
  const isOnTv = layout == AppLayout.TV;
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  }, [resumeSpatialNav, trackSettings]);

  const onNavigationFailed = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "up") {
      document.documentElement.scroll({ top: 0 });
    }
  }, []);
  return (
    <Container theme={theme} focusKey="account" onNavigationFailed={onNavigationFailed}>
      <SideMenu />
      <Content focusKey="content">
        <Switch>
          <Route path="/account/me">
            <MePage />
            <MobileAccountPage />
          </Route>
          <Route path="/account/support">
            <SupportPage />
          </Route>
          <Route path="/account/subscription">
            <SubscriptionPage />
          </Route>
          <Route path="/account/manageAccount">
            {layout == AppLayout.TV && <AccountManage />}
          </Route>
          <Route path="/account/newsletter">
            <NewsletterPage />
          </Route>
          <Route path="/account/notifications">
            <RemoteNotificationsPage />
          </Route>
          <Route path="/account/personalization">
            <PersonalizationPage />
          </Route>
          <Route path="/account/settings/advanced">
            <AdvancedSettingsPage />
          </Route>

          <Route path="/account/profile/:id">
            <EditProfile />
          </Route>
          <Route path="/account/profiles/manage" exact>
            <EditProfilesPage />
          </Route>
          <Route path="/account/profiles/new" exact>
            <NewProfile />
          </Route>
          <Route path="/account/profiles/switch" exact>
            <SwitchUser />
          </Route>
          <Route path="/account/receipt" exact>
            <ReceiptPage />
          </Route>
          <Route path="/account/faq" exact>
            <FaqPage />
          </Route>
          <Route path="/account/speedtest">
            <SpeedtestPage />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

export default AccountPage;
