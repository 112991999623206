import { List, ListDisplayType, Page } from "@blacknut/javascript-sdk/dist";
import { logD, logW } from "@blacknut/logging/dist";
import {
  AppLayout,
  NotificationType,
  State,
  StorageKey,
  addNotification,
  useLayout,
} from "@blacknut/react-client-core/lib";
import { getSectionIdFromPath } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import NotificationInstallComponent from "src/components/NotificationsInstall";
import { ViewOrNot } from "src/components/NotificationsInstall/ViewOrNot";
import { detectDevice } from "src/services/detectDevice";
import { ReactComponent as BlacknutLogo } from "../../assets/dist/logo.svg";
import { ReactComponent as FavoritePlaceholder } from "../../assets/dist/placeholder_list_empty.svg";
import { getNumColumns } from "../../theme/dimens";
import { OrientationType, useOrientation } from "../../utils/OrientationContext";
import { LOGGING_TAG, isInStandaloneMode } from "../../utils/Utils";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
import { PrimaryButton } from "../Button/V2Button";
import CircularProgress from "../CircularProgress/CircularProgress";
import FeaturedTileList from "../List/FeaturedTileList";
import { usePreview } from "../Modals/PreviewContext";
import BannerView from "./componentView/BannerView";
import BrandedView from "./componentView/BrandedView";
import FeaturedView from "./componentView/FeaturedView";
import FlatView from "./componentView/FlatView";
import FullView from "./componentView/FullView";
import HeroView from "./componentView/HeroView";
import RandomView from "./componentView/RandomView";
import styles from "./styles.module.scss";
const FLAT_FEATURE_TILE_WEIGHT = 2;
const DEBUG_SCROLLABLE = false;

const filter = (page: Page, layout: AppLayout, orientation: OrientationType) => {
  const data = page.lists || [];
  const nbCols = 6; // same nbCols for all devices
  const isLandscape = orientation === "LANDSCAPE";
  const nbColsFeatured =
    layout === AppLayout.PHONE && isLandscape
      ? 3
      : FLAT_FEATURE_TILE_WEIGHT * (nbCols - FLAT_FEATURE_TILE_WEIGHT) + 1;
  return data
    .filter((l) => l.total > 0)
    .map((_l) => {
      const list = DEBUG_SCROLLABLE ? { ..._l, tiles: [..._l.tiles, ..._l.tiles] } : _l;
      switch (list.display) {
        case ListDisplayType.FLAT_FEATURED:
          if (layout === AppLayout.PHONE || layout === AppLayout.TABLET) {
            return list;
          }
          return { ...list, tiles: list.tiles.slice(0, nbColsFeatured) };
        case ListDisplayType.FLAT:
          if (layout === AppLayout.TV || layout === AppLayout.DESKTOP) {
            return { ...list, tiles: list.tiles.slice(0, nbCols), total: nbCols };
          }

          //FIXME can flat list be on multiple rows
          // To be checked with romu

          // If more link : single row if nbCols
          let nbRows = 1;
          if (!list.moreLink) {
            const nbItems = list.tiles.length;
            nbRows = Math.ceil(nbItems / nbCols);
            if (nbItems % nbCols != 0 && nbRows > 1) {
              nbRows--; // last row not complete
            }
            nbRows = Math.min(nbRows, orientation === "PORTRAIT" ? 4 : 3);
          }

          return {
            ...list,
            tiles: list.tiles.slice(0, nbRows * nbCols),
            total: nbRows * nbCols,
          };

        case ListDisplayType.BRANDED:
          if (layout === AppLayout.PHONE || layout === AppLayout.TABLET) {
            return list;
          }
          return { ...list, tiles: list.tiles.slice(0, nbCols) };
      }
      return list;
    });
};

interface CatalogProps {
  page: Page;
  onPaginate: () => void;
  paginating: boolean;
}

const getLeaveFor = ({
  data,
  page,
  index,
}: {
  page: Page;
  data: List[];
  index: number;
}) => {
  const down =
    index < data.length - 1
      ? "@" + getSectionIdFromPath(`/${page.uuid}/${data[index + 1].uuid}`)
      : undefined;
  const up =
    index > 0
      ? "@" + getSectionIdFromPath(`/${page.uuid}/${data[index - 1].uuid}`)
      : undefined;

  return { up, down };
};

const Catalog = ({ page, onPaginate, paginating }: CatalogProps) => {
  const { layout } = useSelector((state: State) => state.globalState);
  const { orientation } = useOrientation();
  const dispatch = useDispatch();
  const { active: previewActive } = usePreview();
  const data = useMemo(
    () => filter(page, layout, orientation),
    [page, layout, orientation],
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [installNotif, setInstallNotif] = useState(
    localStorage.getItem(StorageKey.INSTALL_NOTIF),
  );
  const deviceInfos = detectDevice(navigator.userAgent);

  useEffect(() => {
    if (previewActive) return;
    window.addEventListener("storage", () => {
      setInstallNotif(localStorage.getItem(StorageKey.INSTALL_NOTIF));
    });
    return () => {
      window.removeEventListener("storage", () => {
        setInstallNotif(ViewOrNot(deviceInfos));
      });
    };
  }, [deviceInfos, previewActive]);
  const [paginationThresholdRef, inView] = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (inView && page.lists.length > 0) {
      logD(LOGGING_TAG, "Reached bottom");
      if (page.total > page.lists.length && !paginating) {
        logD(LOGGING_TAG, "Start pagination");
        onPaginate();
      } else {
        // case of list pagination
        const l = page.lists[page.lists.length - 1];
        if (l.total > l.tiles.length && !paginating) {
          onPaginate();
        }
      }
    }
  }, [inView, onPaginate, page, paginating]);

  const usingLayout = useLayout();
  const isOnTv = usingLayout === AppLayout.TV;
  const onFocusCenterContent = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      if (layout !== AppLayout.TV) {
        return;
      }
      e.currentTarget.scrollIntoView({ block: "center" });
    },
    [layout],
  );

  useEffect(() => {
    if (localStorage.getItem(StorageKey.WELCOME_NOTIF) == "true") {
      addNotification({
        type: NotificationType.SUCCESS,
        time: new Date().getTime(),
        message: "notifications.welcomeNewUser.success",
      })(dispatch);
      localStorage.removeItem(StorageKey.WELCOME_NOTIF);
    }
  }, [dispatch]);

  const scrollable = layout === AppLayout.PHONE || layout === AppLayout.TABLET;

  return (
    <div className={styles.container} ref={containerRef}>
      {installNotif === "true" && !isInStandaloneMode() && !isOnTv && !previewActive && (
        <NotificationInstallComponent deviceInfos={deviceInfos} />
      )}
      {isOnTv && <BlacknutLogo className={styles.logo} />}
      <ScrollRestorer id="main" />
      {data.length === 0 && (
        <div className={styles.emptyContainer}>
          <FavoritePlaceholder />
          <span className={styles.text}>{t("list.empty")}</span>
          {layout === AppLayout.TV && (
            <PrimaryButton onClick={history.goBack}>{t("buttons.back")}</PrimaryButton>
          )}
        </div>
      )}
      {data.map((e, index) => {
        switch (e.display) {
          case ListDisplayType.HERO:
            return (
              <HeroView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                key={e.uuid}
              />
            );
          case ListDisplayType.FLAT_FEATURED:
            if (layout !== AppLayout.PHONE || orientation !== "LANDSCAPE") {
              return (
                <FeaturedTileList
                  list={e}
                  key={e.uuid}
                  leaveFor={getLeaveFor({ data, page, index })}
                  onFocus={onFocusCenterContent}
                  scrollable={scrollable}
                  className={clsx(styles.featured, scrollable && styles.scrollable)}
                />
              );
            }
            return (
              <FlatView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                onFocus={onFocusCenterContent}
                className={clsx(styles.flat, scrollable && styles.scrollable)}
                scrollable={scrollable}
              />
            );
          case ListDisplayType.FULL:
            if (data.length > 2) {
              return (
                <FlatView
                  list={e}
                  leaveFor={getLeaveFor({ data, page, index })}
                  onFocus={onFocusCenterContent}
                  className={clsx(styles.flat, scrollable && styles.scrollable)}
                />
              );
            }
            return (
              <FullView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                onFocus={onFocusCenterContent}
              />
            );
          case ListDisplayType.FLAT:
            if (layout === AppLayout.PHONE && !e.title) {
              return (
                <FullView
                  list={e}
                  key={e.uuid}
                  leaveFor={getLeaveFor({ data, page, index })}
                  onFocus={onFocusCenterContent}
                />
              );
            }

            return (
              <FlatView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                onFocus={onFocusCenterContent}
                scrollable={scrollable}
                className={clsx(styles.flat, scrollable && styles.scrollable)}
              />
            );
          case ListDisplayType.FEATURED:
            return (
              <FeaturedView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                onFocus={onFocusCenterContent}
              />
            );
          case ListDisplayType.BANNER:
            return (
              <BannerView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                onFocus={onFocusCenterContent}
              />
            );
          case ListDisplayType.BRANDED:
            return (
              <BrandedView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                onFocus={onFocusCenterContent}
                scrollable={scrollable}
                className={clsx(styles.branded, scrollable && styles.scrollable)}
              />
            );
          case ListDisplayType.RANDOM:
            return (
              <RandomView
                list={e}
                leaveFor={getLeaveFor({ data, page, index })}
                onFocus={onFocusCenterContent}
              />
            );
          default:
            logW(LOGGING_TAG, "Unhandled: ", e.display);
            return null;
        }
      })}
      <div ref={paginationThresholdRef} />
      {paginating && <CircularProgress className={styles.paginationLoader} size={36} />}
    </div>
  );
};

export default Catalog;
