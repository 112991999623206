import { isTV, UserAgent } from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import {
  apiService,
  AppLayout,
  ClientDeviceType,
  configureStore,
  GameStreamProtocol,
  getConfig,
  leMagService,
  logout,
  PlayerLogLevel,
  setAppLayout,
  setAUDID,
  setDeeplink,
  setLocale,
  setSupportedProtocols,
  setUpdateAvailable,
  StorageKey,
  syncTokens,
  Token,
  UserToken,
} from "@blacknut/react-client-core/lib";
import { SpatialNavigationProvider } from "@blacknut/spatialnav-sdk/dist";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Provider, useDispatch } from "react-redux";
import AppRouter from "./appRouter";
import { useDatadog } from "./datadog";
import { CustomBackContextProvider } from "./hooks/useBackKey";
import electronService from "./services/ElectronService";
import { LocalStorageService } from "./services/LocalStorageService";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ThemeContextProvider } from "./theme/ThemeProvider";
import { BatchContextProvider } from "./utils/BatchContext";
import { nativeBridge } from "./utils/NativeBridge";
import { OrientationContextProvider } from "./utils/OrientationContext";
import { ScrollContextProvider } from "./utils/scroll/ScrollProvider";
import { TileWidthContextProvider } from "./utils/TileWidthProvider";
import { LOGGING_TAG } from "./utils/Utils";
import { PreviewContextProvider } from "./components/Modals/PreviewContext";

const matchLocale = (current: string, expected: string) => {
  const matchFull = current && current.match(/([a-zA-Z]{2})-[a-zA-Z]{2}/);
  const matchPartial = current && current.match(/([a-zA-Z]{2})/);
  return (
    (matchPartial && matchPartial[1].toLowerCase() == expected) ||
    (matchFull && matchFull[1].toLowerCase()) === expected
  );
};
const storageService = new LocalStorageService();

let localeFromLocalStorage = localStorage.getItem(StorageKey.LOCALE);
if (localeFromLocalStorage) {
  localeFromLocalStorage = JSON.parse(localeFromLocalStorage);
}
console.debug("locale from local storage %o", localeFromLocalStorage);

let apiPathFromOrigin: string | undefined = undefined;
if (window.origin && window.origin.match(/dev/)) {
  apiPathFromOrigin = "https://dev-api.blacknut.net/api";
} else if (window.origin && window.origin.match(/staging/)) {
  apiPathFromOrigin = "https://staging-api.blacknut.net/api";
}
console.debug("api path from origin", apiPathFromOrigin);

let apiPath =
  apiPathFromOrigin || process.env.REACT_APP_API_PATH || "https://api.blacknut.com/api";
let tokenFromCommandLine: Token | undefined;
let localeFromCmdLine: string | undefined;
let deeplinks: string[] | undefined;
const qs = new URLSearchParams(window.location.search);
const accessTokenFromQuery = qs.get("at");
const refreshTokenFromQuery = qs.get("rt");
const audidFromQuery = qs.get("audid");
const isNewUserFromQuery = qs.get("isNewUser");
const isTVLegacy = qs.get("option");

localStorage.setItem(StorageKey.TV_LEGACY, JSON.stringify(isTVLegacy === "legacy"));

if (electronService.isAvailable()) {
  const args: string[] = electronService.getCommandLineArgs();
  console.debug("Command line args %o", args);
  if (args) {
    for (let i = 0; i < args.length; i++) {
      if (args[i] === "--apiPath" && i < args.length - 1) {
        apiPath = args[i + 1];
      }
      if (args[i].startsWith("--token=")) {
        /* windows*/
        tokenFromCommandLine = JSON.parse(args[i].slice(8));
      } else if (args[i] === "--token" && i < args.length - 1) {
        tokenFromCommandLine = JSON.parse(args[i + 1]);
      } else if (args[i] === "--locale" && i < args.length - 1) {
        localeFromCmdLine = args[i + 1];
      }
    }
  }
  deeplinks = args.filter((s) => s.startsWith("blacknut://"));
}
console.debug("locale from command line:", localeFromCmdLine);
console.debug("tokenFromCommandLine %o", tokenFromCommandLine);

localStorage.setItem(StorageKey.INSTALL_NOTIF, "true");
if (isNewUserFromQuery) localStorage.setItem(StorageKey.WELCOME_NOTIF, "true");

/*****************
 * Recovery from <= electron v3
 * Retrieves token, profile data & theme from file:// local storage to current one
 */
if (electronService.isAvailable()) {
  const opts = electronService.recoverFromV3();
  console.log("recoverFromV3 ", opts);
  if (opts.token) {
    localStorage.setItem(StorageKey.TOKEN, opts.token);
  }
  if (opts.profilesData) {
    localStorage.setItem(StorageKey.PROFILES_DATA, opts.profilesData);
  }
  if (opts.theme) {
    localStorage.setItem(StorageKey.THEME_BW, opts.theme);
  }
}

let token: UserToken | undefined = undefined;
///////////////
// Token init
// First use query params
// Then use electron command line
///////////////
if (accessTokenFromQuery && refreshTokenFromQuery) {
  const uat = qs.get("uat");
  const urt = qs.get("urt");
  token = {
    familyToken: {
      accessToken: accessTokenFromQuery,
      refreshToken: refreshTokenFromQuery,
    },
    userToken:
      uat && urt
        ? {
            accessToken: uat,
            refreshToken: urt,
          }
        : undefined,
  } as any /*FIXME*/;
}

if (electronService.isAvailable() && tokenFromCommandLine && !token) {
  logD(LOGGING_TAG, "Got token from electron %o", tokenFromCommandLine);
  tokenFromCommandLine = token;
}

if (token) {
  storageService.setItem(StorageKey.TOKEN, token).catch((e) => {
    console.error("Caught error persiting token", e);
  });
}

const store = configureStore(
  storageService,
  {
    apiPath,
    debug: process.env.NODE_ENV === "development",
    playerDebug: PlayerLogLevel.INFO,
    protocole:
      isTV() || !electronService.isAvailable()
        ? GameStreamProtocol.WEBRTC
        : GameStreamProtocol.AUTO,
    apiVersion: process.env.REACT_APP_API_VERSION || "5.0.1",
    allowAnonymous: !!process.env.REACT_APP_ALLOW_ANONYMOUS,
    startUrl: window.location.origin,
  },
  { logger: process.env.NODE_ENV === "development" },
);
store.dispatch(setSupportedProtocols([GameStreamProtocol.WEBRTC]));

if (deeplinks && deeplinks.length > 0) {
  setDeeplink(deeplinks[0])(store.dispatch);
}

if (audidFromQuery) {
  setAUDID(audidFromQuery)(store.dispatch);
}

if (localeFromCmdLine) {
  setLocale(localeFromCmdLine)(store.dispatch);
}

const locale =
  localeFromCmdLine ||
  localeFromLocalStorage ||
  navigator.language ||
  navigator["userLanguage"];
if (matchLocale(locale, "fr")) {
  leMagService.locale = "fr";
} else if (matchLocale(locale, "es")) {
  leMagService.locale = "es";
} else {
  leMagService.locale = "en";
}

const Datadog = () => {
  useDatadog();
  return null;
};

const TabAwareTokenListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === StorageKey.TOKEN) {
        logD(LOGGING_TAG, "TOKEN CHANGED : %o", event.newValue);
        try {
          if (event.newValue) {
            const oTokens = JSON.parse(event.newValue);
            if (event.storageArea === localStorage) {
              dispatch(syncTokens(oTokens));
            }
          } else {
            logout()(store.dispatch);
          }
        } catch (e) {
          logE(LOGGING_TAG, "Caugth error parsing token", e);
        }
      }
    };
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [dispatch]);
  return null;
};
const App = () => {
  const [inited, setInited] = useState(false);
  React.useEffect(() => {
    const init = async () => {
      try {
        await nativeBridge.init();
      } catch (e) {
        logE(LOGGING_TAG, "Caught error init native bridge", e);
      }
      console.log("inited", nativeBridge.module);

      let deviceInfo: UserAgent = { ...apiService.userAgent };
      if (nativeBridge.module?.getDeviceInfo) {
        try {
          deviceInfo = await nativeBridge.module?.getDeviceInfo();
        } catch (e) {
          logE(LOGGING_TAG, "Caught error getting device info", e);
        }
      }
      if (deviceInfo) {
        apiService.userAgent = {
          ...apiService.userAgent,
          ...deviceInfo,
          apiVersion: apiService.userAgent.apiVersion,
        };
      }
      if (apiService.userAgent.client === ClientDeviceType.PHONE) {
        store.dispatch(setAppLayout(AppLayout.PHONE));
      } else if (apiService.userAgent.client == ClientDeviceType.TABLET) {
        store.dispatch(setAppLayout(AppLayout.TABLET));
      } else if (apiService.userAgent.client == ClientDeviceType.TV) {
        store.dispatch(setAppLayout(AppLayout.TV));
        document.body.classList.add("lowend");
      }

      ReactModal.setAppElement("body");

      // could be done in App.router but in order to be coherent with the android part
      // do this here
      const savedAPI = localStorage.getItem(StorageKey.PLATFORM);
      apiService.endpoint = savedAPI ? JSON.parse(savedAPI) : apiPath;
      try {
        const config = await getConfig()(store.dispatch);
        if (config.deviceKind !== apiService.userAgent.client) {
          switch (config.deviceKind) {
            case ClientDeviceType.DESKTOP:
              apiService.userAgent = {
                ...apiService.userAgent,
                client: ClientDeviceType.DESKTOP,
                deviceType: "DESKTOP",
              };
              break;
            case ClientDeviceType.TABLET:
              apiService.userAgent = {
                ...apiService.userAgent,
                client: ClientDeviceType.TABLET,
                deviceType: "TABLET",
              };
              break;
            case ClientDeviceType.PHONE:
              apiService.userAgent = {
                ...apiService.userAgent,
                client: ClientDeviceType.PHONE,
                deviceType: "MOBILE",
              };
              break;
            case ClientDeviceType.TV:
              apiService.userAgent = {
                ...apiService.userAgent,
                client: ClientDeviceType.TV,
                deviceType: "TV",
              };
              break;
          }
          logD(LOGGING_TAG, "Overriding device kind from server: %o", config.deviceKind);
        }
      } catch (e) {
        logE(LOGGING_TAG, "Caught error getting config");
      }

      if (apiService.userAgent.client === ClientDeviceType.TV) {
        document.documentElement.style.fontSize = "14px";

        document
          .querySelector("meta[name=viewport]")
          ?.setAttribute(
            "content",
            "width=device-width, initial-scale=" +
              window.innerWidth / 1920 +
              ", maximum-scale=1.0, user-scalable=0",
          );
      }

      setInited(true);
    };
    init();
  }, []);

  return (
    <Provider store={store}>
      <Datadog />
      <TabAwareTokenListener />
      <ThemeContextProvider>
        <PreviewContextProvider>
          <OrientationContextProvider>
            {inited && (
              <>
                <TileWidthContextProvider>
                  <BatchContextProvider>
                    <CustomBackContextProvider>
                      <SpatialNavigationProvider
                        defaultEnabled={
                          apiService.userAgent.client == ClientDeviceType.TV
                        }
                      >
                        <ScrollContextProvider>
                          <AppRouter tokenFromCommandLine={!!token?.userToken} />
                        </ScrollContextProvider>
                      </SpatialNavigationProvider>
                    </CustomBackContextProvider>
                  </BatchContextProvider>
                </TileWidthContextProvider>
              </>
            )}
          </OrientationContextProvider>
        </PreviewContextProvider>
      </ThemeContextProvider>
    </Provider>
  );
};
export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (electronService.isAvailable()) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register({
    onSuccess: () => {
      console.log("onSuccess");
    },
    onUpdate: (registration) => {
      registration.unregister().then(() => {
        setUpdateAvailable(true)(store.dispatch);
      });
    },
  });
}
