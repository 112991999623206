import { usePrevious } from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useState } from "react";
import { useTheme } from "src/theme/ThemeProvider";
import { TextInputStyle } from "../../theme/Theme";
import { Container, ContainerVisibility } from "./ProfilePin.style";
import ProfilePinTextField from "./ProfilePinTextField";

export interface ProfilePinProps extends React.HTMLAttributes<HTMLDivElement> {
  onPinEntered: (s: string) => void;
  errored: boolean;
  size?: "large" | "medium" | "small";
  value?: string;
  testID?: string;
  secured?: boolean;
  inputStyle?: Partial<TextInputStyle>;
  pinEditing?: boolean;
  setPinEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  handlePinChanged?: (s: string) => void;
}

const ProfilePin = ({
  value,
  errored,
  size = "small",
  className,
  testID,
  onPinEntered,
  secured = true,
  inputStyle,
  pinEditing = false,
  setPinEditing,
  handlePinChanged,
}: ProfilePinProps) => {
  const { theme } = useTheme();
  const [isPinHidden, setIsPinHidden] = useState<boolean>(true);
  const { active: spatialNavigationActive } = useSpatialNavigation();

  let char0AtInit = "";
  let char1AtInit = "";
  let char2AtInit = "";
  let char3AtInit = "";
  if (value && value.length >= 4) {
    char0AtInit = value.charAt(0);
    char1AtInit = value.charAt(1);
    char2AtInit = value.charAt(2);
    char3AtInit = value.charAt(3);
  }

  const [char0, setChar0] = React.useState(char0AtInit);
  const [char1, setChar1] = React.useState(char1AtInit);
  const [char2, setChar2] = React.useState(char2AtInit);
  const [char3, setChar3] = React.useState(char3AtInit);
  const emptyValueOnFocus = React.useRef(true);
  const ref0 = React.useRef<HTMLInputElement | null>(null);
  const ref1 = React.useRef<HTMLInputElement | null>(null);
  const ref2 = React.useRef<HTMLInputElement | null>(null);
  const ref3 = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (value && value.length >= 4) {
      setChar0(value.charAt(0));
      setChar1(value.charAt(1));
      setChar2(value.charAt(2));
      setChar3(value.charAt(3));
    }
  }, [value]);

  const onChange = React.useCallback(
    (ch1: string, ch2: string, ch3: string, ch4: string) => {
      if (!pinEditing && setPinEditing) setPinEditing(true);
      const newVal = `${ch1}${ch2}${ch3}${ch4}`;
      if (ch1 && ch2 && ch3 && ch4) {
        onPinEntered(newVal);
        emptyValueOnFocus.current = true;
      } else if (handlePinChanged) {
        handlePinChanged(newVal);
      }
    },
    [handlePinChanged, onPinEntered, pinEditing, setPinEditing],
  );

  const previousErrored = usePrevious(errored);
  React.useEffect(() => {
    if (previousErrored != undefined && !previousErrored && errored) {
      setChar0("");
      setChar1("");
      setChar2("");
      setChar3("");
    }
  }, [errored, previousErrored]);

  const onChangePin0 = React.useCallback(
    (s: string | null, inputHasFocus: boolean) => {
      const ch0 = s && s.length > 0 ? s.charAt(0) : "";
      setChar0(ch0);
      if (!inputHasFocus && emptyValueOnFocus.current) {
        setChar1("");
        setChar2("");
        setChar3("");
        onChange(ch0, "", "", "");
        emptyValueOnFocus.current = false;
      } else {
        onChange(ch0, char1, char2, char3);
      }

      if (s) {
        if (inputHasFocus) {
          setTimeout(() => {
            ref1.current && ref1.current.focus();
          }, 0);
        } else {
          //Give focus to the surrounding container
          const focusable = ref1.current && ref1.current.closest("[tabIndex]");
          if (focusable) {
            (focusable as HTMLElement).focus();
          }
        }
      }
    },
    [char1, char2, char3, onChange],
  );

  const onChangePin1 = React.useCallback(
    (s: string | null, inputHasFocus: boolean) => {
      const ch1 = s && s.length > 0 ? s.charAt(0) : "";

      setChar1(ch1);

      if (!inputHasFocus && emptyValueOnFocus.current) {
        setChar0("");
        setChar2("");
        setChar3("");
        onChange("", ch1, "", "");
        emptyValueOnFocus.current = false;
      } else {
        onChange(char0, ch1, char2, char3);
      }

      if (s) {
        if (inputHasFocus) {
          setTimeout(() => {
            ref2.current && ref2.current.focus();
          }, 0);
        } else {
          //Give focus to the surrounding container
          const focusable = ref2.current && ref2.current.closest("[tabIndex]");
          if (focusable) {
            (focusable as HTMLElement).focus();
          }
        }
      }
    },
    [char0, char2, char3, onChange],
  );

  const onChangePin2 = React.useCallback(
    (s: string | null, inputHasFocus: boolean) => {
      const ch2 = s && s.length > 0 ? s.charAt(0) : "";
      setChar2(ch2);

      if (!inputHasFocus && emptyValueOnFocus.current) {
        setChar0("");
        setChar1("");
        setChar3("");
        onChange("", "", ch2, "");
        emptyValueOnFocus.current = false;
      } else {
        onChange(char0, char1, ch2, char3);
      }

      if (s) {
        if (inputHasFocus) {
          setTimeout(() => {
            ref3.current && ref3.current.focus();
          }, 0);
        } else {
          //Give focus to the surrounding container
          const focusable = ref3.current && ref3.current.closest("[tabIndex]");
          if (focusable) {
            (focusable as HTMLElement).focus();
          }
        }
      }
    },
    [char0, char1, char3, onChange],
  );
  const onChangePin3 = React.useCallback(
    (s: string | null, inputHasFocus: boolean) => {
      const ch3 = s && s.length > 0 ? s.charAt(0) : "";
      setChar3(ch3);

      if (!inputHasFocus && emptyValueOnFocus.current) {
        setChar0("");
        setChar1("");
        setChar2("");
        onChange("", "", "", ch3);
        emptyValueOnFocus.current = false;
      } else {
        onChange(char0, char1, char2, ch3);
      }
      if (s) {
        ref3.current && ref3.current.blur();
      }
    },
    [char0, char1, char2, onChange],
  );

  const onFocusInput = React.useCallback(() => {
    // clears input the first time
    if (emptyValueOnFocus.current) {
      setChar0("");
      setChar1("");
      setChar2("");
      setChar3("");
      onChange("", "", "", "");
      emptyValueOnFocus.current = false;
    }
  }, [onChange]);

  return (
    <Container className={className} data-testid={testID}>
      <ProfilePinTextField
        testID="code0"
        value={char0}
        size={size}
        errored={errored}
        onChangePin={onChangePin0}
        inputRef={ref0}
        onInputFocus={onFocusInput}
        secured={pinEditing ? isPinHidden : secured}
        inputStyle={inputStyle}
      />

      <ProfilePinTextField
        testID="code1"
        value={char1}
        size={size}
        errored={errored}
        onChangePin={onChangePin1}
        inputRef={ref1}
        onInputFocus={onFocusInput}
        secured={pinEditing ? isPinHidden : secured}
        inputStyle={inputStyle}
      />

      <ProfilePinTextField
        testID="code2"
        value={char2}
        size={size}
        errored={errored}
        onChangePin={onChangePin2}
        inputRef={ref2}
        onInputFocus={onFocusInput}
        secured={pinEditing ? isPinHidden : secured}
        inputStyle={inputStyle}
      />

      <ProfilePinTextField
        testID="code3"
        value={char3}
        size={size}
        errored={errored}
        onChangePin={onChangePin3}
        inputRef={ref3}
        onInputFocus={onFocusInput}
        secured={pinEditing ? isPinHidden : secured}
        inputStyle={inputStyle}
      />

      {pinEditing && theme.images.VisibilityOff && theme.images.Visibility && (
        <ContainerVisibility
          {...(!spatialNavigationActive
            ? {
                onClick: () => setIsPinHidden(!isPinHidden),
              }
            : {
                onPressEnter: () => setIsPinHidden(!isPinHidden),
              })}
        >
          {isPinHidden ? <theme.images.VisibilityOff /> : <theme.images.Visibility />}
        </ContainerVisibility>
      )}
    </Container>
  );
};
export default ProfilePin;
