import {
  AppLayout,
  GameGenre,
  ImageType,
  LeMagArticle,
  StorageKey,
  useGame,
  useLayout,
} from "@blacknut/react-client-core/lib";
import {
  FocusableSection,
  NavigationFailedEvent,
  getSectionIdFromPath,
} from "@blacknut/spatialnav-sdk/dist";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { HeaderStyle } from "src/theme/Theme";
import { hexToRgb } from "src/utils/ColorUtils";
import { ReactComponent as FullscreenOnTop } from "../../../assets/dist/ic_arrow_up.svg";
import { ReactComponent as FavoriteOff } from "../../../assets/dist/ic_favorite_off_filled.svg";
import { ReactComponent as FavoriteOffActive } from "../../../assets/dist/ic_favorite_off_filled_active.svg";
import { ReactComponent as FavoriteOn } from "../../../assets/dist/ic_favorite_on_filled.svg";
import { ReactComponent as FavoriteOnActive } from "../../../assets/dist/ic_favorite_on_filled_active.svg";
import { ReactComponent as Fullscreen } from "../../../assets/dist/ic_fullscreen_filled.svg";
import { ReactComponent as FullscreenActive } from "../../../assets/dist/ic_fullscreen_filled_active.svg";
import Sharing from "../../../components/Button/ShareButton/ShareButton";
import HeaderBackButton from "../../../components/Header/HeaderBackButton";
import { useHeader } from "../../../components/Header/HeaderProvider";
import LeMagArticleList from "../../../components/LeMagArticleList/LeMagArticleList";
import LeMagArticleModal from "../../../components/LeMagArticleModal/LeMagArticleModal";
import HorizontalTileList from "../../../components/List/HorizontalTileList";
import { useMenu } from "../../../components/Menu/MenuProvider";
import { ModalSubscription, useModal } from "../../../components/Modals/ModalContext";
import { useTheme } from "../../../theme/ThemeProvider";
import { BatchEventOpts, useBatch } from "../../../utils/BatchContext";
import { lockOrientationLandscape, unlockOrientation } from "../../../utils/Utils";
import { useErrorHandling } from "../../../utils/V2ErrorHandler";
import { ScrollRestorer } from "../../../utils/scroll/ScrollProvider";
import useAnonymousCheck from "../../../utils/useAnonymousCheck";
import useGameSpatialNavigation from "../useGameSpatialNavigation";
import useReco from "../useReco";
import { GameGenreContainer, ButtonGenre } from "../PageGameGenre/PageGameGenre.styles";
import {
  BackgroundImage,
  ButtonsContainer,
  Container,
  ContainerContent,
  ContainerDescription,
  ContainerDescriptionLeft,
  ContainerDescriptionRight,
  ContainerUpTpFS,
  Content,
  Description,
  DescriptionsInfos,
  FreeText,
  FullscreenOnTopTV,
  Header,
  InfoContainer,
  LeMagContainer,
  MainLoading,
  Mask,
  MaskVideoGradient,
  ReadMore,
  RecoContainer,
  RecoTitle,
  RecoTitleContainer,
  SectionHeader,
  Separator,
  SeparatorOnLandscape,
  SeparatorOnlyDesktop,
  StyledFavBtn,
  StyledFullscreenBtn,
  StyledGameInfo,
  StyledPlayBtn,
  Title,
  Video,
  BackgroundImageTVLegacy,
} from "./Game.landscape.styles";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";

const GamePage = ({
  videoFullscreen,
  setVideoFullscreen,
}: {
  videoFullscreen: boolean;
  setVideoFullscreen: (fullscreen: boolean) => void;
}) => {
  const params = useParams<{ id: string }>();
  const {
    loading,
    loadingError,
    dismissLoadingError,
    game,
    favoritePending,
    favorite,
    toggleFavorite,
    favoriteError,
    dismissFavoriteError,
    config,
  } = useGame({
    gameId: params.id,
  });
  const { showMenu, hideMenu } = useMenu();

  const history = useHistory();
  let developer = "";

  if (game && game.developer) {
    developer += game.developer.name;
  }

  if (game && game.publisher) {
    if (developer) {
      developer += " | ";
    }
    developer += game.publisher.name;
  }

  const { trackEvent } = useBatch();
  useEffect(() => {
    if (game) {
      const opts: BatchEventOpts = {
        id: game.id,
        name: game.name,
      };
      if (game.genre) {
        opts["category"] = game.genre;
      }
      trackEvent("visited_game", opts);
    }
  }, [game, trackEvent]);

  const backgroundImage =
    game && game.images && game.images.find((img) => img.type === ImageType.BACKGROUND);

  const video = game && game.videos && game.videos.find((v) => v.type === "trailer");

  let descHTMLNoP = "";
  if (game && game.descriptionHtml) {
    descHTMLNoP = game.descriptionHtml.replace("<p>", "").replace("</p>", "");
  }

  let freeTextHTMLNoP = "";
  if (game && game.freeText) {
    freeTextHTMLNoP = game.freeText.replace("<p>", "").replace("</p>", "");
  }
  const { theme } = useTheme();
  const layout = useLayout();
  const isOnTv = layout === AppLayout.TV;

  const { checkForAnonymous } = useAnonymousCheck();
  const onPlayClicked = useCallback(() => {
    if (checkForAnonymous()) {
      history.push(`/game/${game?.id}/play`);
    }
  }, [checkForAnonymous, history, game?.id]);

  const _toggleFavorite = useCallback(() => {
    if (checkForAnonymous()) {
      toggleFavorite();
    }
  }, [checkForAnonymous, toggleFavorite]);

  const { t } = useTranslation();

  const {
    setHeaderLeft,
    setTitle: setHeaderTitle,
    setHeaderRight,
    hideHeader,
    showHeader,
    setHeaderStyle,
  } = useHeader();

  useEffect(() => {
    setHeaderTitle("");
    setHeaderRight(undefined);
    setHeaderLeft(<HeaderBackButton />);
    setHeaderStyle({
      ...theme.headerStyle,
    });
    if (isOnTv) {
      hideHeader();
    }
    return () => {
      if (isOnTv) {
        hideHeader();
      }
      setHeaderStyle(undefined);
    };
  }, [
    setHeaderLeft,
    isOnTv,
    showHeader,
    hideHeader,
    setHeaderRight,
    setHeaderTitle,
    setHeaderStyle,
    theme.headerStyle,
  ]);

  const colorHeaderInterpolation = (color: string) => {
    const position = refContainer.current?.getBoundingClientRect()?.top;
    const codeColorHexa = hexToRgb(color);
    const colorRGB = `RGBA(${codeColorHexa?.r},${codeColorHexa?.g},${
      codeColorHexa?.b
    }, ${Math.abs(position ? position / 250 : 0)})`;
    return colorRGB;
  };
  const isTVLegacy = localStorage.getItem(StorageKey.TV_LEGACY) === "true";
  useEffect(() => {
    const handleScroll = debounce(
      () => {
        const positionVideo =
          refInformationContainer.current?.getBoundingClientRect()?.bottom;
        if (positionVideo && positionVideo < 200 && !videoFullscreen) {
          videoRef.current?.pause();
        } else if (!isTVLegacy) {
          videoRef.current?.play();
        }
        if (window.scrollY > 100) {
          const newHeaderStyle: Partial<HeaderStyle> = {
            ...theme.headerStyle,
            textStyle: {
              color: colorHeaderInterpolation(
                theme.headerStyle.textStyle?.color ?? "#FFFFFF",
              ),
            },
          };
          setHeaderLeft(
            <HeaderBackButton title={game?.name} headerStyle={newHeaderStyle} />,
          );
          setHeaderStyle({
            ...theme.headerStyle,
            borderWidth: 0,
          });
        } else {
          setHeaderLeft(<HeaderBackButton />);
          setHeaderStyle({
            ...theme.headerStyle,
          });
        }
      },
      5,
      { leading: true, trailing: true },
    );
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    game,
    setHeaderStyle,
    setHeaderLeft,
    theme.headerStyle,
    videoFullscreen,
    isTVLegacy,
  ]);

  const onExitFullscreen = useCallback(
    (e?: React.UIEvent<HTMLElement>, goBack?: boolean) => {
      if (goBack) window.history.back(); // to nullify the previously set --> window.history.pushState(null, "", location.href);
      if (e) e.stopPropagation();
      if (!isOnTv) showHeader();

      showMenu();
      setVideoFullscreen(false);
      unlockOrientation();
    },
    [setVideoFullscreen, showHeader, showMenu, isOnTv],
  );

  const onEnterFullscreen = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (e) {
        e.stopPropagation();
      }
      setVideoFullscreen(true);
      hideMenu();
      hideHeader();
      lockOrientationLandscape();

      const handlePopstate = () => {
        onExitFullscreen();
        window.removeEventListener("popstate", handlePopstate);
      };

      window.history.pushState(null, "", location.href);
      window.addEventListener("popstate", handlePopstate);
    },
    [hideHeader, hideMenu, onExitFullscreen, setVideoFullscreen],
  );

  useErrorHandling({
    error: loadingError,
    clearError: dismissLoadingError,
    callback: history.goBack,
  });
  useErrorHandling({ error: favoriteError, clearError: dismissFavoriteError });

  const recommandations = useReco({ game });
  const leMagSectionIdMain = `@${getSectionIdFromPath(`/${game?.id}/readMore`)}`;
  const leMagSectionIdList = `@${getSectionIdFromPath(`/${game?.id}/lemag`)}`;
  const shareButtonId = `@${getSectionIdFromPath(`/${game?.id}/share`)}`;
  const btnsSectionId = `@${getSectionIdFromPath(`/${game?.id}/btns`)}`;
  const gameGenreId = `@${getSectionIdFromPath(`/${game?.id}/gameGenre`)}`;
  const dummySectionId = `@${getSectionIdFromPath(`/${game?.id}/dummy`)}`;
  const refContainer = useRef<HTMLDivElement>(null);
  const refContainerButton = useRef<HTMLDivElement>(null);
  const refInformationContainer = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const modalSubscription = useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  useGameSpatialNavigation({
    game,
    videoFullscreen,
    leMagModalSubscription: modalSubscription,
  });

  const scrollTop = () => {
    document.documentElement.scroll &&
      document.documentElement.scroll({
        top: 0, // could be negative value
        left: 0,
        behavior: "smooth",
      });
  };

  const scrollBottom = () => {
    document.documentElement.scroll &&
      document.documentElement.scroll({
        top: document.documentElement.scrollHeight, // could be negative value
        left: 0,
        behavior: "smooth",
      });
  };

  const onNavigationFailedScrollTop = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction == "up") {
      document.documentElement.scroll({ top: 0 });
    }
  }, []);

  const closeArticleModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);
  const articles = useMemo(
    () => (game?.lemag ? game.lemag.filter((art) => art.title) : []),
    [game],
  );
  const onClickArticle = useCallback(
    (article: LeMagArticle) => {
      if (isOnTv) {
        modalSubscription.current = modalPush((props) => (
          <LeMagArticleModal
            {...props}
            game={game}
            articles={articles}
            article={article}
            onClose={closeArticleModal}
            closeButtonText={t("buttons.cancel")}
            history={history}
          />
        ));
      } else {
        const match = article.link.match(/.*\/(.*)$/);
        if (match) [history.push(`/lemag/${match[1]}`)];
      }
    },
    [isOnTv, modalPush, game, articles, closeArticleModal, t, history],
  );
  const renderGameType = useCallback(
    (genre: GameGenre) => {
      const onClick = async () => {
        history.push(`/genre/${genre.uuid}`);
      };
      return (
        <ButtonGenre testID={genre.uuid} onClick={onClick} theme={theme}>
          {genre.title}
        </ButtonGenre>
      );
    },
    [history, theme],
  );

  // show header on back
  useEffect(() => {
    showHeader();
  }, [showHeader]);

  // show menu on back
  useEffect(() => {
    showMenu();
  }, [showMenu]);

  return (
    <Container
      theme={theme}
      focusKey={params.id}
      onNavigationFailed={onNavigationFailedScrollTop}
    >
      {loading && <MainLoading size={50} />}
      <ScrollRestorer />

      {isOnTv && !loading && !videoFullscreen && (
        <ContainerUpTpFS>
          <FullscreenOnTopTV
            theme={theme}
            disabled={videoFullscreen}
            onFocus={
              onEnterFullscreen as any
            } /*FIXME oui je sais c'est mal  mais à fixer*/
          >
            <FullscreenOnTop />
            {t("game.upVideoFullscreen")}
          </FullscreenOnTopTV>
        </ContainerUpTpFS>
      )}
      <MaskVideoGradient
        className="MaskVideoGradient"
        fullscreen={videoFullscreen}
        height={document.documentElement.offsetHeight}
      />
      {/* Background video */}
      {backgroundImage && (
        <>
          <BackgroundImage src={backgroundImage.url} />
          <Mask />
        </>
      )}
      {isTVLegacy && !videoFullscreen && backgroundImage && (
        <BackgroundImageTVLegacy src={backgroundImage.url} />
      )}
      {video && (!isTVLegacy || (isTVLegacy && videoFullscreen)) && (
        <Video
          game={game}
          fullscreen={videoFullscreen}
          isOnTv={isOnTv}
          onExitFullscreen={(e) => onExitFullscreen(e, true)}
          onPlayClicked={onPlayClicked}
          muteControls={videoFullscreen}
          focusPathPrefix={`/${game.id}`}
          videoRef={videoRef}
          mute={videoFullscreen ? undefined : true}
        />
      )}
      {game && (
        <>
          <Header ref={refContainer}>
            <Title theme={theme}>{game.name}</Title>
            {/* Game info */}

            <InfoContainer ref={refContainerButton}>
              <ButtonsContainer
                focusKey="btns"
                disabled={videoFullscreen}
                leaveFor={{
                  down:
                    articles.length > 0
                      ? leMagSectionIdMain
                      : game.genres && game.genres.length > 0
                      ? gameGenreId
                      : isOnTv
                      ? dummySectionId
                      : shareButtonId,
                }}
              >
                <StyledPlayBtn
                  onFocus={scrollTop}
                  testID="play"
                  theme={theme}
                  onClick={onPlayClicked}
                >
                  {config?.features?.ericssonBoost &&
                  config?.features?.ericssonBoostSubscribed === false
                    ? t("buttons.play_hd")
                    : t("buttons.play")}
                </StyledPlayBtn>

                <StyledFavBtn
                  onFocus={scrollTop}
                  theme={theme}
                  testID="favorite"
                  disabled={favoritePending}
                  onClick={_toggleFavorite}
                >
                  {favorite && (
                    <>
                      <FavoriteOn />
                      <FavoriteOnActive />
                    </>
                  )}
                  {!favorite && (
                    <>
                      <FavoriteOff />
                      <FavoriteOffActive />
                    </>
                  )}
                </StyledFavBtn>
                <StyledFullscreenBtn
                  onFocus={scrollTop}
                  testID="maximize"
                  theme={theme}
                  onClick={onEnterFullscreen}
                >
                  <Fullscreen />
                  <FullscreenActive />
                </StyledFullscreenBtn>
              </ButtonsContainer>
            </InfoContainer>
          </Header>
          <ContainerContent theme={theme}>
            <Content theme={theme}>
              <SeparatorOnlyDesktop ref={refInformationContainer} theme={theme} />
              <ContainerDescription theme={theme}>
                <ContainerDescriptionLeft>
                  <Description dangerouslySetInnerHTML={{ __html: descHTMLNoP }} />
                  {config?.features?.lemag && articles.length > 0 && (
                    <FocusableSection
                      focusKey="readMore"
                      leaveFor={{ up: btnsSectionId, down: gameGenreId }}
                    >
                      <ReadMore
                        theme={theme}
                        onClick={() => {
                          onClickArticle(articles?.[0]);
                        }}
                      >
                        {t("buttons.readMore")}
                      </ReadMore>
                    </FocusableSection>
                  )}
                </ContainerDescriptionLeft>
                <ContainerDescriptionRight theme={theme}>
                  <StyledGameInfo separatorClassName="sep" game={game} layout={layout} />
                  <SeparatorOnLandscape theme={theme} />

                  <DescriptionsInfos>
                    {game.developer && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("game.developer", {
                            developer: game.developer.name,
                          }),
                        }}
                      />
                    )}
                    {game.publisher && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("game.publisher", {
                            publisher: game.publisher.name,
                          }),
                        }}
                      />
                    )}
                  </DescriptionsInfos>
                </ContainerDescriptionRight>
              </ContainerDescription>
              <GameGenreContainer
                focusKey="gameGenre"
                leaveFor={{
                  up: leMagSectionIdMain,
                  down: leMagSectionIdList,
                }}
              >
                {game && game.genres && game.genres.map(renderGameType)}
              </GameGenreContainer>
              {isOnTv && articles.length === 0 && (
                <FocusableSection focusKey="dummy">
                  <Focusable></Focusable>
                </FocusableSection>
              )}
              <LeMagContainer>
                {config?.features?.lemag && articles.length > 0 && (
                  <>
                    <SeparatorOnlyDesktop theme={theme} />

                    <SectionHeader theme={theme}>{t("game.lemag")}</SectionHeader>
                    <FocusableSection
                      focusKey="lemag"
                      leaveFor={{
                        up:
                          game.genres && game.genres.length > 0
                            ? gameGenreId
                            : leMagSectionIdMain,
                        down: shareButtonId,
                      }}
                      disabled={videoFullscreen}
                    >
                      <LeMagArticleList articles={articles} onClick={onClickArticle} />
                    </FocusableSection>
                  </>
                )}
                <Separator theme={theme} />
                <SectionHeader theme={theme}>{t("game.info")}</SectionHeader>

                <FreeText
                  dangerouslySetInnerHTML={{
                    __html: freeTextHTMLNoP,
                  }}
                />
              </LeMagContainer>
              {!isOnTv && (
                <Sharing
                  leaveFor={{
                    up: articles.length > 0 ? leMagSectionIdList : btnsSectionId,
                  }}
                />
              )}
            </Content>

            {recommandations && recommandations.length > 0 && (
              <RecoContainer theme={theme}>
                {recommandations.map((list, i) => {
                  return (
                    <div key={`${i}`}>
                      <RecoTitleContainer>
                        <RecoTitle theme={theme}>{list.title}</RecoTitle>
                      </RecoTitleContainer>
                      <HorizontalTileList
                        showTitle={false}
                        list={list}
                        onFocus={scrollBottom}
                        leaveFor={{
                          up: isOnTv
                            ? articles.length > 0
                              ? leMagSectionIdList
                              : dummySectionId
                            : shareButtonId,
                        }}
                        disabled={videoFullscreen}
                      />
                    </div>
                  );
                })}
              </RecoContainer>
            )}
          </ContainerContent>
        </>
      )}
    </Container>
  );
};
export default GamePage;
