import React, { useCallback, useEffect, useRef, useState } from "react";
import WebRTCGamePlayPage from "./WebRTCGamePlayPage";
import { nativeBridge } from "../../utils/NativeBridge";
import { useHistory, useParams } from "react-router";
import { logE } from "@blacknut/logging/dist";
import { LOGGING_TAG } from "../../utils/Utils";
import { useSelector } from "react-redux";
import { GameStreamProtocol, State } from "@blacknut/react-client-core/lib";
import { useSubscriptionRedirect } from "../../components/SubscriptionRedirect/SubscriptionRedirect";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import MessageModal from "../../components/Modals/MessageModal";
import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import { useTranslation } from "react-i18next";
import { useBatch } from "../../utils/BatchContext";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";

const GamePlayPage = () => {
  const [player, setPlayer] = useState<string>();
  const params = useParams<{ gameId: string }>();
  const history = useHistory();
  const { profile } = useSelector((state: State) => state.profilesState);
  const { config } = useSelector((state: State) => state.globalState);
  const { redirectToSubscription } = useSubscriptionRedirect();
  const { t } = useTranslation();
  const { active: spatialNavigationActive } = useSpatialNavigation();

  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const closeErrorModal = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
    //Le's defer redirect, otherwise, redirect will go the / but modal close will go back
    setTimeout(history.goBack, 500);
  }, [history]);

  const _redirectToSubscription = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;

    //Le's defer redirect, otherwise, redirect will go the / but modal close will go back
    //Stream will be relaunched
    setTimeout(redirectToSubscription, 500);
  }, [redirectToSubscription]);
  const { trackEvent, pauseMobileLanding, resumeMobileLanding } = useBatch();

  useEffect(() => {
    pauseMobileLanding();
    return () => {
      resumeMobileLanding();
    };
  }, [pauseMobileLanding, resumeMobileLanding]);

  useEffect(() => {
    trackEvent("started_game", {
      id: params.gameId,
    });
  }, [params.gameId, trackEvent]);

  useEffect(() => {
    if (nativeBridge.module?.getPlayer && nativeBridge.module?.play) {
      nativeBridge.module
        ?.getPlayer()
        .then((player) => {
          if (player) {
            setPlayer(player);
            if (player !== "webrtc") {
              nativeBridge.module?.play!({ game: params.gameId })
                .then(() => {
                  history.goBack();
                })
                .catch((e) => {
                  logE(LOGGING_TAG, "Caught error on play : %o", e);

                  if (e.code === "not_subscribed") {
                    modalSubscription.current = modalPush((props) => (
                      <MessageModal
                        {...props}
                        message={t("dialogs.error.notSubscribed.message")}
                        title={t("dialogs.error.notSubscribed.title")}
                        onClose={closeErrorModal}
                        buttons={
                          profile?.isMaster && [
                            spatialNavigationActive ? (
                              <SecondaryButton
                                key="ok"
                                onClick={_redirectToSubscription}
                              >
                                {t("buttons.ok")}
                              </SecondaryButton>
                            ) : (
                              <PrimaryButton key="ok" onClick={_redirectToSubscription}>
                                {t("buttons.ok")}
                              </PrimaryButton>
                            ),
                            <SecondaryButton key="cancel" onClick={closeErrorModal}>
                              {t("buttons.cancel")}
                            </SecondaryButton>,
                          ]
                        }
                      />
                    ));
                  } else {
                    modalSubscription.current = modalPush((props) => (
                      <MessageModal
                        {...props}
                        message={t("errors.generic")}
                        title={t("dialogs.error.title")}
                        onClose={closeErrorModal}
                        buttons={[
                          <PrimaryButton key="ok" onClick={closeErrorModal}>
                            {t("buttons.ok")}
                          </PrimaryButton>,
                        ]}
                      />
                    ));
                  }
                });
            }
          }
        })
        .catch((e) => {
          logE(LOGGING_TAG, "Caught error getting player :%o", e);
          setPlayer("webrtc");
        });
    } else {
      setPlayer("webrtc");
    }
  }, [
    _redirectToSubscription,
    closeErrorModal,
    config?.userConf?.protocol,
    history,
    modalPush,
    params.gameId,
    profile?.isMaster,
    t,
  ]);

  if (!player || player !== "webrtc") return null;
  return <WebRTCGamePlayPage />;
};

export default GamePlayPage;
