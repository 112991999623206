import { LeMagArticle, LeMagFeaturedArticle } from "@blacknut/javascript-sdk/dist";
import { Game, useLeMagFeaturedArticles } from "@blacknut/react-client-core/lib";
import {
  bachEnableSections,
  disableAllSectionsExcept,
  FocusableSection,
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { QRCodeSVG } from "qrcode.react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { ReactComponent as ElectronBackIcon } from "../../assets/dist/ic_arrow_back_active.svg";
import { CustomBackSubscription, useCustomBack } from "../../hooks/useBackKey";
import { useTheme } from "../../theme/ThemeProvider";
import useAnonymousCheck from "../../utils/useAnonymousCheck";
import { PrimaryButton } from "../Button/V2Button";
import LeMagArticleList from "../LeMagArticleList/LeMagArticleList";
import {
  CloseButtonStyle,
  Container,
  ContainerButtons,
  ContainerDescription,
  ContainerLeft,
  ContainerLeMagArticleList,
  ContainerRight,
  ContainerTop,
  ContentQRCode,
  Description,
  GradientBox,
  Header,
  Separator,
  StyledReactModal,
  SubTitle,
  TextButtonPlay,
  Title,
  TitleLeMag,
} from "./LeMagArticleModal.styles";
import * as H from "history";

export interface LeMagArticleModalProps extends ReactModal.Props {
  articles: (LeMagArticle | LeMagFeaturedArticle)[];
  article: LeMagArticle | LeMagFeaturedArticle;
  game?: Game;
  testID?: string;
  onClose?: () => void;
  buttons?: React.ReactNode;
  onAfterOpen?: () => void;
  closeButtonText?: string;
  // Auto add OK button is close prop is set bu no buttons
  addOKButton?: boolean;
  history: H.History;
}

const articleIsArticle = (
  art: LeMagArticle | LeMagFeaturedArticle,
): art is LeMagArticle => {
  return "link" in art;
};

const NOP = () => {
  //nope obviously
};
const LeMagArticleModal = ({
  isOpen,
  articles,
  article: initialArticle,
  game,
  buttons,
  onClose,
  onAfterOpen,
  className,
  history,
}: React.PropsWithChildren<LeMagArticleModalProps>) => {
  const { getArticleDetails, articleDetails } = useLeMagFeaturedArticles({
    isScreenForeground: true,
    limit: 1,
  });

  const { checkForAnonymous } = useAnonymousCheck();

  const [article, setArticle] = React.useState(initialArticle);
  const onClickArticle = React.useCallback((art: LeMagArticle) => {
    setArticle(art);
  }, []);

  React.useEffect(() => {
    if (articleIsArticle(article)) {
      const link = article.link;
      const params = link.split("https://www.blacknutlemag.com")[1].split("/");
      const slug = params[2];
      getArticleDetails(slug);
    } else {
      getArticleDetails(article.short_slug);
    }
  }, [article, getArticleDetails]);

  let descHTMLNoP = "";
  if (articleDetails) {
    if (articleDetails.content) {
      if (typeof articleDetails.content === "object") {
        descHTMLNoP = (articleDetails.content as string[])
          .join("\n")
          .replace("<p>", "")
          .replace("</p>", "");
      } else if (typeof articleDetails.content === "string") {
        descHTMLNoP = articleDetails.content.replace("<p>", "").replace("</p>", "");
      }
    } else if (articleDetails.intro) {
      descHTMLNoP = articleDetails.intro;
    }
  }

  const onPlayClicked = React.useCallback(
    (gameId: string) => {
      setTimeout(() => {
        if (checkForAnonymous()) {
          onClose && onClose();
          history.push(`/game/${gameId}/play`);
        }
      }, 300);
    },
    [checkForAnonymous, history, onClose],
  );
  const { resume } = useSpatialNavigation();

  const { theme } = useTheme();
  const { t } = useTranslation();
  const disabledSections = React.useRef([] as string[]);
  const { push: backPush } = useCustomBack();
  const backSubscription = React.useRef<CustomBackSubscription>();
  const _onAfterOpen = React.useCallback(() => {
    resume();

    //Disable all focusable sections except the current one
    disabledSections.current = disableAllSectionsExcept("/modal");
    focusSectionWithPath("/modal");

    if (onClose) {
      backSubscription.current = backPush(onClose);
    }

    if (onAfterOpen) {
      onAfterOpen();
    }
  }, [backPush, onAfterOpen, onClose, resume]);

  const _onAfterClose = React.useCallback(() => {
    bachEnableSections(disabledSections.current);
    backSubscription.current?.remove();
    //Wait till app.router disable spatial nav to reenable it
    setTimeout(() => {
      resume();
    }, 200);
  }, [resume]);

  const similarArticles: LeMagArticle[] = [];
  if (articles && articles.length > 0 && articleIsArticle(article)) {
    for (const art of articles) {
      if (
        articleIsArticle(art) &&
        art.image.id === article.image.id &&
        art !== article
      ) {
        similarArticles.push(art);
      }
    }
  }

  return (
    <StyledReactModal
      testId={game?.id}
      isOpen={isOpen}
      onAfterOpen={_onAfterOpen}
      className={className}
      onRequestClose={onClose}
      onAfterClose={_onAfterClose}
      style={{
        overlay: {
          position: "fixed",
          zIndex: 1002,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.modalStyle.overlayColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          width: "100%",
          height: "100%",
          background: "RGBA(0,0,0,1)",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "0px",
          outline: "none",
          padding: `${theme.modalStyle.padding}rem`,
          border: "none",
          color: "white",
          boxShadow: "0 0 24px rgba(0,0,0,.3)",
        },
      }}
    >
      <FocusableSection
        focusKey="modal"
        defaultElement={{ down: ".leMagPlay" }}
        config={{ restrict: "self-only" }}
      >
        <Header theme={theme}>
          {(buttons || onClose) && (
            <CloseButtonStyle onClick={onClose || NOP} theme={theme}>
              <ElectronBackIcon />
            </CloseButtonStyle>
          )}
          {game ? t("game.backFromModal", { game: game?.name }) : t("buttons.back")}
        </Header>
        <Container>
          <ContainerTop>
            <ContainerLeft>
              <Title>{articleDetails?.title}</Title>
              <SubTitle>{articleDetails?.subtitle}</SubTitle>
              <ContainerDescription>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: descHTMLNoP,
                  }}
                />
                <GradientBox />
              </ContainerDescription>
              {articleDetails?.game_id?.length === 1 && (
                <ContainerButtons>
                  <PrimaryButton
                    onClick={() => onPlayClicked(articleDetails?.game_id[0])}
                    className="leMagPlay"
                  >
                    <TextButtonPlay>{t("buttons.play")}</TextButtonPlay>
                  </PrimaryButton>
                </ContainerButtons>
              )}
            </ContainerLeft>
            <ContainerRight>
              <ContentQRCode>
                <QRCodeSVG
                  value={articleIsArticle(article) ? article.link : article.slug}
                />
              </ContentQRCode>
              <div>{t("game.qrCodeArticleLeMag")}</div>
            </ContainerRight>
          </ContainerTop>
          <ContainerLeMagArticleList>
            {similarArticles.length > 0 && (
              <>
                <Separator />
                <TitleLeMag theme={theme}>{t("game.similarArticles")}</TitleLeMag>
                <LeMagArticleList
                  articles={similarArticles}
                  ItemProps={{ style: { color: "#fff" } }}
                  onClick={onClickArticle}
                />
              </>
            )}
          </ContainerLeMagArticleList>
        </Container>
      </FocusableSection>
    </StyledReactModal>
  );
};

export default LeMagArticleModal;
