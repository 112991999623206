import { UserProfile } from "@blacknut/react-client-core/lib";
import * as React from "react";
import Truncate from "react-truncate";
import styled, { css } from "styled-components";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import Ripple, { RippleType } from "../Ripple";
import AvatarImage from "../AvatarSelectionModal/AvatarImage";
import { useTranslation } from "react-i18next";
import ProfileAddCardButton from "./ProfileAddCardListItem";
import { Hover } from "./ProfileStyles";
export interface ProfileProps {
  profile: UserProfile;
  onClick: () => void;
  isActive?: boolean;
  containerClassName?: string;
  addTile?: boolean;
}

export const Container = styled.div<{ theme: Theme }>`
  padding: ${dimens.margins.DarkRed}rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 13rem;

  svg,
  img {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    padding: ${dimens.margins.Green}rem;

    svg,
    img {
      width: 9rem;
      height: 9rem;
    }
  }
`;

const ActiveState = css`
  background-color: ${(props) => props.theme.profileCardStyle.activeBackgroundColor};
  color: ${(props) => props.theme.profileCardStyle.activeTextColor};
  transform: ${(props) =>
    props.theme.profileCardStyle.scaleFactor
      ? `scale(${props.theme.profileCardStyle.scaleFactor})`
      : undefined};
  box-shadow: 0 0 1.2rem ${(props) => props.theme.profileCardStyle.activeBackgroundColor};
`;

export const Nickname = styled.div`
  margin-top: ${dimens.margins.DarkRed}rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  * {
    cursor: pointer;
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    font-size: 1.8rem;
  }
`;
export const ContainerAdd = styled(Container)<{ theme: Theme }>`
  &:focus {
    svg {
      color: ${(props) => props.theme.profileCardStyle.activeTextColor};
    }
  }
  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      svg {
        color: ${(props) => props.theme.profileCardStyle.activeTextColor};
      }
    }
  }
`;

const ProfileCardItem = ({
  profile,
  onClick,
  isActive = false,
  containerClassName,
  addTile = false,
}: ProfileProps) => {
  const { theme } = useTheme();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const rippleRef = React.useRef<RippleType>(null);
  const { t } = useTranslation();
  const _onClick = React.useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      rippleRef.current?.ripple(e, onClick);
    },
    [onClick],
  );
  return (
    <>
      {!addTile && (
        <Hover
          theme={theme}
          onPressEnter={_onClick}
          spatialNavigationActive={spatialNavigationActive}
          $isActive={isActive}
        >
          <Ripple
            ref={rippleRef}
            rippleStyle={{
              radius: theme.profileCardStyle.radius,
              rippleColor: "#dcdcdc",
            }}
          >
            <Container
              theme={theme}
              onClick={_onClick}
              className={containerClassName}
              data-testid={`${profile.id}`}
            >
              {profile.avatarId && <AvatarImage avatarId={profile.avatarId} />}
              {!profile.avatarId && <AvatarDefault />}

              <Nickname>
                <Truncate width={80} lines={1}>
                  {profile.nickname}
                </Truncate>
              </Nickname>
            </Container>
          </Ripple>
        </Hover>
      )}
      {addTile && <ProfileAddCardButton onClick={onClick} />}
    </>
  );
};

export default ProfileCardItem;
