import { logD, logE } from "@blacknut/logging/dist";
import {
  SpeedTestError,
  speedTestService,
  SPEEDTEST_STATUS,
  State,
} from "@blacknut/react-client-core/lib";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import electronService from "../../services/ElectronService";
import speedTestElectronService from "../../services/SpeedTestElectronService";
import speedTestWebService from "../../services/SpeedTestWebService";
import { LOGGING_TAG } from "../../utils/Utils";
import MessageModal from "../Modals/MessageModal";
import { ModalSubscription, useModal } from "../Modals/ModalContext";

const SpeedTestsHandler = () => {
  const { config, user } = useSelector((state: State) => state.globalState);
  const { t } = useTranslation();
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const speedtestsStartedOnce = useRef(false);
  const closeModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const onSpeedTestFailure = useCallback(
    (err?: SpeedTestError) => {
      let message: string;
      if (!err || err.code === "speedtest_bad_bandwidth") {
        message = t("dialogs.speedTest.failure.message");
      } else {
        message = err.title;
      }
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          title={t("dialogs.speedTest.failure.title")}
          onClose={closeModal}
          message={message}
        />
      ));
    },
    [closeModal, modalPush, t],
  );

  const onSpeedTestError = useCallback(
    (err?: any) => {
      let message: string | undefined = undefined;
      if (!err || err.code === "speedtest_bad_bandwidth") {
        message = t("dialogs.speedTest.error.message");
      } else if (err.title) {
        message = err.title;
      }
      if (message) {
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            title={t("dialogs.speedTest.error.title")}
            onClose={closeModal}
            message={message!}
          />
        ));
      } else {
        logD(LOGGING_TAG, "Caught unkown error: %o", err);
      }
    },
    [closeModal, modalPush, t],
  );
  useEffect(() => {
    const speedTests =
      (process.env.REACT_APP_SPEED_TEST === undefined ||
        process.env.REACT_APP_SPEED_TEST === "true") &&
      !speedtestsStartedOnce.current;
    if (user && config && speedTests) {
      const subscription = speedTestService.onStatusUpdate().subscribe(
        (result: any) => {
          switch (result.status) {
            case SPEEDTEST_STATUS.SUCCESS:
              subscription.unsubscribe();
              break;
            case SPEEDTEST_STATUS.ERROR:
              onSpeedTestError(result.cause);
              subscription.unsubscribe();
              break;
            case SPEEDTEST_STATUS.FAILED:
              onSpeedTestFailure(result.cause);
              subscription.unsubscribe();
              break;
          }
        },
        (err: any) => {
          logE(LOGGING_TAG, "Unknown error", err);
        },
      );

      speedtestsStartedOnce.current = true;

      if (electronService.isAvailable()) {
        speedTestElectronService.start(config);
      } else {
        speedTestWebService.start(config);
      }

      return () => {
        subscription.unsubscribe();
      };
    }
    return () => {
      // nop
    };
  }, [config, onSpeedTestError, onSpeedTestFailure, speedtestsStartedOnce, user]);

  return null;
};
export default React.memo(SpeedTestsHandler);
