import * as React from "react";
import { TextInputStyle } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { Container, Input, SizeVariant } from "./ProfilePinTextField.style";
export interface ProfilePinTextFieldProps {
  value: string;
  onChangePin: (value: string | null, focusNext: boolean) => void;
  size: SizeVariant;
  errored?: boolean;
  onInputFocus?: () => void;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  testID?: string;
  secured?: boolean;
  inputStyle?: Partial<TextInputStyle>;
}

const ProfilePinText = ({
  errored = false,
  value,
  size,
  onChangePin,
  onInputFocus,
  inputRef,
  testID,
  secured = true,
  inputStyle,
}: ProfilePinTextFieldProps) => {
  const { theme } = useTheme();
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const s = e.currentTarget.value;
      if (s.length >= 1) {
        const last = s.charAt(s.length - 1);
        if (last.match(/^[0-9]$/)) {
          onChangePin(last, true);
        }
      } else {
        onChangePin(null, true);
      }
    },
    [onChangePin],
  );

  const onFocus = React.useCallback(() => {
    if (onInputFocus) {
      onInputFocus();
    }
  }, [onInputFocus]);
  const onPressEnter = React.useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const onKeyPress = React.useCallback(
    (e: React.KeyboardEvent<unknown>) => {
      if (e.key.match(/^[0-9]$/)) {
        onChangePin(e.key, document.activeElement === inputRef?.current);
      }
    },
    [inputRef, onChangePin],
  );

  return (
    <Container onPressEnter={onPressEnter} theme={theme} onKeyPress={onKeyPress}>
      <Input
        data-testid={testID}
        type="tel"
        placeholder=""
        errored={errored}
        maxLength={1}
        ref={inputRef}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        sizeVariant={size}
        theme={theme}
        secured={secured}
        inputStyle={inputStyle}
      />
      {value === "" && <span />}
    </Container>
  );
};

export default ProfilePinText;
