import {
  AppLayout,
  getAisReceiptUri,
  State,
  StorageKey,
  useLayout,
} from "@blacknut/react-client-core/lib";

import H from "history";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { match, useHistory } from "react-router-dom";
import NotificationInstallComponent from "src/components/NotificationsInstall";
import { ViewOrNot } from "src/components/NotificationsInstall/ViewOrNot";
import { detectDevice } from "src/services/detectDevice";
import electronService from "../../services/ElectronService";
import { useTheme } from "../../theme/ThemeProvider";
import { ScrollRestorer } from "../../utils/scroll/ScrollProvider";
import LogoutModal from "../Modals/LogoutModal";
import { ModalSubscription, useModal } from "../Modals/ModalContext";
import FocusableNavLink from "./FocusableNavLink";

import {
  deriveToken,
  getCurrentSubscription,
  getFamilyProfiles,
} from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import {
  loadFamilyInfoSuccess,
  setCurrentProfile,
  UserActionTypes,
} from "@blacknut/react-client-core/lib";
import {
  Card,
  Container,
  InstallButton,
  InstallCard,
  InstallContent,
  LogoutButton,
} from "./SideMenu.style";

import { concatMap, firstValueFrom } from "rxjs";
import { isAISBuild, isInStandaloneMode, LOGGING_TAG } from "../../utils/Utils";
const isProfileNavLinkActive = (match: match<never> | null, location: H.Location) => {
  const res =
    !!match ||
    !!location.pathname.match(/\/profiles\/manage/) ||
    !!location.pathname.match(/\/profile\/[a-z0-9]+/);
  return res;
};

const SideMenu = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language || "en-US";
  //TODO gérer les marques blanches

  const { user, accountUrl, config, organization, userToken, familyToken } = useSelector(
    (state: State) => state.globalState,
  );
  useEffect(() => {
    logD(LOGGING_TAG, "Features ? %o", config?.features);
  }, [config]);
  const { profile, familyProfiles } = useSelector((state: State) => state.profilesState);
  const { profiles } = familyProfiles;
  const history = useHistory();
  const layout = useLayout();
  const isOnTv = layout === AppLayout.TV;
  const dispatch = useDispatch();
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const closeLogoutModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);
  const { theme, themes } = useTheme();

  const [installNotif, setInstallNotif] = useState(
    localStorage.getItem(StorageKey.INSTALL_NOTIF),
  );
  const deviceInfos = detectDevice(navigator.userAgent);
  useEffect(() => {
    window.addEventListener("storage", () => {
      setInstallNotif(localStorage.getItem(StorageKey.INSTALL_NOTIF));
    });
    return () => {
      window.removeEventListener("storage", () => {
        setInstallNotif(ViewOrNot(deviceInfos));
      });
    };
  }, [deviceInfos]);
  const openLogoutModal = useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <LogoutModal {...props} onClose={closeLogoutModal} history={history} />
    ));
  }, [closeLogoutModal, modalPush, history]);

  const openWario = useCallback(
    async (e: React.UIEvent<unknown>) => {
      e.preventDefault();

      try {
        const derived = await firstValueFrom(deriveToken("profile"));
        const uri = `${accountUrl}?fat=${derived.familyToken.accessToken}&frt=${derived.familyToken.refreshToken}&uat=${derived.userToken?.accessToken}&urt=${derived.userToken?.refreshToken}&theme=${theme.name}`;
        window.location.replace(uri);
      } catch (e) {
        logE(LOGGING_TAG, "Caught error on token derivation", e);
        const uri = `${accountUrl}?fat=${familyToken?.accessToken}&frt=${familyToken?.refreshToken}&uat=${userToken?.accessToken}&urt=${userToken?.refreshToken}&theme=${theme.name}`;
        if (electronService.isAvailable()) {
          window.open(uri, "_blank");
        } else {
          window.location.replace(uri);
        }
      }
    },
    [
      accountUrl,
      familyToken?.accessToken,
      familyToken?.refreshToken,
      theme.name,
      userToken?.accessToken,
      userToken?.refreshToken,
    ],
  );

  const isMasterProfile = profile && profile.isMaster;
  const openNotificationInstall = () => {
    localStorage.setItem(StorageKey.INSTALL_NOTIF, "true");
    window.dispatchEvent(new Event("storage"));
  };
  const onFaqClick = useCallback(
    (e: React.UIEvent<unknown>) => {
      if (organization?.faqUrl) {
        window.open(organization?.faqUrl);
      } else {
        window.open(
          `https://www.blacknut.com/${locale.split("-")[0]}/support`,
          "_blank",
        );
      }
      e.preventDefault();
    },
    [locale, organization?.faqUrl],
  );

  useEffect(() => {
    const refreshSub = () => {
      if (document.visibilityState === "visible") {
        getCurrentSubscription()
          .pipe(
            concatMap((billingInfo) => {
              dispatch({
                type: UserActionTypes.FETCH_SUBSCRIPTION_SUCCESS,
                billingInfo,
              });
              return getFamilyProfiles();
            }),
          )
          .subscribe({
            next: (profiles) => {
              dispatch(loadFamilyInfoSuccess(profiles));
              const p = profiles.find((p) => p.id === profile?.id);
              if (p) {
                dispatch(setCurrentProfile(p, user, false));
              } else {
                // ??
              }
            },
            error: (error) => {
              logE(LOGGING_TAG, "Caught error refreshing in background", error);
            },
          });
      }
    };
    document.addEventListener("visibilitychange", refreshSub);
    return () => {
      document.removeEventListener("visibilitychange", refreshSub);
    };
  }, [dispatch, profile?.id, user]);

  const onSupportClick = useCallback(
    (e: React.UIEvent<unknown>) => {
      if (organization?.supportUrl) {
        window.open(organization?.supportUrl);
        e.preventDefault();
      }
    },
    [organization],
  );

  const onSubscriptionClick = useCallback(
    (e: React.UIEvent<unknown>) => {
      if (organization?.subscriptionUrl && !isAISBuild()) {
        window.open(organization?.subscriptionUrl);
        e.preventDefault();
      }
    },
    [organization],
  );

  const onClickReceipt = useCallback(
    (e: React.UIEvent<unknown>) => {
      if (!electronService.isAvailable() && user) {
        e.preventDefault();
        window.open(getAisReceiptUri(user), "_blank");
      }
    },
    [user],
  );

  return (
    <>
      <ScrollRestorer id="side" scrollKey="sideMenu" />
      <Container theme={theme} id="side" focusKey="side">
        {installNotif === "true" && !isInStandaloneMode() && !isOnTv && (
          <NotificationInstallComponent deviceInfos={deviceInfos} />
        )}
        <Card theme={theme}>
          {(profiles?.length ?? 0) > 1 && (
            <FocusableNavLink to="/account/profiles/switch">
              {theme.images.SwitchProfile && <theme.images.SwitchProfile />}
              {theme.images.SwitchProfileActive && <theme.images.SwitchProfileActive />}
              <span>{t("buttons.switchUser")}</span>
            </FocusableNavLink>
          )}
          {isMasterProfile ? (
            <FocusableNavLink
              to="/account/profiles/manage"
              className="link"
              isActive={isProfileNavLinkActive}
            >
              {theme.images.Account && <theme.images.Account />}
              {theme.images.AccountActive && <theme.images.AccountActive />}
              <span>{t("buttons.parentalControl")}</span>
            </FocusableNavLink>
          ) : (
            <FocusableNavLink
              to={`/account/profile/${profile?.id}`}
              className="link"
              isActive={isProfileNavLinkActive}
            >
              {theme.images.Account && <theme.images.Account />}
              {theme.images.AccountActive && <theme.images.AccountActive />}
              <span>{t("buttons.editProfile")}</span>
            </FocusableNavLink>
          )}

          {isMasterProfile && config?.features?.accountManagement !== false && (
            <FocusableNavLink
              to="/account/manageAccount"
              className="link"
              onClick={isOnTv ? undefined : openWario}
            >
              {theme.images.Settings && <theme.images.Settings />}
              {theme.images.SettingsActive && <theme.images.SettingsActive />}
              <span>{t("buttons.accountManagement")}</span>
            </FocusableNavLink>
          )}
        </Card>
        {themes.length > 1 && config?.features?.themes !== false && (
          <Card theme={theme}>
            <FocusableNavLink to="/account/personalization" className="link">
              {theme.images.LightTheme && <theme.images.LightTheme />}
              {theme.images.LightThemeActive && <theme.images.LightThemeActive />}
              <span>{t("personalization.title")}</span>
            </FocusableNavLink>
          </Card>
        )}
        <Card theme={theme}>
          {config?.features?.notifications !== false && (
            <FocusableNavLink to="/account/notifications" className="link">
              {theme.images.Notification && <theme.images.Notification />}
              {theme.images.NotificationActive && <theme.images.NotificationActive />}

              <span>{t("account.notifications.title")}</span>
            </FocusableNavLink>
          )}

          {config?.features?.newsletter !== false && isMasterProfile && (
            <FocusableNavLink to="/account/newsletter" className="link">
              {theme.images.Newsletter && <theme.images.Newsletter />}
              {theme.images.NewsletterActive && <theme.images.NewsletterActive />}
              <span>{t("account.newsletter")}</span>
            </FocusableNavLink>
          )}
        </Card>
        <Card theme={theme}>
          {isMasterProfile && config?.features?.subscription !== false && (
            <FocusableNavLink
              to="/account/subscription"
              className="link"
              onClick={onSubscriptionClick}
              onPressEnter={onSubscriptionClick}
            >
              {theme.images.Plans && <theme.images.Plans />}
              {theme.images.PlansActive && <theme.images.PlansActive />}

              <span>{t("buttons.subscription")}</span>
            </FocusableNavLink>
          )}
          {isMasterProfile && config?.features?.receipt !== false && (
            <FocusableNavLink
              to={"/account/receipt"}
              className="link"
              onClick={onClickReceipt}
              onPressEnter={onClickReceipt}
            >
              {theme.images.Receipt && <theme.images.Receipt />}
              {theme.images.ReceiptActive && <theme.images.ReceiptActive />}

              <span>{t("buttons.receipt")}</span>
            </FocusableNavLink>
          )}
        </Card>
        {(config?.features?.faq !== false || config?.features?.support !== false) && (
          <Card theme={theme}>
            {config?.features?.faq !== false && (
              <FocusableNavLink
                to="/account/faq"
                className="link"
                onClick={isOnTv ? undefined : onFaqClick}
                onPressEnter={isOnTv ? undefined : onFaqClick}
              >
                {theme.images.Faq && <theme.images.Faq />}
                {theme.images.FaqActive && <theme.images.FaqActive />}

                <span>{t("buttons.faq")}</span>
              </FocusableNavLink>
            )}
            {config?.features?.support !== false && (
              <FocusableNavLink
                to="/account/support"
                className="link"
                onClick={onSupportClick}
                onPressEnter={onSupportClick}
              >
                {theme.images.Support && <theme.images.Support />}
                {theme.images.SupportActive && <theme.images.SupportActive />}

                <span>{t("buttons.support")}</span>
              </FocusableNavLink>
            )}

            <FocusableNavLink className="link" to="/account/speedtest">
              {theme.images.Speedtest && <theme.images.Speedtest />}
              {theme.images.SpeedtestActive && <theme.images.SpeedtestActive />}
              <span>{t("buttons.speedTest")}</span>
            </FocusableNavLink>
          </Card>
        )}

        {user && user.powerUser && (
          <Card theme={theme}>
            <FocusableNavLink to="/account/settings/advanced" className="link">
              {theme.images.Settings && <theme.images.Settings />}
              {theme.images.SettingsActive && <theme.images.SettingsActive />}

              <span>Advanced settings</span>
            </FocusableNavLink>
          </Card>
        )}
        {ViewOrNot(deviceInfos) === "true" && (
          <Card theme={theme}>
            <FocusableNavLink
              to="#"
              className="link"
              onClick={openNotificationInstall}
              onPressEnter={openNotificationInstall}
            >
              <InstallCard theme={theme}>
                <InstallContent>
                  <span> {t("notifications.install.accountManagement.text")}</span>
                  <InstallButton
                    theme={theme}
                    onClick={openNotificationInstall}
                    testID="openInstallNotification"
                  >
                    {t("notifications.install.accountManagement.button")}
                  </InstallButton>
                </InstallContent>
              </InstallCard>
            </FocusableNavLink>
          </Card>
        )}
        <LogoutButton theme={theme} onClick={openLogoutModal} testID="logout">
          {t("buttons.logout")}
        </LogoutButton>
      </Container>
    </>
  );
};

export default SideMenu;
