import { LeMagArticle } from "@blacknut/react-client-core/lib";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import dimens from "../../theme/dimens";
import React from "react";

interface LeMagArticleListProps {
  articles: LeMagArticle[];
  ItemProps?: {
    onMobile?: boolean;
    style?: CSSProperties;
    className?: string;
  };
  onClick: (art: LeMagArticle) => void;
}
const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: stretch;
  justify-content: stretch;
  @media (orientation: landscape) and (max-width: ${dimens.breakpoints.desktop}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;
const StyledListItem = styled(Focusable)<{ theme: Theme }>`
  cursor: pointer;
  background-color: rgba(207, 207, 207, 0.3);
  border-radius: 6px;

  grid-column: auto;
  grid-row: 1;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    max-width: 420px;
    margin-right: ${dimens.margins.DarkRed}rem;
    &:hover,
    &:focus {
      transform: scale(var(--card-tile-scaleFactor));
      transition: transform 0.5s;
      background-color: ${(props) => props.theme.cardStyle.activeBackgroundColor};
      border-color: ${(props) => props.theme.rippleColor};
      box-shadow: 0 0 1.2rem
        ${(props) => props.theme.tileCardStyle.activeBackgroundColor};
    }
  }
`;
const StyledA = styled.div`
  text-decoration: none;
  grid-column: 1;
  grid-row: 1;
  align-items: center;
  border-radius: 6px;
  padding: 0.6rem;

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 45%, 55%;
    grid-gap: 10px;
  }
`;

const ContainerImage = styled.div`
  border-radius: 0.6rem;
  position: relative;
  display: block;
  margin: 0;
  display: flex;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    grid-column: 1;
  }
`;

const LayerImage = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
`;
const IconImg = styled.img`
  position: absolute;
  z-index: 6;
  width: 6rem;
  bottom: ${dimens.margins.DarkRed}rem;
  right: ${dimens.margins.DarkRed}rem; ;
`;

const StyledImg = styled.img<{ theme: Theme }>`
  border-radius: 0.6rem;
  display: block;
  width: 100%;
`;

const Tile = styled.span<{ theme: Theme }>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-weight: ${(props) => props.theme.textStyle2.fontWeight};
  font-size: ${(props) => props.theme.textStyle2.size}rem;
  font-family: ${(props) => props.theme.textStyle2.fontFamily};
  line-height: 1.4;
  display: block;
  margin: 0.6rem 0;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    grid-column: 2;
    color: #fff;
    line-height: 1.5;
  }
`;
const LeMagArticleList = ({ articles, ItemProps, onClick }: LeMagArticleListProps) => {
  const { theme } = useTheme();
  if (ItemProps?.onMobile) {
    articles = articles.slice(0, 2);
  } else {
    articles = articles.slice(0, 4);
  }
  return (
    <List>
      {articles.map((art) => {
        const _onClickArticle = () => {
          onClick(art);
        };
        return (
          <StyledListItem
            component="li"
            onPressEnter={_onClickArticle}
            theme={theme}
            key={art.title}
          >
            <StyledA onClick={_onClickArticle}>
              {art.image && (
                <ContainerImage>
                  <LayerImage />
                  <IconImg
                    src={`https://images.blacknut.com/website/logo/lemag-white.svg`}
                    alt={art.title}
                    className="absolute w-auto h-3 sm:h-4 bottom-2 left-2"
                  />
                  <StyledImg theme={theme} src={art.image.url} />
                </ContainerImage>
              )}
              <Tile theme={theme} className="texte" style={ItemProps?.style}>
                {art.title}
              </Tile>
            </StyledA>
          </StyledListItem>
        );
      })}
    </List>
  );
};
export default LeMagArticleList;
