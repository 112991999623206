import { logD, logE } from "@blacknut/logging/dist";
import {
  Config,
  SpeedTest,
  speedTestService,
  SPEEDTEST_STATUS,
} from "@blacknut/react-client-core/lib";
import { Subject, Subscription } from "rxjs";
import { LOGGING_TAG } from "../utils/Utils";
import electronService from "./ElectronService";
import { LocalStorageService } from "./LocalStorageService";

class SpeedTestElectronService {
  private subscriptions: Subscription[] = [];

  constructor() {
    speedTestService.localStorageService = new LocalStorageService();
  }

  public start(config: Config) {
    if (speedTestService.status !== SPEEDTEST_STATUS.RUNNING) {
      const subject: Subject<SpeedTest[]> = new Subject();
      this.subscribeElectronEvents(subject);
      electronService.sendNetworkTestConfig(config);
      speedTestService.speedTestWillStart(subject.asObservable());
    }
  }

  run(config: Config) {
    const subject: Subject<SpeedTest[]> = new Subject();
    this.subscribeElectronEvents(subject);
    electronService.sendNetworkTestConfig(config);
    speedTestService.speedTestWillStart(subject.asObservable());
  }

  protected subscribeElectronEvents(subject: Subject<SpeedTest[]>) {
    logD(LOGGING_TAG, "subscribeElectronEvents");
    this.subscriptions.push(
      electronService.onElectronSubject().subscribe(
        ({ event, data }) => {
          switch (event) {
            case "onNetworkResult":
              subject.next(data as SpeedTest[]);
              subject.complete();
              this.onEnd();
              break;
          }
        },
        (error) => {
          logE(LOGGING_TAG, "ERROR %s", error);
          this.onEnd();
        },
      ),
    );
  }

  private onEnd() {
    this.subscriptions.map((s) => s.unsubscribe());
    this.subscriptions = [];
  }
}

export default new SpeedTestElectronService();
