import { logE } from "@blacknut/logging/dist";
import { getVersion } from "@blacknut/playerwebrtc-sdk/dist";
import { PlayerLogLevel, usePowerUserSettings } from "@blacknut/react-client-core/lib";
import {
  Focusable,
  FocusableSection,
  useForceFocusOnMount,
} from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import OptionModal, { Option } from "../../components/Modals/OptionModal";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useReactRouter } from "../../utils/Hooks";
import { nativeBridge } from "../../utils/NativeBridge";
import ListItem from "./ListItem";
import Stats4Nerds from "./Stats4Nerds";
import WindowedMode from "./WindowedMode";
import Mute from "./Mute";

import { LOGGING_TAG } from "../../utils/Utils";
import PerformanceMonitoring from "./PerformanceMonitoring";

const InfoContainer = styled(Focusable)`
  background: ${(props: { theme: Theme }) => props.theme.listStyle.backgroundColor};
  border-radius: ${(props: { theme: Theme }) => props.theme.listStyle.radius}rem;
  margin: ${dimens.margins.LightGreen}rem;
  padding: ${dimens.margins.Green}rem;
  list-style: none;
  color: ${(props: { theme: Theme }) => props.theme.listStyle.textColor};
  text-align: center;
`;

const StyledList = styled.ul`
  background: ${(props: { theme: Theme }) => props.theme.listStyle.backgroundColor};
  border-radius: ${(props: { theme: Theme }) => props.theme.listStyle.radius}rem;
  margin: ${dimens.margins.LightGreen}rem;
  padding: 0;
  list-style: none;
`;

declare type OptWithValue = Option & { value: string };

const BoolOpts: Option[] = [
  { label: "ON", key: "on" },
  { label: "OFF", key: "off" },
];
const PlatformOpts: OptWithValue[] = [
  {
    label: "Dave",
    value: "https://dev-api.blacknut.net/api",
    key: "dev",
  },
  {
    label: "Staging",
    value: "https://staging-api.blacknut.net/api",

    key: "staging",
  },
  {
    label: "Integration",
    value: "https://integration-api.blacknut.net/api",
    key: "integ",
  },
  {
    label: "Prod",
    value: "https://api.blacknut.com/api",
    key: "prod",
  },
];
const EnvAppOpts: OptWithValue[] = [
  {
    label: "Dave-app",
    value: "https://dev-app.blacknut.net",
    key: "dev",
  },
  {
    label: "Staging-app",
    value: "https://staging-app.blacknut.net",
    key: "staging",
  },
  {
    label: "Integration-app",
    value: "https://integration-app.blacknut.net",
    key: "integ",
  },
  {
    label: "Prod-app",
    value: "https://app.blacknut.com",
    key: "prod",
  },
];

const closeModal = (ref: React.MutableRefObject<ModalSubscription | undefined>) => {
  if (ref.current) {
    ref.current.remove();
    ref.current = undefined;
  }
};

const StartUrl = () => {
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const _closeModal = React.useCallback(() => {
    closeModal(modalSubscription);
  }, []);

  const _onSelectedStartUrl = React.useCallback(
    (opt: OptWithValue) => {
      _closeModal();

      if (window.location.origin !== opt.value) {
        nativeBridge.module?.setStartUrl(opt.value);
      }
    },
    [_closeModal],
  );

  const selectedEnv = EnvAppOpts.find((opt) => opt.value === window.location.origin);

  const openStartUrlModal = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Select environment"
        onSelectOption={_onSelectedStartUrl as (val: Option) => void}
        options={EnvAppOpts}
        selected={selectedEnv && selectedEnv.key}
        onClose={_closeModal}
      />
    ));
  }, [modalPush, _onSelectedStartUrl, selectedEnv, _closeModal]);
  return (
    <ListItem
      name="Start Url"
      value={selectedEnv?.value || window.location.origin || "NA"}
      onClick={nativeBridge.module?.setStartUrl ? openStartUrlModal : undefined}
    />
  );
};

const Protocol = () => {
  const [opts, setOpts] = React.useState<OptWithValue[]>();
  const [player, setPlayer] = React.useState<string>("webrtc");

  React.useEffect(() => {
    if (nativeBridge.module?.availablePlayers) {
      nativeBridge.module
        .availablePlayers()
        .then((vals) => {
          setOpts([
            {
              key: "webrtc",
              label: "WebRTC",
              value: "webrtc",
            },
            ...vals.map((v) => ({
              key: v.value,
              label: v.label,
              value: v.value,
            })),
          ]);
        })
        .catch((e) => {
          logE("Caught error getting available players: %o", e);
        });
    }
    if (nativeBridge.module?.getPlayer) {
      nativeBridge.module
        .getPlayer()
        .then((player) => {
          setPlayer(player);
        })
        .catch((e) => {
          logE("Caught error getting player: %o", e);
        });
    }
  }, []);

  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const _closeModal = React.useCallback(() => {
    closeModal(modalSubscription);
  }, []);

  const _onSelectedProtocol = React.useCallback(
    (opt: OptWithValue) => {
      _closeModal();
      nativeBridge.module?.setPlayer && nativeBridge.module?.setPlayer(opt.value);
      setPlayer(opt.value);
    },
    [_closeModal],
  );
  const openProtocolModal = React.useCallback(() => {
    if (opts) {
      modalSubscription.current = modalPush((props) => (
        <OptionModal
          {...props}
          title="Protocol"
          onSelectOption={_onSelectedProtocol as (val: Option) => void}
          options={opts}
          selected={player}
          onClose={_closeModal}
        />
      ));
    }
  }, [opts, modalPush, _onSelectedProtocol, player, _closeModal]);
  return <ListItem name="Player" value={player || "NA"} onClick={openProtocolModal} />;
};

const Environment = (props: {
  platform?: string;
  onSelectPlatform: (platform: string) => void;
}) => {
  const { platform, onSelectPlatform } = props;
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const _closeModal = React.useCallback(() => {
    closeModal(modalSubscription);
  }, []);

  const _onSelectPlatform = React.useCallback(
    (opt: OptWithValue) => {
      _closeModal();
      if (platform !== opt.value) {
        onSelectPlatform(opt.value);
        setTimeout(() => {
          location.reload();
        }, 1200);
      }
    },
    [_closeModal, onSelectPlatform, platform],
  );
  const openPlatformModal = React.useCallback(() => {
    const selectedEnv = PlatformOpts.find((opt) => opt.value === platform);
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Select environment"
        onSelectOption={_onSelectPlatform as (val: Option) => void}
        options={PlatformOpts}
        selected={selectedEnv && selectedEnv.key}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, modalPush, _onSelectPlatform, platform]);
  return (
    <ListItem name="Environment" value={platform || "NA"} onClick={openPlatformModal} />
  );
};

const Debug = (props: { debug: boolean; onSelectDebug: (b: boolean) => void }) => {
  const { debug, onSelectDebug } = props;
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const _closeModal = React.useCallback(() => {
    closeModal(modalSubscription);
  }, []);

  const _onSelectDebug = React.useCallback(
    (opt: Option) => {
      _closeModal();
      if (opt.key === "on" && !debug) {
        onSelectDebug(true);
      } else if (opt.key === "off" && debug) {
        onSelectDebug(false);
      }
    },
    [_closeModal, debug, onSelectDebug],
  );

  const openDebugModal = React.useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Select debug mode"
        onSelectOption={_onSelectDebug}
        options={BoolOpts}
        selected={debug ? "on" : "off"}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, modalPush, _onSelectDebug, debug]);
  return <ListItem name="Debug" value={debug ? "ON" : "OFF"} onClick={openDebugModal} />;
};

const PlayerLogLevelView = (props: {
  playerDebugLevel: PlayerLogLevel;
  onSelectPlayerDebug: (level: PlayerLogLevel) => void;
}) => {
  const { playerDebugLevel, onSelectPlayerDebug } = props;
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const _closeModal = React.useCallback(() => {
    closeModal(modalSubscription);
  }, []);

  const onSelectPlayerLogLevel = React.useCallback(
    (opt: OptWithValue) => {
      _closeModal();
      const current = playerDebugLevel;
      if (opt.value !== current) {
        onSelectPlayerDebug(opt.value as PlayerLogLevel);
      }
    },
    [_closeModal, onSelectPlayerDebug, playerDebugLevel],
  );

  const openPlayerLogLevelModal = React.useCallback(() => {
    const debugPlayerOptions: OptWithValue[] = [];
    Object.keys(PlayerLogLevel).map((val) => {
      const s = PlayerLogLevel[val] as string;
      debugPlayerOptions.push({
        label: s.toLocaleUpperCase(),
        value: s,
        key: val,
      });
    });

    const selectedLogLevel = debugPlayerOptions.find(
      (opt) => opt.value === playerDebugLevel,
    );

    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Select player log level"
        onSelectOption={onSelectPlayerLogLevel as (val: Option) => void}
        options={debugPlayerOptions}
        selected={selectedLogLevel && selectedLogLevel.key}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, modalPush, onSelectPlayerLogLevel, playerDebugLevel]);

  return (
    <ListItem
      name="Player log level"
      value={playerDebugLevel.toLocaleUpperCase()}
      onClick={openPlayerLogLevelModal}
    />
  );
};

const Locale = (props: {
  locale?: string;
  onSelectLocale: (locale?: string) => void;
}) => {
  const { locale, onSelectLocale } = props;
  const { i18n } = useTranslation();
  const modalSubscription = React.useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const _closeModal = React.useCallback(() => {
    closeModal(modalSubscription);
  }, []);

  const _onSelectLocale = React.useCallback(
    (opt: OptWithValue) => {
      const lang = opt.value;
      onSelectLocale(lang == "N/A" ? undefined : lang);
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    [onSelectLocale],
  );
  const openLocaleModal = React.useCallback(() => {
    const opts: OptWithValue[] = [
      {
        label: "System default",
        value: "N/A",
        key: "N/A",
      },
      {
        label: "fr-FR",
        value: "fr-FR",
        key: "fr",
      },
      {
        label: "en-US",
        value: "en-US",
        key: "en",
      },
      {
        label: "de-DE",
        value: "de-DE",
        key: "de",
      },
      {
        label: "th-TH",
        value: "th-TH",
        key: "th",
      },
      {
        label: "it-IT",
        value: "it-IT",
        key: "it",
      },
      {
        label: "de-AT",
        value: "de-AT",
        key: "at",
      },
      {
        label: "es-ES",
        value: "es-ES",
        key: "es",
      },
      {
        label: "pt-PT",
        value: "pt-PT",
        key: "pt",
      },
      {
        label: "ms-MY",
        value: "ms-MY",
        key: "ms-MY",
      },
      {
        label: "pl-PL",
        value: "pl-PL",
        key: "pl-PL",
      },
      {
        label: "zh-HANT",
        value: "zh-HANT",
        key: "zh-HANT",
      },
      {
        label: "zh-HANS",
        value: "zh-HANS",
        key: "zh-HANS",
      },
    ];

    modalSubscription.current = modalPush((props) => (
      <OptionModal
        {...props}
        title="Select Locale"
        onSelectOption={_onSelectLocale as (val: Option) => void}
        options={opts}
        selected={i18n.language}
        onClose={_closeModal}
      />
    ));
  }, [_closeModal, _onSelectLocale, i18n.language, modalPush]);

  return (
    <ListItem
      name="Locale"
      value={locale || "System default"}
      onClick={openLocaleModal}
    />
  );
};

const SettingsPage = () => {
  const { theme } = useTheme();
  const {
    playerDebugLevel,
    onSelectPlayerLogLevel,
    platform,
    edito,
    onSelectPlaform,
    protocol,
    onSelectProtocol,
    debug,
    onSelectDebug,
    locale,
    onSelectLocale,
    stats4Nerds,
    onSelectStats4Nerds,
  } = usePowerUserSettings(useReactRouter());

  const appVersion = process.env.REACT_APP_CLIENT_VERSION || "0.0.0";
  const appBuildId = process.env.REACT_APP_BUILD_NUMBER || "0";
  const version = `${appVersion}#${appBuildId}`;
  const [fullVersion, setFullVersion] = React.useState(version);
  const [nativePlayerVersion, setNativePlayerVersion] = React.useState<
    Record<string, string>
  >({});
  React.useEffect(() => {
    if (nativeBridge.module?.getAppVersion) {
      nativeBridge.module.getAppVersion().then((v) => {
        setFullVersion(`${v}/${version}`);
      });
    }
  }, [version]);

  const { setTitle: setHeaderTitle, setHeaderLeft } = useHeader();
  React.useEffect(() => {
    setHeaderTitle(undefined);
    setHeaderLeft(<HeaderBackButton />);
  }, [setHeaderLeft, setHeaderTitle]);
  useForceFocusOnMount("/account/content/advanced");

  React.useEffect(() => {
    const init = async () => {
      if (nativeBridge.module?.availablePlayers) {
        try {
          const players = await nativeBridge.module.availablePlayers();
          players.map(async (p) => {
            if (nativeBridge.module?.getPlayerVersion) {
              try {
                const version = await nativeBridge.module.getPlayerVersion(p.value);
                setNativePlayerVersion((val) => {
                  const res = { ...(val || {}) };
                  res[p.value] = version;
                  return res;
                });
              } catch (e) {
                logE(LOGGING_TAG, `Error getting version on ${p.label} :%o`, e);
                setNativePlayerVersion((val) => {
                  const res = { ...(val || {}) };
                  res[p.value] = "N/A";
                  return res;
                });
              }
            } else {
              setNativePlayerVersion((val) => {
                const res = { ...(val || {}) };
                res[p.value] = "N/A";
                return res;
              });
            }
          });
        } catch (e) {
          logE(LOGGING_TAG, "Error getting players: %o", e);
        }
      }
    };
    init();
  }, []);
  return (
    <FocusableSection focusKey="advanced">
      <StyledList theme={theme}>
        <Environment platform={platform} onSelectPlatform={onSelectPlaform} />
        <Debug debug={debug} onSelectDebug={onSelectDebug} />
        <PlayerLogLevelView
          playerDebugLevel={playerDebugLevel}
          onSelectPlayerDebug={onSelectPlayerLogLevel}
        />
        <Locale locale={locale} onSelectLocale={onSelectLocale} />
        <Stats4Nerds
          onSelectStats4Nerds={onSelectStats4Nerds}
          stats4Nerds={stats4Nerds}
        />
        <StartUrl />
        <Protocol />
        <WindowedMode />
        <Mute />
        <PerformanceMonitoring />
      </StyledList>
      <InfoContainer theme={theme}>
        <div>Version : {fullVersion}</div>
        <div>{`WebRTC Player Version : ${getVersion()}`}</div>
        <div>{`Window size : ${window.innerWidth}*${window.innerHeight} (inner) ${window.outerWidth}*${window.outerHeight} (outer)`}</div>
        {Object.entries(nativePlayerVersion).map((e) => {
          return <div key={e[0]}>{`Native Player Version (${e[0]}) : ${e[1]}`}</div>;
        })}
        <div>{`Edito URL : ${edito}`}</div>
      </InfoContainer>
    </FocusableSection>
  );
};

export default SettingsPage;
