import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import styled from "styled-components";
import dimens from "../../theme/dimens";

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: ${dimens.margins.DarkRed}rem;
    &:last-child {
      margin-right: 0;
    }
  }

  & > svg {
    cursor: pointer;
    margin-left: ${dimens.margins.DarkRed / 2}rem;
  }
`;

export const ContainerVisibility = styled(Focusable)`
  color: var(--visibility-input-icon-color);

  &:focus,
  &:hover {
    color: var(--accent-color);
  }
`;
