import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import styled from "styled-components";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { PrimaryButton, SecondaryButton } from "../Button/V2Button";

export const Container = styled(FocusableSection)<{ theme: Theme }>`
  width: 48rem;
  background-color: ${(props) => props.theme.backgroundColor};
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-right: ${(props) => `1px solid ${props.theme.separatorColor}`};
  height: calc(100vh - ${dimens.desktop.headerHeight}rem);
  display: none;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    display: block;
  }
`;

export const LogoutButton = styled(SecondaryButton)<{ theme: Theme }>`
  margin-top: ${dimens.margins.Orange}rem;
  background-color: ${({ theme }) => theme.toggleButton.tintColor};

  button {
    color: ${({ theme }) => theme.toggleButton.textColor};
    border: none;
  }
`;
export const Card = styled.div<{ theme: Theme }>`
  background-color: ${(props) => props.theme.accountCardStyle.inactiveBackgroundColor};
  margin-bottom: ${dimens.margins.Green}rem;
  border-radius: ${(props) => props.theme.accountCardStyle.radius}rem;
`;

export const InstallCard = styled.div<{ theme: Theme }>`
  position: relative;
  color: ${(props) => props.theme.accountCardStyle.inactiveBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.textStyle.color};
  cursor: pointer;

  span {
    cursor: pointer;
  }
`;

export const InstallContent = styled.div`
  margin: ${dimens.margins.Green}rem;
  text-align: center;
`;

export const InstallButton = styled(PrimaryButton)<{ theme: Theme }>`
  button {
    font-size: 1.5rem;
  }
  background: transparent;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: ${(props) => props.theme.primaryButton.borderRadius}rem;
  max-width: fit-content;
`;
