import {
  ConsoleAppender,
  logD,
  logE,
  LogLevel,
  Appender,
  logI,
} from "@blacknut/logging/dist";
import {
  configure,
  launchStream,
  WebRTCError,
  requestFullscreen,
  exitFullscreen,
  DefaultFullscreenHelper,
} from "@blacknut/playerwebrtc-sdk/dist";
import {
  setUserToken,
  State,
  apiService,
  ApiErrorCode,
  StorageKey,
  addNotification,
  NotificationType,
} from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { inputUI } from "@blacknut/spatialnav-sdk/dist/input";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import HeaderBackButton from "../../components/Header/HeaderBackButton";
import { useHeader } from "../../components/Header/HeaderProvider";
import { useMenu } from "../../components/Menu/MenuProvider";
import MessageModal from "../../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../../components/Modals/ModalContext";
import { useSubscriptionRedirect } from "../../components/SubscriptionRedirect/SubscriptionRedirect";
import { CustomBackSubscription, useCustomBack } from "../../hooks/useBackKey";
import { useTheme } from "../../theme/ThemeProvider";
import { nativeBridge } from "../../utils/NativeBridge";
import { LOGGING_TAG } from "../../utils/Utils";
import cefService from "../../services/CEFService";
import { ClientDeviceType, isTizen } from "@blacknut/javascript-sdk/dist";
import { DDAppender } from "@blacknut/logging-datadog/dist";

const TAG = "WebRTCGamePlay";

const makeAppenders = (debug: boolean) => {
  const appenders: Appender[] = [new DDAppender()];
  if (debug) {
    appenders.push(new ConsoleAppender());
  }
  return appenders;
};
const GamePlayPage = () => {
  const history = useHistory();
  const { hideMenu, showMenu } = useMenu();
  const {
    setHeaderStyle,
    hideHeader,
    showHeader,
    setTitle: setHeaderTitle,
    setHeaderLeft,
    setHeaderRight,
  } = useHeader();
  const { push: modalPush } = useModal();

  const windowed = localStorage.getItem(StorageKey.WINDOWED);
  const mute = localStorage.getItem(StorageKey.STREAM_MUTED);
  const modalSubscription = useRef<ModalSubscription>();
  const { t } = useTranslation();
  const mounted = useRef(false);
  const { theme } = useTheme();

  const dispatch = useDispatch();
  const { resume: resumeSpatialNavigation, active: spatialNavigationActive } =
    useSpatialNavigation();

  const { push: pushBack } = useCustomBack();
  const backSubscription = useRef<CustomBackSubscription>();

  // Avoid double back
  // When stream is stopped via in game exit or via sdk popups, onStop is called before unmount
  // When stream is stopped via browser back button, unmount is called before onStop
  const stopCalled = useRef(false);

  const { debug, locale, stats4Nerds } = useSelector(
    (state: State) => state.globalState,
  );
  const { profile } = useSelector((state: State) => state.profilesState);

  const tearDown = useCallback(() => {
    showHeader();
    showMenu();
    inputUI.gameMode = false;
    backSubscription.current?.remove();
    backSubscription.current = undefined;

    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
  }, [showHeader, showMenu]);

  const onBackCanceled = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;

    inputUI.gameMode = true;
  }, []);

  const onBackConfirmed = useCallback(() => {
    tearDown();
    history.goBack();
  }, [history, tearDown]);

  const longBackTimer = useRef<NodeJS.Timeout>();

  const longBackNotificationTime = useRef<number>();

  const showLeaveGameModal = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = modalPush((props) => (
      <MessageModal
        {...props}
        message={t("dialogs.exitGame.message")}
        title={t("dialogs.exitGame.title")}
        onClose={onBackCanceled}
        onAfterOpen={() => {
          inputUI.gameMode = false;
          resumeSpatialNavigation();
        }}
        onRequestClose={onBackCanceled}
        buttons={[
          <PrimaryButton key="ok" onClick={onBackConfirmed}>
            {t("buttons.ok")}
          </PrimaryButton>,
          <SecondaryButton key="cancel" onClick={onBackCanceled}>
            {t("buttons.cancel")}
          </SecondaryButton>,
        ]}
      />
    ));
  }, [modalPush, onBackCanceled, onBackConfirmed, resumeSpatialNavigation, t]);

  //Back handling
  const timeAtFirstBack = useRef<number>();
  const setupCustomBackLoading = useCallback(() => {
    backSubscription.current?.remove();
    backSubscription.current = pushBack(() => {
      showLeaveGameModal();
    });
  }, [pushBack, showLeaveGameModal]);

  const setupCustomBackPlaying = useCallback(() => {
    backSubscription.current?.remove();
    backSubscription.current = pushBack((e) => {
      if (isTizen()) {
        showLeaveGameModal();
        return;
      }
      if (!e.repeat) {
        timeAtFirstBack.current = new Date().getTime();
      }
      const time = new Date().getTime(); // prevent single press
      longBackTimer.current && clearTimeout(longBackTimer.current);
      longBackTimer.current = setTimeout(() => {
        if (time - timeAtFirstBack.current! < 500) return;
        showLeaveGameModal();
      }, 500);

      if (
        !e.repeat &&
        (!longBackNotificationTime.current ||
          new Date().getTime() - longBackNotificationTime.current > 5000)
      ) {
        longBackNotificationTime.current = new Date().getTime();
        addNotification({
          type: NotificationType.INFO,
          time: new Date().getTime(),
          message: "player.long_back",
        })(dispatch);
      }
    });
  }, [dispatch, pushBack, showLeaveGameModal]);

  // Configure header
  useEffect(() => {
    setHeaderTitle("");
    setHeaderRight(undefined);
    setHeaderLeft(<HeaderBackButton />);
    setHeaderStyle({
      ...theme.headerStyle,
      backgroundColor: "transparent",
      borderWidth: 0,
    });

    return () => {
      setHeaderStyle(undefined);
    };
  }, [
    setHeaderLeft,
    setHeaderRight,
    setHeaderStyle,
    setHeaderTitle,
    t,
    theme.headerStyle,
  ]);

  const closeErrorModal = useCallback(() => {
    tearDown();
    history.goBack();
  }, [history, tearDown]);

  const onPlay = useCallback(() => {
    inputUI.gameMode = true;
    setupCustomBackPlaying();
    hideHeader();
  }, [hideHeader, setupCustomBackPlaying]);

  const { redirectToSubscription } = useSubscriptionRedirect();

  const _redirectToSubscription = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
    tearDown();

    //Le's defer redirect, otherwise, redirect will go the / but modal close will go back
    //Stream will be relaunched
    setTimeout(redirectToSubscription, 500);
  }, [redirectToSubscription, tearDown]);

  const onError = useCallback(
    (e: WebRTCError) => {
      logE(TAG, "Caught error on player", e);

      //Close exit confirmation modal if any
      modalSubscription.current?.remove();

      inputUI.gameMode = false;

      if (e.code === ApiErrorCode.NOT_SUBSCRIBED) {
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            message={t("dialogs.error.notSubscribed.message")}
            title={t("dialogs.error.notSubscribed.title")}
            onClose={closeErrorModal}
            buttons={
              profile?.isMaster && [
                <PrimaryButton key="ok" onClick={_redirectToSubscription}>
                  {t("buttons.ok")}
                </PrimaryButton>,
                <SecondaryButton key="cancel" onClick={closeErrorModal}>
                  {t("buttons.cancel")}
                </SecondaryButton>,
              ]
            }
          />
        ));
      } else {
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            message={t("errors.generic")}
            title={t("dialogs.error.title")}
            onClose={closeErrorModal}
            buttons={[
              <PrimaryButton key="ok" onClick={closeErrorModal}>
                {t("buttons.ok")}
              </PrimaryButton>,
            ]}
          />
        ));
      }
    },
    [modalPush, t, closeErrorModal, profile?.isMaster, _redirectToSubscription],
  );

  const onCancelLaunch = useCallback(() => {
    tearDown();
  }, [tearDown]);

  const onStop = useCallback(() => {
    if (!stopCalled.current) {
      history.goBack();
    }
    tearDown();
  }, [history, tearDown]);

  const params = useParams<{ gameId: string }>();
  const appenders = useMemo(() => makeAppenders(debug), [debug]);

  useEffect(() => {
    const module = nativeBridge.module;
    return () => {
      const p =
        module?.handleInAppWebRTC() && module?.exitFullscreen
          ? module?.exitFullscreen?.()
          : exitFullscreen(document);

      if (p) {
        p.then(() => {
          logD(LOGGING_TAG, "exit fullscreen ok");
        }).catch((e) => {
          logE(LOGGING_TAG, "exit fullscreen ko: %o", e);
        });
      }
    };
  }, []);
  useEffect(() => {
    setupCustomBackLoading();
  }, [setupCustomBackLoading]);

  useEffect(() => {
    hideMenu();
  }, [hideMenu]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      configure({
        endpoint: apiService.endpoint,
        userAgent: {
          ...apiService.userAgent,
          flavor: apiService.userAgent.flavor || "app",
        },

        userToken: {
          ...apiService.userToken!,
        },
        logging: {
          appenders,
          level: LogLevel.LEVEL_DEBUG,
        },
        appVersion: process.env.REACT_APP_VERSION,
        onTokenRenewed: (token, type) => {
          if (type === "user") {
            setUserToken(token, true)(dispatch);
          }
        },

        locale,
      });
      logD(LOGGING_TAG, "Launching stream");
      const res = launchStream({
        gameId: params.gameId,
        contentNodeId: "player",
        onCancelLaunch,
        onError,
        onStop,
        onPlay,
        stats: stats4Nerds,
        confirmExit: true,
        fullscreenHelper: nativeBridge.module?.handleInAppWebRTC()
          ? {
              requestFullscreen: nativeBridge.module?.requestFullscreen?.bind(
                nativeBridge.module,
              ),
              addFullcreenChangeListener:
                nativeBridge.module?.addFullcreenChangeListener?.bind(
                  nativeBridge.module,
                ),
              removeFullscreenChangeListener:
                nativeBridge.module?.removeFullscreenChangeListener?.bind(
                  nativeBridge.module,
                ),
              exitFullscreen: nativeBridge.module?.exitFullscreen?.bind(
                nativeBridge.module,
              ),
              isFullscreen: nativeBridge.module?.isFullscreen?.bind(nativeBridge.module),
              teardown: () => {
                // ??
              },
            }
          : new DefaultFullscreenHelper(),
        embedded: cefService.isAvailable,
        preferedWindowMode: windowed ? "windowed" : undefined,
        autoplay:
          nativeBridge.module?.handleInAppWebRTC() ||
          apiService.userAgent.client === ClientDeviceType.TV,
        mute: !!mute,
      });

      return () => {
        stopCalled.current = true;
        res?.stop();
      };
    } else {
      return () => {
        //NOPE
      };
    }
  }, [
    history,
    onCancelLaunch,
    onError,
    onPlay,
    onStop,
    params.gameId,
    theme.headerStyle,
    appenders,
    debug,
    dispatch,
    locale,
    stats4Nerds,
    windowed,
    mute,
  ]);

  return (
    <>
      <div id="player" />
    </>
  );
};

export default GamePlayPage;
