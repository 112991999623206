import { logout, State } from "@blacknut/react-client-core/lib";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import * as H from "history";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearHistory } from "../../utils/Utils";
import { PrimaryButton, SecondaryButton } from "../Button/V2Button";
import MessageModal from "./MessageModal";
import { ModalProps } from "./Modal";

const LogoutModal = (props: ModalProps & { history: H.History }) => {
  const { onClose, history } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: State) => state.globalState);
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const defaultFocus = "logoutModal_ok";

  const onConfirmed = React.useCallback(() => {
    logout()(dispatch);
  }, [dispatch]);

  const _onRequestClose = React.useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  React.useEffect(() => {
    if (!user) {
      clearHistory(history);
      history.replace("/welcome");
    }
  }, [user, onClose, history]);

  return (
    <MessageModal
      {...props}
      testID={"logoutModal"}
      title={t("dialogs.logout.title")}
      message={t("dialogs.logout.message")}
      defaultFocus={`.${defaultFocus}`}
      buttons={[
        <SecondaryButton key="cancel" onClick={_onRequestClose} testID="cancel">
          {t("buttons.cancel")}
        </SecondaryButton>,
        spatialNavigationActive ? (
          <SecondaryButton
            key="ok"
            onClick={onConfirmed}
            testID="confirm"
            className={defaultFocus}
          >
            {t("buttons.logout")}
          </SecondaryButton>
        ) : (
          <PrimaryButton key="ok" onClick={onConfirmed} testID="confirm">
            {t("buttons.logout")}
          </PrimaryButton>
        ),
      ]}
    />
  );
};
export default LogoutModal;
