import { AppLayout, List as ListModel, State } from "@blacknut/react-client-core/lib";
import {
  Focusable,
  FocusableSection,
  FocusableSectionProps,
} from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ReactComponent as Arrow } from "../../../assets/dist/ic_arrow.svg";
import dimens, { FLAT_FEATURE_TILE_WEIGHT, getNumColumns } from "../../../theme/dimens";
import { OrientationType, useOrientation } from "../../../utils/OrientationContext";
import { tile2Id } from "../../../utils/Utils";
import { usePreview } from "../../Modals/PreviewContext";
import Tile from "../../Tile/Tile";
import ArrowButton from "../ArrowButton";
import styles from "./styles.module.scss";

interface TileListProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  showTitle?: boolean;
  list: ListModel;
  className?: string;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  scrollable?: boolean;
}

const arrange = (
  list: ListModel,
  layout: AppLayout,
  orientation: OrientationType,
  scrollable: boolean,
) => {
  if (scrollable) return list.tiles;
  const nbColsFeatured =
    FLAT_FEATURE_TILE_WEIGHT *
      (getNumColumns(layout, orientation) - FLAT_FEATURE_TILE_WEIGHT) +
    1;

  return list.tiles.slice(0, nbColsFeatured);
};

const FeaturedTileList = ({
  showTitle = true,
  list,
  className,
  leaveFor,
  disabled,
  onFocus,
  scrollable = false,
}: TileListProps) => {
  const { layout } = useSelector((state: State) => state.globalState);

  const { orientation } = useOrientation();
  const history = useHistory();
  const tiles = React.useMemo(
    () => arrange(list, layout, orientation, scrollable),
    [list, layout, orientation, scrollable],
  );
  const hasTitle = showTitle && list.title && list.title.trim().length > 0;
  const [isArrowButton, setIsArrowButton] = React.useState<boolean>(false);

  const handleOnFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (onFocus) onFocus(e);

    if (window.innerWidth >= dimens.breakpoints.desktop) setIsArrowButton(true);
  };

  const { active: previewActive, goToPage: goToPagePreview } = usePreview();
  const goToCatePage = () => {
    if (previewActive && list.moreLink) {
      goToPagePreview(list.moreLink);
    } else if (!!list.moreLink) {
      history.push(`/page/${list.moreLink}`);
    }
  };

  return (
    <FocusableSection
      focusKey={list.uuid}
      className={clsx(styles.container, scrollable && styles.scrollable, className)}
      leaveFor={leaveFor}
      disabled={disabled}
      defaultElement={{ down: "first", up: ".tile" }}
      config={{ restrict: "self-first" }}
      onFocus={handleOnFocus}
    >
      {(hasTitle || (!!list.moreLink && layout === AppLayout.PHONE)) && (
        <div className={clsx(styles.titleContainer, scrollable && styles.scrollable)}>
          {hasTitle && (
            <Focusable
              className={clsx(styles.title, !!list.moreLink && styles.clickable)}
              onPressEnter={goToCatePage}
              onClick={goToCatePage}
              disabled={
                !list.moreLink || window.innerWidth >= dimens.breakpoints.desktop
              }
            >
              {list.title} <Arrow />
            </Focusable>
          )}
        </div>
      )}

      <div
        className={styles.content}
        onBlur={() => setIsArrowButton(false)}
        onMouseEnter={() =>
          window.innerWidth >= dimens.breakpoints.desktop && setIsArrowButton(true)
        }
        onMouseLeave={() => setIsArrowButton(false)}
      >
        <ul className={clsx(styles.list, scrollable && styles.scrollable)}>
          {tiles.map((e, index) => (
            <li
              className={clsx(styles.tile, index === 0 && styles.featured)}
              key={`${list.uuid}-${tile2Id(e)}`}
            >
              <Tile item={e} list={list} featured={index === 0} />
            </li>
          ))}
        </ul>

        {list.moreLink && (
          <ArrowButton
            onPress={goToCatePage}
            className={clsx(styles.arrowButton, isArrowButton && styles.visible)}
          />
        )}
      </div>
    </FocusableSection>
  );
};

export default FeaturedTileList;
