import {
  UserProfile,
  isKidProfile,
  ProfileType,
  UpdateFamilyProfileData,
  State,
} from "@blacknut/react-client-core/lib";
import {
  Focusable,
  FocusableSection,
  focusElementWithSectionPath,
  focusSectionWithPath,
  getSectionIdFromPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AvatarImagesJSON } from "src/utils/avatar";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import { ReactComponent as EditIcon } from "../../assets/dist/ic_edit.svg";
import { ReactComponent as Trash } from "../../assets/dist/ic_delete.svg";
import AvatarSelectionModal from "../../components/AvatarSelectionModal/AvatarSelectionModal";
import { PrimaryButton } from "../../components/Button/V2Button";
import TextInput from "../../components/Inputs/TextInput/V2TextInput";
import AvatarImage from "../AvatarSelectionModal/AvatarImage";
import Checkbox from "../Inputs/Checkbox/Checkbox";
import { OnCloseEvent } from "../Modals/Modal";
import { ModalSubscription, useModal } from "../Modals/ModalContext";
import styles from "./styles.module.scss";
import ProfilePin from "../ProfilePin/ProfilePin";
import clsx from "clsx";

enum AccountType {
  child = "childAccount",
  adult = "adultAccount",
}

interface EditProfileProps {
  profile?: UserProfile;
  onSubmit: (data: UpdateFamilyProfileData) => void;
  onDelete?: () => void;
  profiles?: UserProfile[];
  onChange?: (e: UpdateFamilyProfileData) => void;
}

const EditProfileNew = ({
  onSubmit,
  onChange,
  onDelete,
  profile,
  profiles,
}: EditProfileProps) => {
  const { t } = useTranslation();
  const { profile: profileLogged } = useSelector((state: State) => state.profilesState);
  const [data, setData] = useState<UpdateFamilyProfileData>({
    nickname: profile ? profile.nickname : "",
    isPinLocked: profile ? profile.isPinLocked : false,
    pin: profile && profile.isPinLocked ? "1234" : "",
    avatarId: profile ? profile.avatarId : "1",
    userProfile: profile ? profile.userProfile : ProfileType.KIDS,
    id: profile?.id || "",
    isMaster: profile?.isMaster || false,
  });
  const [pinModified, setPinModified] = useState<boolean>(false);
  const [pinEditing, setPinEditing] = useState<boolean>(data.pin === "");
  const [nicknameError, setNicknameError] = useState<string | undefined>();

  const { userProfile, avatarId } = data;
  const kidProfiles = [ProfileType.KIDS, ProfileType.PRE_TEENS, ProfileType.TEENS];
  const isKid = isKidProfile(userProfile);
  const modalSubscription = useRef<ModalSubscription>();
  const userProfileBeforeGoingAdult = useRef<ProfileType>();
  const { push: modalPush } = useModal();
  const { enabled: spatialNavEnabled, active } = useSpatialNavigation();
  const closeAvatarModal = useCallback((e?: OnCloseEvent) => {
    if (modalSubscription.current) {
      modalSubscription.current.remove(e);
      modalSubscription.current = undefined;
    }
  }, []);

  const dispatchOnChange = useCallback(
    (d: UpdateFamilyProfileData) => {
      if (onChange) {
        onChange(d);
      }
    },
    [onChange],
  );

  const fix3533 = useRef(false);

  const _onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (fix3533.current) {
        fix3533.current = false;
        document.querySelectorAll("input").forEach((e) => e.blur());
        return;
      }
      const _data = { ...data };
      if (!pinModified) {
        delete _data.pin;
      }

      if (profiles) {
        // Check duplicate name
        const other = profiles.find((p) => p.nickname === _data.nickname);
        if (!other || (profile && other.id === profile.id)) {
          if (_data.userProfile === ProfileType.KIDS) {
            _data.isPinLocked = false;
          }

          onSubmit(_data);
          setNicknameError(undefined);
        } else {
          setNicknameError(t("errors.profiles.duplicateName"));
        }
      }
    },
    [data, onSubmit, pinModified, profile, profiles, t],
  );

  const onSelectAvatar = useCallback(
    (id: string) => {
      closeAvatarModal();
      setData((d) => {
        const newData = { ...d, avatarId: id };
        dispatchOnChange(newData);
        return newData;
      });
    },
    [closeAvatarModal, dispatchOnChange],
  );

  const onClickAvatar = useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <AvatarSelectionModal
        {...props}
        onClose={closeAvatarModal}
        closeButtonText={t("buttons.cancel")}
        onSelectAvatar={onSelectAvatar}
        testID="avatarModal"
        currentAvatar={avatarId || AvatarImagesJSON[0].id}
      />
    ));
  }, [avatarId, closeAvatarModal, modalPush, onSelectAvatar, t]);

  const onPseudoChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setData((d) => {
        const newData = { ...d, nickname: val };
        dispatchOnChange(newData);
        return newData;
      });
    },
    [dispatchOnChange],
  );

  const onChildCheckChange = useCallback(() => {
    if (!isKidProfile(data.userProfile)) {
      setData((d) => {
        const newData = {
          ...d,
          userProfile: userProfileBeforeGoingAdult.current || ProfileType.KIDS,
        };
        dispatchOnChange(newData);
        return newData;
      });
    }
  }, [data, dispatchOnChange]);

  const setUserProfile = useCallback(
    (userProfile: ProfileType) => {
      setData((d) => {
        const newData = { ...d, userProfile };
        dispatchOnChange(newData);
        return newData;
      });
    },
    [dispatchOnChange],
  );

  const onAdultCheckChange = useCallback(() => {
    if (data.userProfile !== ProfileType.GENERIC) {
      userProfileBeforeGoingAdult.current = data.userProfile;
      setData((d) => {
        const newData = { ...d, userProfile: ProfileType.GENERIC };
        dispatchOnChange(newData);
        return newData;
      });
    }
  }, [data, dispatchOnChange]);

  const onPinEntered = useCallback(
    (s: string | null) => {
      setPinModified(true);
      setData((d) => {
        const newData = { ...d, pin: s === null ? undefined : s };
        dispatchOnChange(newData);
        return newData;
      });
      if (s?.length === 4 && spatialNavEnabled) {
        focusElementWithSectionPath("/account/content/profile", ".submit");
      }
    },
    [dispatchOnChange, spatialNavEnabled],
  );

  const handlePinChanged = (s: string) => {
    setData((d) => {
      return { ...d, pin: s === null ? undefined : s };
    });
  };

  const onPinCheckChange = useCallback(() => {
    setData((d) => {
      const newData = { ...d, isPinLocked: !d.isPinLocked };
      dispatchOnChange(newData);
      return newData;
    });
  }, [dispatchOnChange]);

  useEffect(() => {
    if (active) {
      focusSectionWithPath("/account/content/profile");
    }
  }, [active]);

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      fix3533.current = true;
    }
  }, []);
  return (
    <form onSubmit={_onSubmit} data-testid="form">
      <FocusableSection className={styles.container} focusKey="profile">
        <Focusable
          onPressEnter={onClickAvatar}
          onClick={onClickAvatar}
          className={styles.avatarContainer}
          data-testid="avatar"
        >
          {avatarId && <AvatarImage avatarId={avatarId} isHoverable={true} />}
          {!avatarId && <AvatarDefault />}
          <div className={styles.editIconContainer}>
            <EditIcon />
          </div>
        </Focusable>

        {profileLogged?.isMaster && profile?.isMaster && (
          <span className={styles.mainAccount}>
            {t("profile.main", { name: profile.nickname })}
          </span>
        )}
        <TextInput
          label={t("profile.nickname")}
          placeholder={t("profile.nickname")}
          type="text"
          id="pseudo"
          onChange={onPseudoChange}
          error={nicknameError}
          value={data.nickname}
          testID="nickname"
          onKeyDown={onKeyDown}
        />

        {/* If current profile is a kid only allow to change its name & avatar */}
        {profileLogged?.userProfile !== ProfileType.KIDS && (
          <>
            {(profileLogged?.userProfile !==
              ProfileType.GENERIC /* Pre teens / teens case */ ||
              (profileLogged?.isMaster &&
                profileLogged.id !==
                  profile?.id)) /* Master is allowed to set child account except for himself*/ && (
              // Allow to set pin code & display pegi value as disabled
              <FocusableSection
                className={styles.focusableCardRow}
                focusKey={AccountType.child}
                defaultElement={{ down: "first" }}
                onClick={onChildCheckChange}
              >
                <div className={styles.card}>
                  <Checkbox
                    onCheckChange={onChildCheckChange}
                    checked={isKid}
                    label={t("profile.childAccount")}
                    id={AccountType.child}
                    disabled={profile && profile.isMaster}
                    radio={true}
                    testID="child"
                    focusDisabled={isKid}
                    focusVisible={false}
                  />

                  {isKid && (
                    <>
                      {profileLogged?.isMaster &&
                        !profileLogged?.isPinLocked &&
                        !profile?.isMaster && (
                          <p className={styles.labelSecure}>
                            {t("profile.labelSecure")}
                          </p>
                        )}

                      <div className={styles.separator} />
                      <div>
                        <span className={styles.pegiTitle}>
                          {t("profile.ageRestriction")}
                        </span>
                        <FocusableSection
                          className={styles.pegiContainer}
                          focusKey="pegi"
                          leaveFor={{
                            down: `@${getSectionIdFromPath(
                              "/account/content/profile/child_secure",
                            )}`,
                          }}
                        >
                          {kidProfiles.map((e) => {
                            const onChange = () => {
                              setUserProfile(e);
                            };
                            let label = "3 - 6";
                            switch (e) {
                              case ProfileType.KIDS:
                                label = "3 - 6";
                                break;
                              case ProfileType.PRE_TEENS:
                                label = "7 - 11";
                                break;
                              case ProfileType.TEENS:
                                label = "12 - 17";
                                break;
                            }
                            return (
                              <Checkbox
                                key={`pegi-${e}`}
                                id={`pegi-${e}`}
                                onCheckChange={onChange}
                                checked={userProfile === e}
                                radio={true}
                                label={label}
                                testID={`pegi-${e}`}
                                disabled={!profileLogged?.isMaster}
                              />
                            );
                          })}
                        </FocusableSection>
                      </div>
                    </>
                  )}

                  {userProfile !== ProfileType.KIDS &&
                    userProfile !== ProfileType.GENERIC && (
                      <FocusableSection
                        focusKey="child_secure"
                        enterTo="default-element"
                        defaultElement={{ down: `.${styles.secureCheckbox}` }}
                      >
                        <Checkbox
                          onCheckChange={onPinCheckChange}
                          checked={data.isPinLocked}
                          label={t("profile.secureAccess")}
                          id="secureAccess"
                          testID="secureAccess"
                          className={styles.secureCheckbox}
                        />
                        {data.isPinLocked && (
                          <ProfilePin
                            className={styles.pinContainer}
                            onPinEntered={onPinEntered}
                            errored={false}
                            value={data.pin}
                            size="medium"
                            pinEditing={pinEditing}
                            setPinEditing={setPinEditing}
                            handlePinChanged={handlePinChanged}
                          />
                        )}
                      </FocusableSection>
                    )}
                </div>
              </FocusableSection>
            )}

            {/* On Generic profile, check box for adult & pin code */}
            {profileLogged?.userProfile === ProfileType.GENERIC && (
              <FocusableSection
                className={styles.focusableCardRow}
                focusKey={AccountType.adult}
                defaultElement={{ down: "first" }}
                onClick={onAdultCheckChange}
              >
                <div className={styles.card}>
                  {!profile?.isMaster && (
                    <Checkbox
                      onCheckChange={onAdultCheckChange}
                      checked={!isKid}
                      label={t("profile.adultAccount")}
                      id={AccountType.adult}
                      disabled={profile && profile.isMaster}
                      radio={true}
                      testID="adult"
                      focusDisabled={!isKid}
                      focusVisible={false}
                      className={clsx(!profileLogged.isMaster && styles.hidden)}
                    />
                  )}

                  {!isKid && (
                    <>
                      {profileLogged?.isMaster &&
                        !profileLogged?.isPinLocked &&
                        !profile?.isMaster && (
                          <p className={styles.labelSecure}>
                            {t("profile.labelSecure")}
                          </p>
                        )}

                      {profile?.isMaster && (
                        <Checkbox
                          onCheckChange={onPinCheckChange}
                          checked={data.isPinLocked}
                          label={t("profile.secureAccess")}
                          id="secureAccess"
                          testID="secureAccess"
                        />
                      )}
                      {!profile?.isMaster && (
                        <Checkbox
                          className={clsx(
                            styles.secureCheckbox,
                            !profileLogged.isMaster && styles.noMargin,
                          )}
                          onCheckChange={onPinCheckChange}
                          checked={data.isPinLocked}
                          label={t("profile.secureAccess")}
                          id="secureAccess"
                          testID="secureAccess"
                        />
                      )}
                      {data.isPinLocked && (
                        <ProfilePin
                          className={styles.pinContainer}
                          onPinEntered={onPinEntered}
                          errored={false}
                          value={data.pin}
                          size="medium"
                          pinEditing={pinEditing}
                          setPinEditing={setPinEditing}
                          handlePinChanged={handlePinChanged}
                        />
                      )}
                    </>
                  )}
                </div>
              </FocusableSection>
            )}
          </>
        )}
        <div className={styles.buttonRow}>
          <PrimaryButton
            type="submit"
            disabled={
              !data.nickname ||
              data.nickname.length === 0 ||
              (data.isPinLocked && (!data.pin || !data.pin.match(/^[0-9]{4}$/))) ||
              (!isKid && !userProfile)
            }
            testID="submit"
            className="submit"
          >
            {profile ? t("buttons.update") : t("buttons.save")}
          </PrimaryButton>
        </div>

        {onDelete && profile && !profile.isMaster && profileLogged?.isMaster && (
          <div className={styles.buttonRow}>
            <Focusable
              className={styles.trashContainer}
              onClick={onDelete}
              onPressEnter={onDelete}
              data-testid="delete"
            >
              <Trash />
            </Focusable>
          </div>
        )}
      </FocusableSection>
    </form>
  );
};
export default EditProfileNew;
