import { setDebug, State } from "@blacknut/react-client-core/lib";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import electronService, {
  ElectronEvent,
  ElectronEventType,
} from "../services/ElectronService";
const useDebug = () => {
  const dispatch = useDispatch();
  const { debug } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    const sub = electronService.onElectronSubject().subscribe((e: ElectronEvent) => {
      const { event, data } = e;

      switch (event) {
        case ElectronEventType.devToolsChange:
          setDebug(data === "true")(dispatch);
          break;
      }
    });
    return () => sub.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    electronService.sendDebug(debug);
  }, [debug]);
};
export default useDebug;
