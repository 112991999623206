import { Game, setMuted, State, toggleMute } from "@blacknut/react-client-core/lib";
import {
  Focusable,
  FocusableSection,
  FocusableSectionProps,
  focusSectionWithPath,
  LeaveForNowhere,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import React, {
  UIEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { CSSProperties } from "styled-components";
import { ReactComponent as Minimize } from "../../assets/dist/ic_fullscreen_exit_filled.svg";
import { ReactComponent as MinimizeActive } from "../../assets/dist/ic_fullscreen_exit_filled_active.svg";
import { ReactComponent as Maximize } from "../../assets/dist/ic_fullscreen_filled.svg";
import { ReactComponent as MaximizeActive } from "../../assets/dist/ic_fullscreen_filled_active.svg";
import { ReactComponent as Play } from "../../assets/dist/ic_play.svg";
import { ReactComponent as VolumeOff } from "../../assets/dist/ic_sound_off.svg";
import { ReactComponent as VolumeOffActive } from "../../assets/dist/ic_sound_off_active.svg";
import { ReactComponent as VolumeOn } from "../../assets/dist/ic_sound_on.svg";
import { ReactComponent as VolumeOnActive } from "../../assets/dist/ic_sound_on_active.svg";
import { useCustomBack } from "../../hooks/useBackKey";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";
import { useOrientation } from "../../utils/OrientationContext";
import { is_iOS } from "@blacknut/javascript-sdk/dist";
import IconButton from "../Button/IconButton";
import { PrimaryButton } from "../Button/V2Button";
import PlayIcon from "../PlayIcon/PlayIcon";

const Video = styled.video``;

const FSButton = styled(IconButton)`
  svg:last-child {
    display: none;
  }
  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${(props) => props.theme.rippleColor};
  }
  &:focus {
    svg {
      & path:nth-child(1) {
        fill: #${(props) => props.theme.primaryButton.activeBackgroundColor};
        fill-opacity: 1;
      }
    }
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 4.2rem;
      height: 4.2rem;
    }
  }
  &:focus {
    svg {
      & path:nth-child(1) {
        fill: ${(props) => props.theme.primaryButton.activeBackgroundColor};
        fill-opacity: 1;
      }
    }
  }
  @media (hover: hover) {
    &:hover {
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
  }
`;

const MaximizeButton = styled(IconButton)`
  svg:last-child {
    display: none;
  }
  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${(props) => props.theme.rippleColor};
  }
  @media (hover: hover) {
    &:hover {
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
    svg {
      width: 3.2rem;
      height: 3.2rem;
      color: ${(props) => props.theme.rippleColor};
    }
  }
`;

const VolumeButtonTmp = styled(IconButton)`
  svg:last-child {
    display: none;
  }
  &:focus {
    svg {
      & path:nth-child(1) {
        fill: ${(props) => props.theme.primaryButton.activeBackgroundColor};
        fill-opacity: 1;
      }
    }
  }
  @media (hover: hover) {
    &:hover {
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
  }
`;

const VolumeButton = (props: {
  className?: string;
  theme: Theme;
  muted?: boolean;
  onClick?: () => void;
}) => {
  const { className } = props;
  const _muted = useSelector((state: State) => state.globalState.muted);
  const dispatch = useDispatch();
  const onVolumeClick = (e: React.MouseEvent<HTMLElement>) => {
    toggleMute()(dispatch);
    e.stopPropagation();
  };
  const muted = props.muted ?? _muted;
  return (
    <VolumeButtonTmp
      className={className}
      onClick={props.onClick || onVolumeClick}
      testID="volume"
      theme={props.theme}
    >
      {muted && (
        <>
          <VolumeOff />
          <VolumeOffActive />
        </>
      )}
      {!muted && (
        <>
          <VolumeOn />
          <VolumeOnActive />
        </>
      )}
    </VolumeButtonTmp>
  );
};

export { VolumeButton };

const StyledVolumeButton = styled(VolumeButton)`
  margin-left: auto;
  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${(props) => props.theme.rippleColor};
  }
`;

const FullscreenVolumeButton = styled(VolumeButton)`
  svg {
    width: 3.2rem;
    height: 3.2rem;
    color: ${(props) => props.theme.rippleColor};
  }
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 4.2rem;
      height: 4.2rem;
    }
  }
`;

const ControlsContainer = styled(FocusableSection)`
  position: absolute;
  bottom: ${dimens.margins.Green}rem;
  left: ${dimens.margins.Green}rem;
  right: ${dimens.margins.Green}rem;
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-right: ${dimens.margins.DarkRed}rem;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    bottom: ${dimens.margins.DarkRed}rem;
    right: ${dimens.margins.DarkRed}rem;
  }
`;
const FSControlsContainer = styled.div<{ showControls: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  z-index: 2;
  margin-top: 2rem;
  opacity: ${(props) => (props.showControls ? 1 : 0)};

  @media screen and (orientation: landscape) {
    margin-top: 0;
    position: fixed;
    bottom: ${dimens.margins.Green * 1.5}rem;
    left: 0;
    right: 0;
  }
`;

export const StyledPlayBtn = styled(Focusable)<{ spatialNavigationActive: boolean }>`
  //transition: transform 0.2s; /* Animation */
`;
export const StyledPlayIcon = styled(PlayIcon)`
  cursor: pointer;
  height: 4.2rem;
  border-radius: 4.2rem;
  padding: 1.2rem;

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    height: 5.2rem;
    border-radius: 5.2rem;
    padding: 1.7rem;
  }
`;

const StyledVideo = styled(Video)<{
  fullscreen: boolean;
  portrait: boolean;
  height: number;
}>`
  object-fit: cover;
  width: 100vw;
  height: ${(props) =>
    props.fullscreen && !props.portrait
      ? "100vh"
      : !props.fullscreen && props.portrait
      ? "50vh"
      : "auto"};
  display: ${(props) => (props.portrait && props.fullscreen ? "flex" : "block")};
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

const FSControls = styled(FocusableSection)<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
  }
  margin-right: ${dimens.margins.Orange}rem;
  width: 50%;

  @media (min-width: ${dimens.breakpoints.desktop}px) {
  }
`;
const ButtonPlay = styled.div`
  & button {
    min-height: 5.7rem !important;
    min-width: 5.7rem !important;
    margin: 0;
    padding: 0;
    & > svg {
      min-width: 2.8rem !important;
      min-height: 2.8rem !important;
    }
  }
`;
const Title = styled.h1<{ theme: Theme }>`
  color: #fff;
  z-index: 2;
  font-size: ${(props) => props.theme.titleTextStyle.size / 1.5}rem;
  font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
  font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
  margin-left: ${dimens.margins.Orange}rem;
`;
const StyledIcon = styled(Play)`
  height: 1.8rem;
  width: auto;
  flex-shrink: 0;
`;
const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Res = (
  props: {
    game: Game;
    fullscreen: boolean;
    className?: string;
    style?: CSSProperties;
    onExitFullscreen?: (e?: UIEvent<HTMLElement>) => void;
    onEnterFullscreen?: (e?: UIEvent<HTMLElement>) => void;
    onPlayClicked: (e?: UIEvent<HTMLElement>) => void;
    muteControls?: boolean;
    onVideoStarted?: () => void;
    videoClassName?: string;
    focusPathPrefix?: string;
    mute?: boolean;
    videoRef?: React.RefObject<HTMLVideoElement>;
  } & Pick<FocusableSectionProps, "leaveFor" | "disabled">,
) => {
  const {
    game,
    fullscreen,
    className,
    onExitFullscreen,
    onEnterFullscreen,
    onPlayClicked,
    muteControls = false,
    onVideoStarted,
    style,
    videoClassName,
    focusPathPrefix,
    leaveFor,
    mute = false,
    videoRef,
  } = props;
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const mutedFromRedux = useSelector((state: State) => state.globalState.muted);
  const muted = mute || mutedFromRedux;
  useEffect(() => {
    return () => {
      if (is_iOS()) {
        setMuted(true)(dispatch);
      }
    };
  }, [dispatch]);
  useLayoutEffect(() => {
    return () => {
      if (videoRef?.current) {
        videoRef.current.pause();
        videoRef.current.removeAttribute("src");
        videoRef.current.load();
      }
    };
  }, []);
  const timer = useRef<NodeJS.Timeout | undefined>();
  const fsControlsContainerRef = useRef<HTMLDivElement>(null);

  const video = game.videos && game.videos.find((v) => v.type === "trailer");

  const [showFullscreenControls, setShowFullscreenControls] = useState(false);

  const { pause: pauseSpatialNav, resume: resumeSpatialNav } = useSpatialNavigation();
  const { orientation } = useOrientation();
  const scheduleHideFullcreenButton = useCallback(() => {
    timer.current = setTimeout(() => {
      pauseSpatialNav();
      setShowFullscreenControls(false);
      fsControlsContainerRef.current?.focus();
    }, 5000);
  }, [pauseSpatialNav]);

  const _setShowFullscreenControls = useCallback(
    (show: boolean) => {
      if (show && timer.current !== undefined) {
        clearTimeout(timer.current);
      }

      if (show) {
        resumeSpatialNav();
        focusSectionWithPath(`${focusPathPrefix}/fsControls`);
        if (timer.current !== undefined) {
          clearTimeout(timer.current);
        }
        if (orientation !== "PORTRAIT") {
          scheduleHideFullcreenButton();
        }
      } else if (fullscreen) {
        fsControlsContainerRef.current?.focus();
        pauseSpatialNav();
      }

      setShowFullscreenControls(show);
    },
    [
      focusPathPrefix,
      fullscreen,
      orientation,
      pauseSpatialNav,
      resumeSpatialNav,
      scheduleHideFullcreenButton,
    ],
  );
  const doShowFullscreenControls = useCallback(() => {
    _setShowFullscreenControls(true);
  }, [_setShowFullscreenControls]);

  const { push: backPush } = useCustomBack();
  useEffect(() => {
    _setShowFullscreenControls(fullscreen);

    if (fullscreen && onExitFullscreen) {
      const { remove } = backPush(() => {
        onExitFullscreen();
      });
      return () => {
        remove();
      };
    }
    return () => {
      //NOP
    };
  }, [_setShowFullscreenControls, backPush, fullscreen, onExitFullscreen]);

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<unknown>) => {
      if (e.key === "Escape" || e.key === "ArrowDown") {
        if (timer.current !== undefined) {
          clearTimeout(timer.current);
        }
        resumeSpatialNav();
        if (onExitFullscreen) {
          onExitFullscreen();
        }
        e.stopPropagation();
      } else if (showFullscreenControls) {
        if (timer.current !== undefined) {
          clearTimeout(timer.current);
        }
        scheduleHideFullcreenButton();
      } else {
        _setShowFullscreenControls(true);
      }
    },
    [
      showFullscreenControls,
      resumeSpatialNav,
      onExitFullscreen,
      scheduleHideFullcreenButton,
      _setShowFullscreenControls,
    ],
  );

  const _onExitFullscreen = useCallback(() => {
    if (timer.current !== undefined) {
      clearTimeout(timer.current);
    }
    resumeSpatialNav();
    if (onExitFullscreen) {
      onExitFullscreen();
    }
  }, [onExitFullscreen, resumeSpatialNav]);

  useEffect(() => {
    if (fullscreen && orientation === "PORTRAIT") {
      _setShowFullscreenControls(true);
    }
  }, [_setShowFullscreenControls, fullscreen, orientation]);

  useEffect(() => {
    const onFullscreenChange = () => {
      if (!document.fullscreenElement && fullscreen) {
        _onExitFullscreen();
      }
    };
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, [_onExitFullscreen, fullscreen]);

  if (!video) {
    return null;
  }
  return (
    <VideoContainer className={className} style={style}>
      <StyledVideo
        src={video.url}
        loop={true}
        autoPlay={true}
        portrait={orientation === "PORTRAIT"}
        fullscreen={fullscreen}
        height={videoRef?.current?.clientHeight as number}
        muted={muted}
        onPlaying={onVideoStarted}
        className={videoClassName}
        playsInline={true}
        data-testid={"video"}
        onClick={
          onExitFullscreen && fullscreen && !showFullscreenControls
            ? doShowFullscreenControls
            : undefined
        }
        onMouseMove={
          onExitFullscreen && fullscreen && !showFullscreenControls
            ? doShowFullscreenControls
            : undefined
        }
        ref={videoRef}
      />
      {!fullscreen && (
        <ControlsContainer focusKey="controls" leaveFor={leaveFor}>
          {muteControls && <StyledVolumeButton theme={theme} />}

          {onEnterFullscreen && (
            <MaximizeButton onClick={onEnterFullscreen} theme={theme} testID="maximize">
              <>
                <Maximize />
                <MaximizeActive />
              </>
            </MaximizeButton>
          )}
        </ControlsContainer>
      )}
      {fullscreen && (
        <FSControlsContainer
          onKeyUp={onKeyUp}
          ref={fsControlsContainerRef}
          showControls={showFullscreenControls}
          tabIndex={-1}
        >
          <Title theme={theme}>{game.name}</Title>
          <FSControls focusKey="fsControls" leaveFor={LeaveForNowhere} theme={theme}>
            <ButtonPlay>
              <PrimaryButton onClick={onPlayClicked} testID="play">
                <StyledIcon />
              </PrimaryButton>
            </ButtonPlay>
            {muteControls && <FullscreenVolumeButton theme={theme} />}
            {onExitFullscreen && (
              <FSButton
                onClick={_onExitFullscreen}
                theme={theme}
                testID="minimize"
                disabled={!showFullscreenControls}
              >
                <>
                  <Minimize />
                  <MinimizeActive />
                </>
              </FSButton>
            )}
          </FSControls>
        </FSControlsContainer>
      )}
    </VideoContainer>
  );
};

export default Res;
