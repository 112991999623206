import {
  ServiceQualityLevel,
  SpeedTestConsolidatedResult,
  SpeedTestDetail,
  SpeedTestError,
  speedTestService,
  SPEEDTEST_STATUS,
  State,
  StorageKey,
} from "@blacknut/react-client-core/lib";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SecondaryButton } from "../../components/Button/V2Button";
import PageTitle from "../../components/PageTitle/PageTitle";
import { SpeedTestAnimatedGauge } from "../../components/SpeedTestAnimatedGauge/SpeedTestAnimatedGauge";
import electronService from "../../services/ElectronService";
import { LocalStorageService } from "../../services/LocalStorageService";
import speedTestElectronService from "../../services/SpeedTestElectronService";
import speedTestWebService from "../../services/SpeedTestWebService";
import { useTheme } from "../../theme/ThemeProvider";

import { logE } from "@blacknut/logging/dist";
import { FocusableSection, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { LOGGING_TAG } from "../../utils/Utils";
import { useSpeedtestSyle } from "./useSpeedtestSyle";

export const SpeedtestPage: FunctionComponent = () => {
  const { config } = useSelector((state: State) => state.globalState);
  const { t } = useTranslation();
  const { theme } = useTheme();
  const style = useSpeedtestSyle(theme);

  const [running, setRunning] = useState(false);
  const [quality, setQuality] = useState(0);
  const [bandwidth, setBandwidth] = useState<SpeedTestDetail>();
  const [jitter, setJitter] = useState<SpeedTestDetail>();
  const [ping, setPing] = useState<SpeedTestDetail>();
  const [packetLoss, setPacketLoss] = useState<SpeedTestDetail>();
  const [error, setError] = useState<SpeedTestError>();

  const { resume: resumeSpatialNav } = useSpatialNavigation();
  React.useEffect(() => {
    //Defer resume after app.router has been paused it
    setTimeout(() => {
      resumeSpatialNav();
    }, 200);
  }, [resumeSpatialNav]);

  useEffect(() => {
    new LocalStorageService()
      .getItem(StorageKey.SPEED_TEST_RESULT)
      .then((res: SpeedTestConsolidatedResult | undefined) => {
        if (res) {
          displayResult(res);
        }
      });
  }, []);

  const onSpeedTestFailure = useCallback((err?: SpeedTestError) => {
    if (err) {
      setError(err);
    }
  }, []);

  const onSpeedTestError = useCallback((err?: SpeedTestError) => {
    if (err) {
      setError(err);
    }
  }, []);

  const displayResult = (result: SpeedTestConsolidatedResult): void => {
    setQuality(result.quality);
    setBandwidth({
      result: Math.floor(result.bandwidthResult / 1000000),
      quality: result.bandwidthQuality,
    });
    setJitter({
      result: result.jitterResult,
      quality: result.jitterQuality,
    });
    setPing({
      result: result.latencyResult,
      quality: result.latencyQuality,
    });
    setPacketLoss({
      result: result.packetLossResult,
      quality: result.packetLossQuality,
    });
  };

  const _startTest = useCallback(() => {
    if (config) {
      setQuality(0);
      setBandwidth(undefined);
      setJitter(undefined);
      setPing(undefined);
      setPacketLoss(undefined);

      const interval = setInterval(() => {
        setQuality(Math.floor(Math.random() * (60 - 40 + 1)) + 40);
      }, 5000);
      const subscription = speedTestService.onStatusUpdate().subscribe(
        (result) => {
          clearInterval(interval);
          setRunning(false);
          switch (result.status) {
            case SPEEDTEST_STATUS.SUCCESS:
              if (result.result) {
                displayResult(result.result.results);
              }
              subscription.unsubscribe();
              break;
            case SPEEDTEST_STATUS.ERROR:
              onSpeedTestError(result.cause);
              subscription.unsubscribe();
              break;
            case SPEEDTEST_STATUS.FAILED:
              onSpeedTestFailure(result.cause);
              subscription.unsubscribe();
              break;
          }
        },
        (err: unknown) => {
          clearInterval(interval);
          setRunning(false);
          logE(LOGGING_TAG, "Unknown error", err);
        },
      );

      setRunning(true);
      if (electronService.isAvailable()) {
        speedTestElectronService.run(config);
      } else {
        speedTestWebService.run(config);
      }
    }
  }, [config, onSpeedTestError, onSpeedTestFailure]);

  const getSpeedTestDetailsStyle = (
    detail: SpeedTestDetail | undefined,
  ): React.CSSProperties => {
    let computedStyles: React.CSSProperties = style.detail;
    if (detail) {
      switch (detail.quality) {
        case ServiceQualityLevel.GOOD:
          computedStyles = {
            ...computedStyles,
            ...style.detailOK,
          };
          break;
        case ServiceQualityLevel.MEDIUM:
          computedStyles = {
            ...computedStyles,
            ...style.detailWarn,
          };
          break;
        case ServiceQualityLevel.BAD:
          computedStyles = {
            ...computedStyles,
            ...style.detailBad,
          };
          break;
      }
    }
    return computedStyles;
  };

  const electronAvailable = electronService.isAvailable();
  return (
    <div style={style.container}>
      <PageTitle title={t("speedTest.subtitle")}></PageTitle>
      <FocusableSection focusKey="speedtest" style={style.content}>
        <SpeedTestAnimatedGauge quality={quality} />
        {error && <p style={style.errorMessage}>{error.title}</p>}
        <div style={style.detailsContainer}>
          <div style={getSpeedTestDetailsStyle(bandwidth)}>
            <div style={style.detailTitle}>{t("speedTest.bandwidth")}</div>
            <div style={style.detailValue}>{bandwidth ? bandwidth.result : "--"}</div>
            <div style={style.detailUnit}>Mbps</div>
          </div>
          {electronAvailable && (
            <>
              <div style={getSpeedTestDetailsStyle(jitter)}>
                <div style={style.detailTitle}>{t("speedTest.jitter")}</div>
                <div style={style.detailValue}>{jitter ? jitter.result : "--"}</div>
                <div style={style.detailUnit}>ms</div>
              </div>
              <div style={getSpeedTestDetailsStyle(ping)}>
                <div style={style.detailTitle}>{t("speedTest.ping")}</div>
                <div style={style.detailValue}>{ping ? ping.result : "--"}</div>
                <div style={style.detailUnit}>ms</div>
              </div>
              <div style={getSpeedTestDetailsStyle(packetLoss)}>
                <div style={style.detailTitle}>{t("speedTest.packetLoss")}</div>
                <div style={style.detailValue}>
                  {packetLoss ? packetLoss.result : "--"}
                </div>
                <div style={style.detailUnit}>%</div>
              </div>
            </>
          )}
        </div>
        <SecondaryButton onClick={_startTest} disabled={running} style={style.btn}>
          {t("speedTest.run")}
        </SecondaryButton>
        <div style={style.disclaimer}>{t("speedTest.disclaimer")}</div>
      </FocusableSection>
    </div>
  );
};
