import styled, { css } from "styled-components";
import IconButton from "../../../components/Button/IconButton";
import CircularProgress from "../../../components/CircularProgress/CircularProgress";
import BNVideo from "../../../components/GameVideo/GameVideo";
import PlayIcon from "../../../components/PlayIcon/PlayIcon";
import dimens from "../../../theme/dimens";
import { Theme } from "../../../theme/Theme";
import { Focusable, FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import GameInfo from "../GameInfo";
import { PrimaryButton, TertiaryButton } from "../../../components/Button/V2Button";

export const RecoTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RecoTitle = styled.span`
  margin-bottom: ${dimens.margins.Green}rem;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    color: #fff;
  }
  color: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.color};
  font-weight: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontWeight};
  font-size: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.size}rem;
  font-family: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontFamily};
`;

export const Container = styled(FocusableSection)`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 1s;
  background-color: ${(props: { theme: Theme }) => props.theme.backgroundColor};
`;
export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${dimens.margins.Green}rem 0;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
  z-index: 2;
  & > * {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;
export const ContainerDescriptionRight = styled.div<{ theme: Theme }>`
  background: ${(props) => props.theme.cardStyle.inactiveBackgroundColor};
  border-radius: 8px;
  color: ${(props) => props.theme.cardStyle.inactiveTextColor};

  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  justify-content: center;
  min-width: 50vw;
  max-width: 600px;
  padding: ${dimens.margins.Green}rem ${dimens.margins.LightGreen}rem;

  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: 0;
    background: transparent;
    color: #fff;
    margin: 0 0 0 auto;
    min-width: 20vw;
  }
`;
export const ContainerDescriptionLeft = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  margin-bottom: ${dimens.margins.DarkRed}rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    max-width: 40vw;
  }
`;

export const MainLoading = styled(CircularProgress)`
  margin: auto;
`;

export const Title = styled.h1<{ theme: Theme }>`
  color: #fff;
  z-index: 2;
  font-size: ${(props) => props.theme.titleTextStyle.size}rem;
  font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
  font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
  margin-top: 50vh;
  margin-bottom: 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  z-index: 3;
  align-items: center;
`;

export const StyledGameInfo = styled(GameInfo)`
  display: flex;
  justify-content: start;
  max-width: 600px;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    margin-bottom: ${dimens.margins.Orange}rem;
  }
`;

export const Content = styled.div<{ theme: Theme }>`
  padding: 0 ${dimens.phone.contentPaddingH}rem ${dimens.phone.contentPaddingV}rem
    ${dimens.phone.contentPaddingH}rem;
  flex-direction: column;
  justify-content: stretch;
  display: flex;
  color: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.color};
  font-weight: ${(props) => props.theme.textStyle.fontWeight};
  font-size: ${(props) => props.theme.textStyle.size}rem;
  font-family: ${(props) => props.theme.textStyle.fontFamily};
  min-height: min-content;
  user-select: none;
  z-index: 2;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    color: #fff;
    padding: 0 ${dimens.desktop.contentPaddingH}rem ${dimens.desktop.contentPaddingV}rem
      ${dimens.desktop.contentPaddingH}rem;
  }
`;

export const Description = styled.div``;

export const DescriptionsInfos = styled.div`
  max-width: 600px;
`;

export const FreeText = styled.div`
  z-index: 2;
`;

export const LeMagContainer = styled.div`
  & > * {
    z-index: 2;
  }
`;

export const ButtonsContainer = styled(FocusableSection)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  align-items: center;
  margin-top: ${dimens.margins.Green}rem;
`;
export const ButtonsContainerLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > first-child {
    margin-left: 1rem;
  }

  & > * {
    margin-right: ${dimens.margins.Green}rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;
export const TextButtonPlay = styled.span`
  width: 9.8rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: 14.8rem;
  }
`;

export const RecoContainer = styled.div<{ theme: Theme }>`
  padding: ${dimens.margins.LightGreen}rem ${dimens.phone.contentPaddingH}rem
    ${dimens.margins.LightGreen}rem ${dimens.phone.contentPaddingH}rem;
  z-index: 3;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    padding: ${dimens.margins.LightGreen}rem ${dimens.desktop.contentPaddingH}rem
      ${dimens.margins.LightGreen}rem ${dimens.desktop.contentPaddingH}rem;
  }
`;
export const StyledFavBtn = styled(IconButton)<{ theme: Theme }>`
  display: flex;
  justify-content: center;
  &:focus {
    svg {
      & path:nth-child(1) {
        fill: ${(props) => props.theme.primaryButton.activeBackgroundColor};
        fill-opacity: 1;
      }
      & path:nth-child(2) {
        fill: #ffffff;
        fill-opacity: 1;
      }
    }
  }
  svg {
    color: ${(props) => props.theme.rippleColor};
    width: 3.2rem;
    height: 3.2rem;
  }
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 4.2rem;
      height: 4.2rem;
    }
  }

  svg:last-child {
    display: none;
  }

  @media (hover: hover) {
    border-radius: 6rem;
    &:hover {
      box-shadow: 0 0 1.2rem
        ${(props) => props.theme.tileCardStyle.activeBackgroundColor};
      z-index: 4;
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
  }
`;

export const StyledFullscreenBtn = styled(IconButton)`
  margin-left: auto;
  display: flex;
  justify-content: center;

  &:focus {
    svg {
      & path:nth-child(1) {
        fill: ${(props) => props.theme.primaryButton.activeBackgroundColor};
        fill-opacity: 1;
      }
    }
  }
  svg {
    color: ${(props) => props.theme.rippleColor};
    width: 3.2rem;
    height: 3.2rem;
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 4.2rem;
      height: 4.2rem;
    }
  }
  svg:last-child {
    display: none;
  }

  @media (hover: hover) {
    border-radius: 6rem;
    &:hover {
      box-shadow: 0 0 1.2rem
        ${(props) => props.theme.tileCardStyle.activeBackgroundColor};
      z-index: 4;
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
  }
`;
export const StyledPlayBtn = styled(PrimaryButton)`
  min-width: 13rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: 18rem;
  }
  margin-right: ${dimens.margins.Green}rem;
`;

export const StyledPlayIcon = styled(PlayIcon)<{ theme: Theme }>`
  cursor: pointer;
  height: 3.2rem;
  border-radius: ${(props) => props.theme.playButtonStyle?.radiusSm || 3.2}rem;
  padding: 0.7rem;
  transition: transform 0.2s; /* Animation */

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    height: 4.2rem;
    border-radius: ${(props) => props.theme.playButtonStyle?.radiusLg || 4.2}rem;
    padding: 1.2rem;
  }
  ${StyledPlayBtn}:focus & {
    box-shadow: 0 0 1.2rem ${(props) => props.theme.primaryButton.activeBackgroundColor};
    transform: ${(props) =>
      props.theme.primaryButton.scaleFactor
        ? `scale(${props.theme.primaryButton.scaleFactor})`
        : undefined};
  }
`;
export const BackgroundImageTVLegacy = styled.img`
  position: fixed;
  left: ${dimens.desktop.navigationBarWidth + "rem"};
  top: 0;
  right: 0;
  width: calc(100vw - ${dimens.desktop.navigationBarWidth}rem);
  height: calc(100vh - ${dimens.desktop.headerHeight}rem);
  z-index: 1;
`;

export const BackgroundImage = styled.img`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  object-fit: cover;
  transition: all 1s;
  width: 100%;
  height: 100%;
  filter: blur(3px);
  z-index: 0;
`;

export const Mask = styled.div`
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
export const MaskVideoGradient = styled.div<{ fullscreen: boolean; height: number }>`
  display: ${(props) => (props.fullscreen ? "none" : "initial")};
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: ${(props) => props.height}px;
  z-index: 2;
`;

export const ContainerContent = styled.div<{ theme: Theme }>`
  margin: 0 0 0 -${dimens.desktop.navigationBarWidth}rem;
  padding: 0 0px 0 ${dimens.desktop.navigationBarWidth}rem;
  min-width: 100vw;
  z-index: 2;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    background: linear-gradient(RGBA(0, 0, 0, 0.6) 0px, RGBA(0, 0, 0, 0.8) 15%);
  }
  background: ${(props) => props.theme.backgroundColor};
`;

export const Video = styled(BNVideo)<{ isOnTv: boolean; fullscreen: boolean }>`
  position: fixed;
  left: ${(props) =>
    props.fullscreen ? "0" : dimens.desktop.navigationBarWidth + "rem"};
  top: ${(props) =>
    props.fullscreen || props.isOnTv ? "0" : dimens.desktop.headerHeight + "rem"};
  right: 0;
  width: ${(props) =>
    props.fullscreen
      ? "100vw"
      : `calc(100vw - ${dimens.desktop.navigationBarWidth}rem)`};
  height: ${(props) =>
    props.fullscreen ? "100vh" : `calc(100vh - ${dimens.desktop.headerHeight}rem)`};
  z-index: ${(props) => (props.fullscreen ? 5 : 1)};
  background-color: ${(props) => (props.fullscreen ? "#000" : "transparent")};
`;

export const ContainerUpTpFS = styled.div`
  position: relative;
  display: flex;
  z-index: 3;
  top: -${dimens.desktop.headerHeight}rem;
  left: 0;
  margin-left: -${dimens.desktop.navigationBarWidth}rem;
  padding-left: ${dimens.desktop.contentPaddingH}rem;
  background: linear-gradient(black, transparent);
  min-height: ${dimens.margins.Pink * 2}rem;
`;

export const FullscreenOnTopTV = styled(Focusable)`
  position: relative;
  z-index: 3;
  min-height: ${dimens.margins.Pink - dimens.desktop.headerHeight}rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 1rem;
    height: 3rem;
  }
  color: white !important;
  top: 0;
  left: ${dimens.desktop.navigationBarWidth}rem;
`;

export const Separator = styled.div<{ theme: Theme }>`
  height: 0.5px;
  background-color: rgba(255, 255, 255, 0.25);
  margin-top: ${dimens.margins.Orange}rem !important;
  margin-bottom: ${dimens.margins.Orange}rem !important;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    margin: ${dimens.margins.LightGreen}rem 0;
  }
`;
export const SeparatorOnLandscape = styled(Separator)<{ theme: Theme }>`
  display: flex;
  margin-top: ${dimens.margins.Green}rem !important;
  margin-bottom: ${dimens.margins.Green}rem !important;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    display: none;
  }
`;
export const SeparatorOnlyDesktop = styled(Separator)<{ theme: Theme }>`
  display: none;
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    display: flex;
  }
`;

export const Header = styled.div`
  margin: 0 0 0 -${dimens.desktop.navigationBarWidth}rem !important;
  padding: 0 ${dimens.phone.contentPaddingH}rem ${dimens.desktop.contentPaddingV}rem
    ${dimens.phone.contentPaddingH + dimens.desktop.navigationBarWidth}rem;
  display: flex;
  min-height: min-content;
  flex-direction: column;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    margin: 0 0 0 -${dimens.desktop.navigationBarWidth}rem !important;
    padding: 0 ${dimens.desktop.contentPaddingH}rem ${dimens.desktop.contentPaddingV}rem
      ${dimens.desktop.navigationBarWidth + dimens.desktop.contentPaddingH}rem;
  }
  background: linear-gradient(transparent 0px, transparent 50%, RGBA(0, 0, 0, 0.6) 85%);
  z-index: 3;
`;

export const SectionHeaderCSS = css`
  font-size: ${(props) => props.theme.sectionTextStyle.size}rem;
  color: ${(props) => props.theme.sectionTextStyle.color};
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    color: #fff;
  }
  font-weight: ${(props) => props.theme.sectionTextStyle.fontWeight};
  margin-bottom: ${dimens.margins.Green}rem;
`;

export const SectionHeader = styled.div<{ theme: Theme }>`
  margin-top: ${dimens.margins.Green}rem;
  ${SectionHeaderCSS}
`;
export const ReadMore = styled(TertiaryButton)<{ theme: Theme }>`
  button {
    width: auto;
    padding: 0;
    border: none;
    min-height: 0;
  }
  &:focus {
    transform: none;
    button {
      text-decoration: underline;
    }
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline !important;
    }
  }
`;
