import { Focusable, FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import styled from "styled-components";
import { ReactComponent as FavoriteOff } from "../../../assets/dist/ic_favorite_off.svg";
import { ReactComponent as FavoriteOn } from "../../../assets/dist/ic_favorite_on.svg";
import IconButton from "../../../components/Button/IconButton";
import { PrimaryButton, TertiaryButton } from "../../../components/Button/V2Button";
import CircularProgress from "../../../components/CircularProgress/CircularProgress";
import BNVideo from "../../../components/GameVideo/GameVideo";
import dimens from "../../../theme/dimens";
import { Theme } from "../../../theme/Theme";
import GameInfo from "../GameInfo";

export const RecoTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RecoTitle = styled.span`
  margin-bottom: ${dimens.margins.Green}rem;
  color: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.color};
  font-weight: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontWeight};
  font-size: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.size}rem;
  font-family: ${(props: { theme: Theme }) => props.theme.sectionTextStyle.fontFamily};
`;

export const Container = styled(FocusableSection)<{ theme: Theme }>`
  margin-top: -${dimens.phone.headerHeight}rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 1s;
  background: ${(props) => props.theme.cardStyle.inactiveBackgroundColor};
`;

export const MainLoading = styled(CircularProgress)`
  margin: auto;
`;

export const Title = styled.h1<{ theme: Theme }>`
  text-align: start;
  color: #fff;
  z-index: 1;
  font-size: ${(props) => props.theme.titleTextStyle.size}rem;
  font-weight: ${(props) => props.theme.titleTextStyle.fontWeight};
  font-family: ${(props) => props.theme.titleTextStyle.fontFamily};
`;

export const StyledGameInfo = styled(GameInfo)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ContainerGameInfo = styled.div<{ theme: Theme }>`
  background-color: ${(props) => props.theme.cardStyle.inactiveBackgroundColor};
  border-radius: 8px;
  padding: ${dimens.margins.Green}rem ${dimens.margins.LightGreen}rem;
  font-size: 1.4rem;
  color: ${(props) => props.theme.cardStyle.inactiveTextColor};
  margin-top: ${dimens.margins.Green}rem;
`;

export const LeMagContainer = styled.div`
  z-index: 2;
  margin-bottom: 0;
`;

export const Content = styled.div<{ theme: Theme }>`
  padding: ${dimens.phone.contentPaddingV}rem ${dimens.phone.contentPaddingH}rem;
  flex-direction: column;
  justify-content: stretch;
  display: flex;
  color: ${(props) => props.theme.textStyle.color};
  font-weight: ${(props) => props.theme.textStyle.fontWeight};
  font-size: ${(props) => props.theme.textStyle.size}rem;
  font-family: ${(props) => props.theme.textStyle.fontFamily};
  min-height: min-content;
  user-select: none;
`;

export const ContainerBackgroundContent = styled.div<{ theme: Theme }>`
  max-width: 100vw;
  z-index: 1;
  background: ${(props) => props.theme.backgroundColor};
`;

export const Description = styled.div``;
export const FreeText = styled.div``;

export const ButtonsContainer = styled(FocusableSection)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${dimens.margins.Green}rem;
  z-index: 1;
`;

export const RecoContainer = styled.div<{ theme: Theme }>`
  z-index: 0;
  padding-top: ${dimens.margins.Green}rem;
  padding: ${dimens.margins.LightGreen}rem;
  background-color: ${(props) => props.theme.cardStyle.inactiveBackgroundColor};
  margin-bottom: ${dimens.margins.LightGreen}rem;
`;

export const StyledFavBtn = styled(IconButton)<{ theme: Theme }>`
  display: flex;
  justify-content: center;
  &:focus {
    svg {
      & path:nth-child(1) {
        fill: ${(props) => props.theme.primaryButton.activeBackgroundColor};
        fill-opacity: 1;
      }
      & path:nth-child(2) {
        fill: #ffffff;
        fill-opacity: 1;
      }
    }
  }
  svg {
    color: ${(props) => props.theme.rippleColor};
    width: 3.2rem;
    height: 3.2rem;
  }
  @media (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 4.2rem;
      height: 4.2rem;
    }
  }

  svg:last-child {
    display: none;
  }

  @media (hover: hover) {
    border-radius: 6rem;
    &:hover {
      box-shadow: 0 0 1.2rem
        ${(props) => props.theme.tileCardStyle.activeBackgroundColor};
      z-index: 4;
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
  }
`;

export const StyledPlayBtn = styled(PrimaryButton)`
  min-width: 13rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    width: 18rem;
  }
  margin-right: ${dimens.margins.Green}rem;
`;

export const StyledCircularProgress = styled(CircularProgress)`
  margin-left: 0.5rem;
  color: #fff;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  object-fit: cover;
  transition: all 1s;
  width: 100%;
  height: 50vh;
  filter: blur(3px);
  z-index: 0;
`;

export const Mask = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50vh;
  transition: all 1s;
  z-index: 1;
`;

export const Video = styled(BNVideo)<{ fullscreen: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  height: ${(props) => (props.fullscreen ? "100vh" : "50vh")};
  z-index: ${(props) => (props.fullscreen ? 5 : 1)};
  background-color: ${(props) => (props.fullscreen ? "#000" : "transparent")};
  video {
    object-fit: cover;
  }
`;

export const Separator = styled.div<{ theme: Theme }>`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.25);
  margin: ${dimens.margins.Green}rem 0;
`;

export const Header = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-end;
  height: 50vh;
  padding: 0 ${dimens.margins.LightGreen}rem 0 ${dimens.margins.LightGreen}rem;
  background: linear-gradient(transparent 0px, transparent 50%, RGBA(0, 0, 0, 0.6) 85%);
`;

export const SectionHeader = styled.div<{ theme: Theme }>`
  font-size: ${(props) => props.theme.sectionTextStyle.size}rem;
  color: ${(props) => props.theme.sectionTextStyle.color};
  font-weight: ${(props) => props.theme.sectionTextStyle.fontWeight};
  margin-bottom: ${dimens.margins.Green}rem;
`;

export const StyledFavoriteOn = styled(FavoriteOn)`
  width: 2.4rem;
  height: 2.4rem;
`;

export const StyledFavoriteOff = styled(FavoriteOff)`
  width: 2.4rem;
  color: white;
  height: 2.4rem;
`;

export const FavoriteText = styled.span`
  flex: 1;
  margin-right: 2.4rem;
`;

export const StyledFullscreenBtn = styled(IconButton)`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  svg {
    color: ${(props) => props.theme.rippleColor};
    width: 3.2rem;
    height: 3.2rem;
  }
  &:focus {
    svg {
      & path:nth-child(1) {
        fill: ${(props) => props.theme.primaryButton.activeBackgroundColor};
        fill-opacity: 1;
      }
    }
  }

  @media (min-width: ${dimens.breakpoints.desktop}px) {
    svg {
      width: 4.2rem;
      height: 4.2rem;
    }
  }
  svg:last-child {
    display: none;
  }

  @media (hover: hover) {
    border-radius: 6rem;
    &:hover {
      box-shadow: 0 0 1.2rem
        ${(props) => props.theme.tileCardStyle.activeBackgroundColor};
      z-index: 4;
      svg:first-child {
        display: none;
      }
      svg:last-child {
        display: block;
      }
    }
  }
`;

export const ReadMore = styled(TertiaryButton)<{ theme: Theme }>`
  margin-bottom: ${dimens.margins.Green}rem;
  button {
    width: auto;
    padding: 0;
    border: none;
    min-height: 0;
  }
  &:focus {
    transform: none;
    button {
      text-decoration: underline;
    }
  }
`;
