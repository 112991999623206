import { Config } from "@blacknut/javascript-sdk/dist";
import { logD } from "@blacknut/logging/dist";
import {
  SPEEDTEST_STATUS,
  SpeedTest,
  speedTestService,
} from "@blacknut/react-client-core/lib";
import { SpeedTest as SpeedtestV3 } from "@blacknut/speedtest/dist";
import { Subject, Subscription } from "rxjs";
import { LocalStorageService } from "./LocalStorageService";
const TAG = "Speedtest";
class SpeedTestWebService {
  private subscriptions: Subscription[] = [];

  constructor() {
    speedTestService.localStorageService = new LocalStorageService();
  }

  public start(config: Config) {
    logD(TAG, "Start speedtest");
    if (speedTestService.status !== SPEEDTEST_STATUS.RUNNING) {
      const subject: Subject<SpeedTest[]> = new Subject();
      const speedtest = new SpeedtestV3(config, SpeedtestV3.config_standard);

      this.startDownload(speedtest, subject);
    }
  }

  run(config: Config) {
    const subject: Subject<SpeedTest[]> = new Subject();
    const speedtest = new SpeedtestV3(config, SpeedtestV3.config_full);
    this.startDownload(speedtest, subject);
  }

  private startDownload(speedtest: SpeedtestV3, subject: Subject<SpeedTest[]>) {
    speedTestService.speedTestWillStart(subject.asObservable());

    speedtest
      .run()
      .then(() => {
        subject.next(speedtest.report(""));
        subject.complete();
        this.onEnd();
      })
      .catch((e) => {
        subject.error(e);
        subject.complete();
        this.onEnd();
      });
  }

  private onEnd() {
    this.subscriptions.map((s) => s.unsubscribe());
    this.subscriptions = [];
  }
}

export default new SpeedTestWebService();
