import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import styled from "styled-components";
import { TextInputStyle, Theme } from "../../theme/Theme";
import { overrideIfNeeded } from "../Inputs/TextInput/V2TextInput";
export declare type SizeVariant = "small" | "medium" | "large";

export const Container = styled(Focusable)`
  position: relative;

  & > span {
    display: block;
    width: calc(100% - 1.6rem);
    height: 0.2rem;
    background: ${(props) => props.theme.inputPinStyle.borderColor};
    z-index: 2;
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;
  }

  > input {
    z-index: 1;
  }

  &:focus-within {
    & > span {
      background: ${(props) => props.theme.rippleColor};
    }
    input {
      border-color: ${(props) => props.theme.rippleColor};
    }
  }
`;

export const Input = styled.input<{
  theme: Theme;
  sizeVariant: SizeVariant;
  errored: boolean;
  secured: boolean;
  inputStyle?: Partial<TextInputStyle>;
}>`
  background-color: ${(props) =>
    props.errored
      ? "#f8d7da"
      : overrideIfNeeded(
          "inactiveBackgroundColor",
          props.theme.textInputStyle,
          props.inputStyle,
        )};
  text-align: center;
  border: 0.1rem solid ${(props) => props.theme.inputPinStyle.borderColor};
  padding: 0;
  color: ${(props) =>
    props.secured ? props.theme.rippleColor : props.theme.textStyle.color};
  font-family: ${(props) => (props.secured ? "dotsfont" : undefined)};
  border-radius: 0.4rem;

  width: ${(props) =>
    props.sizeVariant === "small"
      ? "3rem"
      : props.sizeVariant === "medium"
      ? "4.8rem"
      : "7.6rem"};
  height: ${(props) =>
    props.sizeVariant === "small"
      ? "5rem"
      : props.sizeVariant === "medium"
      ? "4.8rem"
      : "7.6rem"};
  font-size: ${(props) =>
    props.sizeVariant === "small"
      ? "1.4rem"
      : props.sizeVariant === "medium"
      ? "1.6rem"
      : "3rem"};

  @media screen and (min-width: 1023px) {
    width: ${(props) =>
      props.sizeVariant === "small"
        ? "4rem"
        : props.sizeVariant === "medium"
        ? "4.8rem"
        : "7.6rem"};
    height: ${(props) =>
      props.sizeVariant === "small"
        ? "6rem"
        : props.sizeVariant === "medium"
        ? "4.8rem"
        : "7.6rem"};
    font-size: ${(props) =>
      props.sizeVariant === "small"
        ? "2rem"
        : props.sizeVariant === "medium"
        ? "1.6rem"
        : "4rem"};
  }
`;
