import { isSmartTV, isTizen, isWebOS } from "@blacknut/javascript-sdk/dist";
import { logD, logI, logger, LogLevel } from "@blacknut/logging/dist";
import {
  analyticsService,
  apiService,
  ClientDeviceType,
  DeprecatedStorageKey,
  mediationServerService,
  metricsService,
  notificationsService,
  StorageKey,
  UserAgent,
} from "@blacknut/react-client-core/lib";
import Cookies from "js-cookie";
import "./i18n/i18n";
import "./index.css";
import electronService from "./services/ElectronService";
import localStorageService from "./services/LocalStorageService";
import { LOGGING_TAG } from "./utils/Utils";
import { DDAppender } from "@blacknut/logging-datadog/dist";
import { setupDatadog } from "./datadog";
//import cefService from "./services/CEFService";
import { isTouchDevice, is_iOS, isPhone } from "@blacknut/javascript-sdk/dist";

//FIXME huge refactoring required with App.tsx
setupDatadog();

const setToLocalStorageIfDefined = (key: string, value: string | null | undefined) => {
  if (value) {
    localStorage.setItem(key, value);
  }
};

logger.configure({
  level: LogLevel.LEVEL_INFO,
  appenders: [new DDAppender({ type: "App" })],
});

// Lang cookie used on web
const lang = Cookies.get("lang") || navigator.language || navigator["userLanguage"];
apiService.locale = lang;

const qs = new URLSearchParams(window.location.search);
const _modelFromQuery = qs.get("model");
const _brandFromQuery = qs.get("brand");
const _deviceFromQuery = qs.get("device"); // careful also used in preview
const _osFromQuery = qs.get("os"); // used on preview

const deviceModel = _modelFromQuery || localStorage.getItem("model");
const deviceBrand = _brandFromQuery || localStorage.getItem("brand");
const deviceLayout = _deviceFromQuery || localStorage.getItem("device");

setToLocalStorageIfDefined("model", _modelFromQuery);
setToLocalStorageIfDefined("brand", _brandFromQuery);
setToLocalStorageIfDefined("device", _deviceFromQuery);

let appVersion = process.env.REACT_APP_CLIENT_VERSION || "0.0.0";
let client: ClientDeviceType = ClientDeviceType.DESKTOP;
let deviceType: "DESKTOP" | "MOBILE" | "TABLET" | "TV" = "DESKTOP";
if (deviceLayout) {
  switch (deviceLayout.toLowerCase()) {
    case "tv":
      client = ClientDeviceType.TV;
      deviceType = "TV";
      break;
    case "tablet":
      client = ClientDeviceType.TABLET;
      deviceType = "TABLET";
      break;
    case "phone":
    case "mobile":
      client = ClientDeviceType.PHONE;
      deviceType = "MOBILE";
      break;
  }
} else if (electronService.isAvailable()) {
  appVersion = electronService.version;
  client = ClientDeviceType.DESKTOP;
  deviceType = "DESKTOP";
  // Data recovery from previous release of yoshi
  // Prior to 2.0.0 local storage values where not all JSON values, so get value directly from localstorage instead of using service
  const version = localStorage.getItem(StorageKey.VERSION);
  if (version) {
    logI(LOGGING_TAG, "Previous version ? %s", version);

    const re = /([0-9]+)\.([0-9]+)\.([0-9]+)/;
    const match = version.match(re);
    if (match) {
      if (parseInt(match[1], 10) < 2) {
        // Upgraded from yoshi
        const platform = localStorage.getItem(StorageKey.PLATFORM);
        logD(LOGGING_TAG, "GOT PLATFORM %s", platform);

        if (platform) {
          localStorage.setItem(StorageKey.PLATFORM, JSON.stringify(platform));
        }

        // Version prior to 1.8 token is a v4 token
        localStorage.removeItem(StorageKey.TOKEN);

        // From yoshi 1.8 famly token renamed to token
        const familyToken = localStorage.getItem(DeprecatedStorageKey.TOKEN);
        logD(LOGGING_TAG, "GOT FAMILY TOKEN %o", familyToken);
        localStorage.removeItem(DeprecatedStorageKey.TOKEN);
        if (familyToken) {
          localStorage.setItem(StorageKey.TOKEN, familyToken);
        }
      }
    }
  }
} else if (isTizen()) {
  client = ClientDeviceType.TV;
  deviceType = "TV";
} else if (isSmartTV()) {
  client = ClientDeviceType.TV;
  deviceType = "TV";
} else if (isPhone()) {
  client = ClientDeviceType.PHONE;
  deviceType = "MOBILE";
} else if (isTouchDevice() && !matchMedia("(pointer:fine)").matches) {
  client = ClientDeviceType.TABLET;
  deviceType = "TABLET";
}

//CEF detection -- START
//cefService.init();
//CEF detection -- END

localStorageService.setItem(StorageKey.VERSION, appVersion);

const _defaultUA: Partial<UserAgent> = {
  apiVersion: process.env.REACT_APP_API_VERSION || "5.0.3",
  client,
  clientVersion: appVersion,
  deviceType,
  flavor: process.env.REACT_APP_FLAVOR,
  brand: deviceBrand ? deviceBrand : undefined,
  model: deviceModel ? deviceModel : undefined,
  os: _osFromQuery as "Android",
};

let ua: UserAgent;
if (isTizen()) {
  ua = {
    ..._defaultUA,
    os: "Tizen",
    model: deviceModel || undefined,
    brand: deviceBrand || undefined,
    deviceType: "TV",
    client: ClientDeviceType.TV,
  } as UserAgent;
} else if (isWebOS()) {
  ua = {
    ..._defaultUA,
    os: "Webos",
    brand: "LG",
    model: deviceModel || undefined,
    deviceType: "TV",
    client: ClientDeviceType.TV,
  } as UserAgent;
} else if (isSmartTV()) {
  ua = {
    ..._defaultUA,
    os: "Unknown" as any,
    brand: "Unknown",
    model: deviceModel || undefined,
    deviceType: "TV",
    client: ClientDeviceType.TV,
  } as UserAgent;
} else {
  const idx = navigator.platform.indexOf(" ");
  let os = idx === -1 ? navigator.platform : navigator.platform.substring(0, idx);
  if (is_iOS()) {
    os = "iOS";
  } else if (os === "MacIntel") {
    os = "Mac";
  }
  ua = {
    ..._defaultUA,
    clientVersion: appVersion,
    os: _osFromQuery || (os as "Android"),
    deviceType,
  } as UserAgent;
}
mediationServerService.lang = lang;
mediationServerService.userAgent(ua);

notificationsService.lang = lang;
notificationsService.userAgent(ua);

apiService.userAgent = ua;

///////////////////////////////////////
// Analytics
///////////////////////////////////////
analyticsService.userAgent = ua;
if (electronService.isAvailable()) {
  analyticsService.setApp("pc-client");
  electronService.getNetworkConnectionType().then((type) => {
    analyticsService.setConnectionType(type);
  });
} else {
  analyticsService.setApp("webapp");
  const nav = navigator as any;
  const connection = nav.connection || nav.mozConnection || nav.webkitConnection;
  if (connection) {
    analyticsService.setConnectionType(connection.type);
  }
}

metricsService.userAgent = ua;
