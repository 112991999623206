import { createDarkTheme } from "../default/Dark";

const res = createDarkTheme({ colorAccent: "#0A75B0", primaryTextColor: "#DADADA" });
res.default.defaultFontFamily = "TheSans";

res.default.logoSize = { width: 11.68, height: 2.4 };

res.default.backgroundColor = "#141414";
res.default.bottomBarStyle.backgroundColor = "#141414";
res.default.bottomBarStyle.borderColor = "#141414";
res.default.bottomBarStyle.scaleFactor = undefined;

res.default.headerStyle.backgroundColor = "#141414";
res.default.headerStyle.textStyle = {
  ...res.default.headerStyle.textStyle,
  fontFamily: "TheSans500",
};
res.default.primaryButton.borderRadius = 0.6;
res.default.primaryButton.activeTextColor = "#DADADA";
res.default.primaryButton.inactiveTextColor = "#DADADA";
res.default.primaryButton.inactiveBorderColor = "#DADADA";
res.default.primaryButton.fontFamily = "TheSans700";

res.default.secondaryButton.borderRadius = 0.6;
res.default.secondaryButton.inactiveBorderColor = "#DADADA";
res.default.secondaryButton.inactiveTextColor = "#DADADA";
res.default.secondaryButton.fontFamily = "TheSans600";

res.default.toggleButton.borderRadius = 0.6;
res.default.toggleButton.selectedTintColor = "#0A75B0";
res.default.toggleButton.selectedTextColor = "#fff";
res.default.toggleButton.tintColor = "#141414";
res.default.toggleButton.fontFamily = "TheSans700";

res.default.tertiaryButton.fontFamily = "TheSans600";

res.default.images.SortAZ = undefined;
res.default.images.SortDate = undefined;
res.default.images.SortGenre = undefined;

res.default.modalStyle.buttonsAlignment = "center";
res.default.modalStyle.backgroundColor = "#222222";

res.default.separatorColor = "#222222";

res.default.checkboxStyle.inactiveTintColor = undefined;
res.default.checkboxStyle.activeTintColor = undefined;

res.default.fullscreenControls.backgroundColor = "rgba(0,0,0,.75)";
res.default.accountCardStyle.radius = 0.6;
res.default.profileCardStyle.radius = 0;
res.default.profileCardStyle.scaleFactor = undefined;
res.default.tileCardStyle.scaleFactor = undefined;
res.default.profileListItemCardStyle.scaleFactor = undefined;
res.default.browseCardItemCardStyle.scaleFactor = undefined;

res.default.sectionTextStyle.fontFamily = "TheSans700";
res.default.titleTextStyle.fontFamily = "TheSans600";
res.default.search = {
  ...res.default.search,
  backgroundColor: "#222222",
  toggleButtonStyle: {
    ...res.default.toggleButton,
    tintColor: "#222222",
  },
};

res.default.notificationPWA = {
  ...res.default.notificationPWA,
  backgroundColor: "#141414",
};
export default res;
