import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/ThemeProvider";
import { AccountListItem, MyAccountSection, ThemeListItem } from "./AccountListItem";
import {
  SectionListItemListItem,
  IconContainer,
  StyledRipple,
  SectionListItemListItemContent,
  StyledCheckbox,
} from "./MobileAccountPage.style";
import React, { useCallback, useRef } from "react";
import { RippleType } from "../../components/Ripple";
const AccountListItemView = ({
  item,
  index,
  section,
}: {
  item: AccountListItem;
  index: number;
  section: MyAccountSection;
}) => {
  const { theme } = useTheme();
  const Icon = item.icon;
  const ActiveIcon = item.activeIcon;
  const rippleRef = useRef<RippleType>(null);
  const onClick = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      rippleRef.current?.ripple(e, item.onPress);
    },
    [item.onPress],
  );
  return (
    <StyledRipple
      ref={rippleRef}
      rippleStyle={{
        rippleColor: "#dcdcdc",
        radius: {
          topLeft: index === 0 ? theme.listStyle.radius : undefined,
          topRight: index === 0 ? theme.listStyle.radius : undefined,
          bottomLeft:
            index === section.data.length - 1 ? theme.listStyle.radius : undefined,
          bottomRight:
            index === section.data.length - 1 ? theme.listStyle.radius : undefined,
        },
      }}
    >
      <SectionListItemListItem
        key={item.id}
        theme={theme}
        data-role="listitem"
        onClick={onClick}
        isFirst={index === 0}
        isLast={index === section.data.length - 1}
        onPressEnter={item.onPress}
      >
        <SectionListItemListItemContent theme={theme}>
          <IconContainer className="icons">
            {Icon && <Icon />}
            {ActiveIcon && <ActiveIcon />}
          </IconContainer>
          <span>{item.title}</span>
        </SectionListItemListItemContent>
      </SectionListItemListItem>
    </StyledRipple>
  );
};
export default AccountListItemView;
