import { isPhone, isTizen, isWebOS } from "@blacknut/javascript-sdk/dist";
import { logW, logD } from "@blacknut/logging/dist";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PrimaryButton, SecondaryButton } from "../components/Button/V2Button";
import MessageModal from "../components/Modals/MessageModal";
import { ModalSubscription, useModal } from "../components/Modals/ModalContext";
import { LOGGING_TAG } from "../utils/Utils";
import nativeBridge from "../utils/NativeBridge";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { apiService } from "@blacknut/javascript-sdk/dist";

interface BackEvent {
  key?: string;
  keyCode?: number;
  ctrl?: boolean;
}
export const DefaultBackEvent: BackEvent = { key: "Backspace", keyCode: 8, ctrl: true };
const Handlers: Record<string, BackEvent[]> = {};
Handlers["webos"] = [DefaultBackEvent, { key: "GoBack", keyCode: 27 }];
Handlers.default = [DefaultBackEvent];
Handlers.tizen = [DefaultBackEvent, { keyCode: 10009 }];
Handlers.defaultTV = [{ key: "Backspace", keyCode: 8  }, { key: "BrowserBack", keyCode: 166  }];

export interface CustomBackSubscription {
  remove: () => void;
}
declare type HandlerCallback = (evt: KeyboardEvent) => void;

export interface CustomBackContextProps {
  push: (handler: HandlerCallback) => CustomBackSubscription;
  handler?: HandlerCallback;
  pop: () => void;
}

export const CustomBackContext = createContext<CustomBackContextProps>({
  push: () => {
    if (process.env.NODE_ENV !== "test") {
      logW(LOGGING_TAG, "CustomBack not implemented");
    }
    return {
      remove: () => {
        //NOP
      },
    };
  },
  pop: () => {
    if (process.env.NODE_ENV !== "test") {
      logW(LOGGING_TAG, "CustomBack not implemented");
    }
  },
});

export const CustomBackContextProvider = (props: PropsWithChildren<unknown>) => {
  const [handlers, setHandlers] = useState<HandlerCallback[]>([]);

  const push = useCallback((handler: HandlerCallback) => {
    setHandlers((handlers) => {
      return [...handlers, handler];
    });

    return {
      remove: () => {
        setHandlers((handlers) => {
          const res = handlers.filter((h) => h !== handler);
          return res;
        });
      },
    };
  }, []);

  const pop = useCallback(() => {
    setHandlers((handlers) => {
      if (handlers.length > 0) {
        return handlers.splice(-1);
      }
      return [];
    });
  }, []);

  const handler = handlers.length > 0 ? handlers[handlers.length - 1] : undefined;
  logD(LOGGING_TAG, "Render. Handlers count:(%o)", handlers.length);
  return (
    <CustomBackContext.Provider
      value={{
        handler,
        push,
        pop,
      }}
    >
      {props.children}
    </CustomBackContext.Provider>
  );
};
export const useCustomBack = () => useContext(CustomBackContext);

const useBackKey = () => {
  let events = Handlers.default;
  if (isWebOS()) {
    events = Handlers["webos"];
  } else if (isTizen()) {
    events = Handlers["tizen"];
  } else if (apiService.userAgent.deviceType === "TV") {
    events = Handlers.defaultTV;
  }
  const history = useHistory();
  const { handler: backHandler } = useContext(CustomBackContext);
  const modalSub = useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const { t } = useTranslation();
  const tempLastEvtTimeStamp = useRef<number>(0);
  const { active: spatialNavigationActive } = useSpatialNavigation();

  useEffect(() => {
    if (!events) return;
    const listener = (evt: KeyboardEvent) => {
      for (const backEvent of events) {
        if (
          evt.timeStamp - tempLastEvtTimeStamp.current > 100 &&
          ((evt.key && evt.key === backEvent.key) ||
            evt.keyCode ===
              backEvent.keyCode) /*https://www.npmjs.com/package/keysim does not support key*/ &&
          (backEvent.ctrl === undefined || backEvent.ctrl === evt.ctrlKey)
        ) {
          evt.preventDefault();
          evt.stopPropagation();
          evt.stopImmediatePropagation();

          tempLastEvtTimeStamp.current = evt.timeStamp;

          if (
            !isPhone() &&
            nativeBridge.module?.exit &&
            (window.location.pathname === "/catalog" ||
              window.location.pathname === "/welcome")
          ) {
            const onClose = () => {
              modalSub.current?.remove();
              modalSub.current = undefined;
            };
            const onConfirmed = () => {
              modalSub.current?.remove();
              modalSub.current = undefined;
              nativeBridge.module?.exit && nativeBridge.module.exit();
            };
            modalSub.current = modalPush((props) => (
              <MessageModal
                {...props}
                title={t("dialogs.exitApp.title")}
                message={t("dialogs.exitApp.message")}
                buttons={[
                  <SecondaryButton key="cancel" onClick={onClose} testID="cancel">
                    {t("buttons.cancel")}
                  </SecondaryButton>,
                  spatialNavigationActive ? (
                    <SecondaryButton key="ok" onClick={onConfirmed} testID="confirm">
                      {t("buttons.ok")}
                    </SecondaryButton>
                  ) : (
                    <PrimaryButton key="ok" onClick={onConfirmed} testID="confirm">
                      {t("buttons.ok")}
                    </PrimaryButton>
                  ),
                ]}
              />
            ));
          } else if (backHandler) {
            backHandler(evt);
          } else {
            history.goBack();
          }
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [backHandler, events, history, modalPush, spatialNavigationActive, t]);
};
export default useBackKey;
