import { useTranslation } from "react-i18next";
import { useTheme } from "../../theme/ThemeProvider";
import { MyAccountSection, ThemeListItem } from "./AccountListItem";
import {
  SectionListItemListItem,
  IconContainer,
  StyledRipple,
  SectionListItemListItemContent,
  StyledCheckbox,
} from "./MobileAccountPage.style";
import React, { useCallback, useRef } from "react";
import { RippleType } from "../../components/Ripple";

const ThemeListItemView = ({
  item,
  index,
  section,
}: {
  item: ThemeListItem;
  index: number;
  section: MyAccountSection;
}) => {
  const { t } = useTranslation();
  const { theme, setThemeId } = useTheme();
  const Icon = item.icon;
  const ActiveIcon = item.activeIcon;
  const rippleRef = useRef<RippleType>(null);
  const onSelectTheme = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (theme.name !== item.theme) {
        rippleRef.current?.ripple(e, () => {
          setThemeId(item.theme);
        });
      }
    },
    [item.theme, setThemeId, theme.name],
  );
  return (
    <StyledRipple
      ref={rippleRef}
      rippleStyle={{
        rippleColor: "#dcdcdc",
        radius: {
          topLeft: index === 0 ? theme.listStyle.radius : undefined,
          topRight: index === 0 ? theme.listStyle.radius : undefined,
          bottomLeft:
            index === section.data.length - 1 ? theme.listStyle.radius : undefined,
          bottomRight:
            index === section.data.length - 1 ? theme.listStyle.radius : undefined,
        },
      }}
    >
      <SectionListItemListItem
        theme={theme}
        data-role="listitem"
        isFirst={index === 0}
        isLast={index === section.data.length - 1}
        onPressEnter={onSelectTheme}
      >
        <SectionListItemListItemContent theme={theme} onClick={onSelectTheme}>
          <IconContainer className="icons">
            {Icon && <Icon />}
            {ActiveIcon && <ActiveIcon />}
          </IconContainer>
          <StyledCheckbox
            theme={theme}
            onCheckChange={onSelectTheme}
            checked={theme.name == item.theme}
            label={t(`themes.${item.theme}`)}
            radio={true}
            id={`theme-${item.theme}`}
            labelAlignment="start"
          />
        </SectionListItemListItemContent>
      </SectionListItemListItem>
    </StyledRipple>
  );
};

export default ThemeListItemView;
