import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from "../../components/Button/V2Button";
import Checkbox from "../../components/Inputs/Checkbox/Checkbox";
import ProfileHorizontalList from "../../components/ProfileHorizontalList/ProfileHorizontalList";
import Ripple from "../../components/Ripple";
import dimens from "../../theme/dimens";
import { Theme } from "../../theme/Theme";

export const Container = styled.div`
  background-color: ${(props: { theme: Theme }) => props.theme.backgroundColor};
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${dimens.margins.LightGreen}rem 0;
  margin-left: ${dimens.phone.navigationBarWidth}rem;
  @media screen and (min-width: ${dimens.breakpoints.desktop}px) {
    display: none;
  }
`;

export const StyledProfilesList = styled(ProfileHorizontalList)`
  padding: 0 ${dimens.margins.LightGreen}rem;
  width: calc(100vw - ${dimens.margins.LightGreen}rem);
  @media screen and (orientation: landscape) {
    width: calc(
      100vw - ${dimens.margins.LightGreen}rem -
        ${dimens.phoneLandscape.navigationBarWidth}rem
    );
  }
`;
export const SectionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 ${dimens.margins.LightGreen}rem;
`;

export const SectionListItem = styled.div<{ theme: Theme }>`
  color: ${(props) => props.theme.textStyle.color};
  font-family: ${(props) => props.theme.textStyle.fontFamily};
  font-weight: ${(props) => props.theme.textStyle.fontWeight};
  font-size: ${(props) => props.theme.textStyle.size}rem;
`;

export const SectionHeader = styled.div`
  padding: ${dimens.margins.Green}rem ${dimens.margins.LightGreen}rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SectionHeaderTitle = styled.div`
  margin-right: auto;
`;
export const SectionListItemList = styled.ul<{ theme: Theme }>`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const SectionListItemListItem = styled(Focusable)<{
  theme: Theme;
  isFirst: boolean;
  isLast: boolean;
}>`
  flex: 1;
  background-color: ${(props) => props.theme.listStyle.backgroundColor};
  padding: 1.6rem 2.4rem;
  border-top-left-radius: ${(props) =>
    props.isFirst ? props.theme.listStyle.radius : undefined}rem;
  border-top-right-radius: ${(props) =>
    props.isFirst ? props.theme.listStyle.radius : undefined}rem;
  border-bottom-left-radius: ${(props) =>
    props.isLast ? props.theme.listStyle.radius : undefined}rem;
  border-bottom-right-radius: ${(props) =>
    props.isLast ? props.theme.listStyle.radius : undefined}rem;
  cursor: pointer;
  .icons > :last-child {
    display: none;
  }
  &:focus {
    transform: scale(1.01);
    background-color: ${(props) => props.theme.listStyle.activeBackgroundColor};
    color: ${(props) => props.theme.listStyle.activeTextColor};
    label {
      color: ${(props) => props.theme.listStyle.activeTextColor};
    }
    .icons > :first-child {
      display: none;
    }
    .icons > :last-child {
      display: block;
    }
  }
`;

export const StyledRipple = styled(Ripple)`
  display: flex;
`;

export const SectionListItemListItemContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const StyledPrimary = styled(PrimaryButton)`
  margin: ${dimens.margins.Green}rem ${dimens.margins.LightGreen}rem 0
    ${dimens.margins.LightGreen}rem;
`;

export const IconContainer = styled.div`
  svg {
    display: block;
    margin-right: ${dimens.margins.DarkRed}rem;
    width: 3.2rem;
    height: 3.2rem;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  flex: 1;

  ${SectionListItemListItem}:focus & {
    svg {
      color: ${(props) => props.theme.listStyle.activeTextColor};
    }
  }
`;

export const StyledLogout = styled(SecondaryButton)`
  margin: ${dimens.margins.LightGreen}rem ${dimens.margins.LightGreen}rem 0
    ${dimens.margins.LightGreen}rem;
  min-height: min-content;

  background-color: ${({ theme }) => theme.toggleButton.tintColor};

  button {
    color: ${({ theme }) => theme.toggleButton.textColor};
    border: none;
  }
`;

export const InstallCard = styled.div<{ theme: Theme }>`
  position: relative;
  color: ${(props) => props.theme.accountCardStyle.inactiveBackgroundColor};
  display: flex;
  margin: ${dimens.margins.LightGreen}rem auto 0;
  align-items: center;
  justify-content: center;
  min-height: 12rem;
  overflow: hidden;
  background: ${(props) => props.theme.spinnerColor};
  border-radius: 0.6rem;
  box-sizing: content-box;
  padding: 2.4rem 2.4rem;
  background-color: ${(props) => props.theme.listStyle.backgroundColor};
  color: ${(props) => props.theme.textStyle.color};
  font-family: ${(props) => props.theme.textStyle.fontFamily};
  font-weight: ${(props) => props.theme.textStyle.fontWeight};
  font-size: ${(props) => props.theme.textStyle.size}rem;
  cursor: pointer;

  span {
    cursor: pointer;
  }

  @media (min-width: ${dimens.breakpoints.tablet}px) {
    padding: unset;
  }
`;

export const InstallContent = styled.div`
  text-align: center;
  z-index: 2;
`;

export const InstallButton = styled(PrimaryButton)<{ theme: Theme }>`
  button {
    font-size: 1.5rem;
  }
  background: transparent;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: ${(props) => props.theme.primaryButton.borderRadius}rem;
  max-width: fit-content;
`;
